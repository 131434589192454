/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useState, useEffect, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify'
import moment from 'moment';
import { debounce } from "lodash"
import {
  contact_list
} from '../../utills/api_helper'
import { useAuth } from '../../modules/auth'
import { ErrorsPage } from '../../modules/errors/ErrorsPage'
import { KTCard, KTCardBody, KTSVG } from '../../../_metronic/helpers'

const lang = localStorage.getItem('i18nConfig')?.slice(17, 19);

function Items({ currentItems }) {
  const intl = useIntl()

  return (
    <>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead className='border-bottom-very-light-blue'>
            <tr className='text-start text-light-blue fw-bolder fs-7 text-uppercase gs-0'>
              <td className='text-nowrap'>{intl.formatMessage({ id: 'COMPANY.NAME' })}</td>
              <td className='text-nowrap'>{intl.formatMessage({ id: 'LAST.NAME' })}</td>
              <td className='text-nowrap'>{intl.formatMessage({ id: 'FIRST.NAME' })}</td>
              <td className='text-nowrap'>{intl.formatMessage({ id: 'EMAIL' })}</td>
              <td className='text-nowrap'>{intl.formatMessage({ id: 'PHONE.NO' })}</td>
              <td className='text-nowrap'>{intl.formatMessage({ id: 'DESCRIPTION' })}</td>
            </tr>
          </thead>
          <tbody className='text-light-blue fw-bold'>
            {currentItems.length ?
              currentItems &&
              currentItems.map((item, index) => (
                <tr key={item._id}>
                  <td className='text-nowrap'>{item?.company_name}</td>
                  <td className='text-nowrap'>{item?.lastname}</td>
                  <td className='text-nowrap'>{item?.firstname}</td>
                  <td className='text-nowrap'>{item?.email}</td>
                  <td className='text-nowrap'>{item?.phone_number}</td>
                  <td className='text-nowrap'>{item?.user_message}</td>
                </tr>
              ))
              : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      {intl.formatMessage({ id: 'NO.RECORDS' })}
                    </div>
                  </td>
                </tr>
              )}
          </tbody>
        </table>
      </div>
    </>
  );
}

function PaginatedItems() {

  useEffect(() => {
    get_contactlist();
  }, [])

  const intl = useIntl()
  const [search, setSearch] = useState<string>("");
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [forcePage, setForcePage] = useState<number>(0)
  const [remountComponent, setRemountComponent] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [userList, setUserList] = useState([]);

  const endOffset = itemOffset + itemsPerPage;
  const showingItemsText = intl.formatMessage({ id: "Showing results from [firstRow] to [lastRow]" }).replace("[firstRow]", "" + (itemOffset + 1)).replace("[lastRow]", "" + (endOffset <= userList.length ? endOffset : userList.length));
  const currentItems = userList.slice(itemOffset, endOffset);

  const pageCount = Math.ceil(userList.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % userList.length;
    setItemOffset(newOffset);
  };

  function get_contactlist(search: string = "") {
    let filter = { email: search }
    contact_list(filter).then((res) => {
      if (res.data.status == "success") {
        setUserList(res.data.data);
      }
    });
  }


  function searchChange(text) {
    setSearch(text);
    if (text === "") {
      get_contactlist("");
    }
  }

  const searchQuery = useCallback(debounce(() => {
    get_contactlist(search)
  }
    , 500), [search]);


  useEffect(() => {
    searchQuery();
    // Cancel the debounce on useEffect cleanup.
    return searchQuery.cancel;
  }, [search, searchQuery]);

  function clearFilter() {
    setSearch("");
    get_contactlist("");
  }

  return (
    <>
      <KTCard>
        <KTCardBody className='py-4'>
          <div className='border-0' >
            <div className='row'>
              <div className='col-lg-1'>
                <div className='col-lg-12 py-2'>
                  {/* setRemountComponent is used to reset selection of active pagination to 1 */}
                  <select className='form-select border-light-blue text-light-blue'
                    onChange={(e) => {
                      setItemOffset(0);
                      setItemsPerPage(parseInt(e.target.value));
                      setForcePage(0);
                      setRemountComponent(Math.random());
                    }}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                  </select>
                </div>
              </div>
              {/* begin::Card toolbar */}
              <div className='col-lg-9'>
                <div className='row'>
                  <div className='col-lg-4 py-2'>
                    <input type="text" value={search} onChange={(e) => searchChange(e.target.value)} className='form-control border-light-blue text-light-blue placeholder-light-blue' placeholder={intl.formatMessage({ id: "Search by email" })} style={{ fontWeight: 200 }} />
                  </div>
                  <div className='col-lg-2 py-2'>
                    <button
                      type='button'
                      className='btn border-light-blue text-light-blue fw-bold me-2 px-6'
                      data-kt-menu-dismiss='true'
                      data-kt-user-table-filter='reset'
                      onClick={clearFilter}
                    >
                      {intl.formatMessage({ id: 'RESET' })}
                    </button>
                  </div>

                </div>
              </div>
              <div className='col-lg-2 py-2'>
              </div>
            </div>
            {/* end::Card toolbar */}
          </div>
        </KTCardBody>
      </KTCard>
      <KTCard className='mt-3'>
        <KTCardBody className='py-4'>
          <Items currentItems={currentItems} />
          <div className='row' key={remountComponent}>

            <div className='col-lg-6 text-light-blue d-flex align-items-center'>{showingItemsText}</div>
            <ReactPaginate
              nextLabel={intl.formatMessage({ id: "next >" })}
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel={intl.formatMessage({ id: "< previous" })}
              pageClassName="page-item"
              pageLinkClassName="page-link text-light-blue"
              previousClassName="page-item"
              previousLinkClassName="page-link text-light-blue"
              nextClassName="page-item"
              nextLinkClassName="page-link text-light-blue"
              breakLabel="..."
              breakClassName="page-item text-light-blue"
              breakLinkClassName="page-link text-light-blue"
              containerClassName="col-lg-6 pagination justify-content-end"
              activeClassName="green-background rounded"
              renderOnZeroPageCount={() => null}
              forcePage={forcePage}
            />
          </div>
        </KTCardBody>
      </KTCard>
    </>
  );
}


const ContactList: FC = () => {

  useEffect(() => {
    moment.locale(lang)
  }, [])

  return (
    <PaginatedItems />
  )
}

const ContactListWrapper: FC = () => {
  const intl = useIntl();
  const { currentUser } = useAuth();

  return (
    <>
      {currentUser?.role !== 'superAdmin' ? (
        <>
          <ErrorsPage />
        </>
      ) : (
        <>
          <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.CONTACTSLIST' })}</PageTitle>
          <ContactList />
        </>
      )}
    </>
  )
}

export { ContactListWrapper }
