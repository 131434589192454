/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { KTCard, KTCardBody } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import { getLayout, useLayout } from '../../../_metronic/layout/core'
import { get_bots, init_richmenu, addRichmenuArea, get_template_list, get_survey_list, delete_area, createRichMenu, create_rich_menu } from '../../utills/api_helper'
import { toast } from 'react-toastify'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'
const lang = localStorage.getItem('i18nConfig')?.slice(17, 19);


const CreateRichMenu: FC = () => {

  const navigate = useNavigate();
  const intl = useIntl()
  const [richmenuImageUploadButton, setRichmenuImageUploadButton] = useState<HTMLInputElement | null>(null);
  const [imgdiv, setImgdiv] = useState<HTMLInputElement | null>(null);
  const [canvas, setCanvas] = useState<any>(null);
  const [ctx, setCtx] = useState<any>(null);
  const [canvasOffset, setCanvasOffset] = useState<any>(null);
  const [scale, setScale] = useState<any>(null);
  const [offsetX, setOffsetX] = useState<any>(null);
  const [offsetY, setOffsetY] = useState<any>(null);
  const [richMenuId, setRichMenuId] = useState<string | undefined>("");
  const [richMenuImage, setRichMenuImage] = useState<any>();
  const [richMenuImageData, setRichMenuImageData] = useState<any>();
  const [bots, setBots] = useState<any>([]);
  const [selectedBot, setSelectedBot] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [xValue, setXValue] = useState<any | undefined>("");
  const [yValue, setYValue] = useState<any | undefined>("");
  const [width, setWidth] = useState<any | undefined>("");
  const [height, setHeight] = useState<any | undefined>("");
  const [label, setLabel] = useState<string | undefined>("");
  const [message, setMessage] = useState<string | undefined>("");
  const [uri, setUri] = useState<string | undefined>("");
  const [displayText, setDisplayText] = useState<string | undefined>("");
  const [selectedAutoReplyType, setSelectedAutoReplyType] = useState<string | undefined>("");
  const [responseMessageText, setResponseMessageText] = useState<string | undefined>("");
  const [responseMessageImage, setResponseMessageImage] = useState<any>("");
  const [responseMessageVideo, setResponseMessageVideo] = useState<any>("");
  const [responseMessageAudio, setResponseMessageAudio] = useState<any>("");
  const [selectedMessageTemplate, setSelectedMessageTemplate] = useState<string | undefined>("");
  const [selectedSurvey, setSelectedSurvey] = useState<string | undefined>("");
  const [selectedReferralUrl, setSelectdeReferralUrl] = useState<string | undefined>("");
  const [selectdeReservationUrl, setselectdeReservationUrl] = useState<string | undefined>("");
  const [chatBarText, setChatBarText] = useState<string>("");
  const [showAddArea, setShowAddArea] = useState<boolean>(false);
  const [areas, setAreas] = useState<any>([]);
  const [refresh, setRefresh] = useState<any>(0);
  const [actionTypes, setActionTypes] = useState(["message", "postback", "uri"]);
  const [seletedActionType, setSeletedActionType] = useState<string | undefined>("");
  const [autoReplyType, setAutoReplyType] = useState(
    [
      { id: "text", display_text: intl.formatMessage({ id: 'text' }) },
      { id: "image", display_text: intl.formatMessage({ id: 'image' }) },
      { id: "template", display_text: intl.formatMessage({ id: 'Message Template' }) },
      { id: "survey", display_text: intl.formatMessage({ id: 'Survey' }) },
      { id: "referral", display_text: intl.formatMessage({ id: 'Referral Template' }) }
    ]);
  const [addAreaLoading, setAddAreaLoading] = useState(false)
  const [createRichmanuLoading, setCreateRichmanuLoading] = useState(false)
  const [templates, setTemplateList] = useState<any>([]);
  const [referral_templates, setReferralTemplateList] = useState<any>([]);
  const [surveys, setSurveyList] = useState<any>([]);
  const [mobileViewModal, setMobileViewModal] = useState<boolean>(false);

  const { setLayout } = useLayout()
  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener('resize', handleWindowSizeChange);

    // setCanvas(document.getElementById("canvas") as HTMLInputElement);
    // setting template config start
    // getting all template config and hiding side menu toggle button
    // coz rectangle is not creating from proper start end point in canvas
    let allConfig = getLayout();
    allConfig.aside.minimize = false;
    setLayout(allConfig)
    // setting template config end
    get_botlist();
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, [])


  function handleWindowSizeChange() {
    if (window.innerWidth <= 768) {
      setMobileViewModal(true);
      console.log("Mobile view")
    }
  }

  function get_botlist() {
    let form_data = {};
    get_bots(form_data).then((res) => {
      if (res.data.status == "success") {
        setBots(res.data.data);
      }
    });
  }

  useEffect(() => {
    if (showAddArea) {
      setRichmenuImageUploadButton(document.getElementById("richmenuImageUploadButton") as HTMLInputElement);
    }
  }, [showAddArea])
  useEffect(() => {
    if (!richMenuImage) {
      setRichmenuImageUploadButton(document.getElementById("richmenuImageUploadButton") as HTMLInputElement);
    }
    if (richMenuImage) {
      setImgdiv(document.getElementById("imgdiv") as HTMLInputElement);

    }
  }, [richMenuImage])


  function intiRichMenu() {
    let form_data = {
      _id: selectedBot.split("::")[0],
      selected: true,
      name: name,
      chatBarText: chatBarText,
      admin_id: selectedBot.split("::")[1],
    };
    init_richmenu(form_data).then((res) => {
      if (res.data.status == "success") {
        setShowAddArea(true);
        setRichMenuId(res.data.data._id);
        toast(res.data.msg,
          { type: "success" });
      } else {
        toast(res.data.msg,
          { type: "error" });
      }
    });

  }

  const handleUploadRichmenu = () => {
    richmenuImageUploadButton?.click();
  };

  function richmenuChange(e) {
    setRichMenuImage(e.target.files[0])
    setTimeout(() => {
      onLoadCanvas()
    }, 1000);
  }

  useEffect(() => {
    if (canvas) {
      onSetCanvas()
    }

  }, [canvas])
  useEffect(() => {
    if (ctx) {
      onSetCtx()
    }

  }, [ctx])
  useEffect(() => {
    if (canvasOffset) {
      onSetCanvasOffset()
    }

  }, [canvasOffset])
  useEffect(() => {
    // if (areas.length) {
    setCreatedAreas()
    // }
  }, [areas])

  var isDrawing = false;
  var startX;
  var startY;
  var mouseX;
  var mouseY;
  var mouseX1;
  var mouseY1;
  var scrollLoded = false;
  function onLoadCanvas() {
    setCanvas(document.getElementById("canvas"));

  }

  function onSetCanvas() {

    setCtx(canvas.getContext("2d"));
    if (!scrollLoded) {

      var timer: any = null;
      document.addEventListener('scroll', (event) => {
        // let scrolled = window.pageYOffset;
        if (timer !== null) {
          clearTimeout(timer);
        }
        timer = setTimeout(function () {
          scrollLoded = false;
          setCanvasOffset(canvas.getBoundingClientRect());
        }, 150);
      });
    }

  }

  function onSetCtx() {
    if (richMenuImage) {
      let img = new Image();
      var objectUrl = URL.createObjectURL(richMenuImage);
      img.src = objectUrl;
      img.onload = function () {
        if (img.width === 2500 && (img.height === 1686 || img.height === 843)) {
          if (imgdiv) {
            const imgScale = img.width / imgdiv.clientWidth;
            setScale(imgScale);
            canvas.style.background = "url('" + URL.createObjectURL(richMenuImage) + "')"
            canvas.style.backgroundSize = "cover";
            canvas.style.backgroundRepeat = "no-repeat";
            canvas.width = img.naturalWidth / imgScale;
            canvas.height = img.naturalHeight / imgScale;
            setCanvasOffset(canvas.getBoundingClientRect());
            setRichMenuImageData({ width: img.width, height: img.height });
          }
        } else {
          toast(intl.formatMessage({ id: 'Image size should be 2500x1686px or 2500x843px' }), { type: "error" })
          setRichMenuImage(undefined)
        }
        URL.revokeObjectURL(objectUrl);
      };
      img.src = URL.createObjectURL(richMenuImage);
    }

  }

  function onSetCanvasOffset() {
    // console.log("onSetCanvasOffset called");
    setOffsetX(canvasOffset.left);
    setOffsetY(canvasOffset.top);

  }

  function resetAllAddAreaFormValues() {
    setXValue("");
    setYValue("");
    setWidth("");
    setHeight("");
    setSeletedActionType("");
    if (seletedActionType == "message") {
      setLabel("");
      setMessage("");
    } else if (seletedActionType == "uri") {
      setLabel("");
      setUri("");
    } else if (seletedActionType == "postback") {
      setLabel("");
      setSelectedAutoReplyType("");
      setDisplayText("");
      if (selectedAutoReplyType == "text") {
        setResponseMessageText("");
      } else if (selectedAutoReplyType == "image") {
        setResponseMessageImage("");
      } else if (selectedAutoReplyType == "video") {
        setResponseMessageVideo("");
      } else if (selectedAutoReplyType == "audio") {
        setResponseMessageAudio("");
      } else if (selectedAutoReplyType == "template") {
        setSelectedMessageTemplate("");
      } else if (selectedAutoReplyType == "survey") {
        setSelectedSurvey("");
      } else if (selectedAutoReplyType == "referral") {
        setSelectdeReferralUrl("");
      }
    }
  }

  function clearSelectedAreaInCanvas() {
    isDrawing = false;
    startX = null;
    startY = null;
    mouseX = null;
    mouseY = null;
    mouseX1 = null;
    mouseY1 = null;
    // ctx.clearRect(0, 0, canvas.width, canvas.height);
    setCreatedAreas();
  }

  function setCreatedAreas() {
    if (ctx) {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      // console.log("setCreatedAreas areas ", areas)
      areas.map((item, index) => {
        // ctx.clearRect(item.bounds.x / scale - 2, item.bounds.y / scale - 2, item.bounds.width / scale, item.bounds.height / scale);
        ctx.fillStyle = "rgba(255,255,255,0.70)";
        ctx.fillRect(item.bounds.x / scale, item.bounds.y / scale, item.bounds.width / scale, item.bounds.height / scale);
        ctx.strokeRect(item.bounds.x / scale, item.bounds.y / scale, item.bounds.width / scale, item.bounds.height / scale);
        ctx.strokeText(index + 1, ((item.bounds.width + item.bounds.x + item.bounds.x) / scale) / 2, ((item.bounds.height + item.bounds.y + item.bounds.y) / scale) / 2);
        // ctx.stroke();
      })
    }
  }

  function validationWithcondition() {
    let commonValidation: any = {
      xValue: Yup.string()
        .required(intl.formatMessage({ id: 'REQUIRED' })),
      yValue: Yup.string()
        .required(intl.formatMessage({ id: 'REQUIRED' })),
      width: Yup.string()
        .required(intl.formatMessage({ id: 'REQUIRED' })),
      height: Yup.string()
        .required(intl.formatMessage({ id: 'REQUIRED' })),
    };
    commonValidation.seletedActionType = Yup.string().required(intl.formatMessage({ id: 'REQUIRED' }));
    if (seletedActionType == "message") {
      commonValidation.label = Yup.string().required(intl.formatMessage({ id: 'REQUIRED' }))
        .max(20, intl.formatMessage({ id: 'MAXLENGTH' }).replace("[maxLength]", "" + 20));
      commonValidation.message = Yup.string().required(intl.formatMessage({ id: 'REQUIRED' }))
        .max(300, intl.formatMessage({ id: 'MAXLENGTH' }).replace("[maxLength]", "" + 300));
    } else if (seletedActionType == "uri") {
      commonValidation.label = Yup.string().required(intl.formatMessage({ id: 'REQUIRED' }));
      commonValidation.uri = Yup.string().required(intl.formatMessage({ id: 'REQUIRED' }));
      commonValidation.uri = Yup.string().matches(
        /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
        intl.formatMessage({ id: "Please enter valid uri"}))
      ;
    } else if (seletedActionType == "postback") {
      commonValidation.label = Yup.string().required(intl.formatMessage({ id: 'REQUIRED' }));
      commonValidation.displayText = Yup.string().required(intl.formatMessage({ id: 'REQUIRED' }));
      commonValidation.selectedAutoReplyType = Yup.string().required(intl.formatMessage({ id: 'REQUIRED' }));
      if (selectedAutoReplyType == "text") {
        commonValidation.responseMessageText = Yup.string().required(intl.formatMessage({ id: 'REQUIRED' }));
      }
      else if (selectedAutoReplyType == "image") {
        commonValidation.responseMessageImage = Yup.string().required(intl.formatMessage({ id: 'REQUIRED' }));
      }
      else if (selectedAutoReplyType == "video") {
        commonValidation.responseMessageVideo = Yup.string().required(intl.formatMessage({ id: 'REQUIRED' }));
      }
      else if (selectedAutoReplyType == "template") {
        commonValidation.selectedMessageTemplate = Yup.string().required(intl.formatMessage({ id: 'REQUIRED' }));
      }
      else if (selectedAutoReplyType == "survey") {
        commonValidation.selectedSurvey = Yup.string().required(intl.formatMessage({ id: 'REQUIRED' }));
      }
      else if (selectedAutoReplyType == "referral") {
        commonValidation.selectedReferralUrl = Yup.string().required(intl.formatMessage({ id: 'REQUIRED' }));
      }
    }
    return Yup.object().shape(commonValidation)
  }

  const initialValues = {
    xValue: '',
    yValue: '',
    width: '',
    height: '',
    seletedActionType: '',
    label: '',
    message: '',
    uri: '',
    displayText: '',
    selectedAutoReplyType: '',
    responseMessageText: '',
    responseMessageImage: undefined,
    responseMessageVideo: '',
    responseMessageAudio: '',
    selectedMessageTemplate: '',
    selectedSurvey: '',
    selectedReferralUrl: ''
  }

  const addAreaSchema = validationWithcondition;

  const formikAddArea = useFormik({
    initialValues,
    validationSchema: addAreaSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      setAddAreaLoading(true)
      let is_file = false;
      let formData = new FormData()
      let richArea: any = {};
      richArea = {
        _id: richMenuId,
        action: {},

        // bounds: {
        //   x: parseInt(xValue),
        //   y: parseInt(yValue),
        //   width: 2400,
        //   height: 761
        // },
        bounds: {
          x: parseInt(xValue),
          y: parseInt(yValue),
          width: parseInt(width),
          height: parseInt(height)
        },
        lang: lang
      };
      if (seletedActionType == "message") {
        richArea.action.text = message;
        richArea.action.label = label;
        richArea.action.type = seletedActionType;
      }
      else if (seletedActionType == "postback") {
        richArea.action.text = displayText;
        richArea.action.type = seletedActionType;
        richArea.message_type = selectedAutoReplyType;
        if (selectedAutoReplyType == "text") {
          richArea.text = responseMessageText;
        }
        else if (selectedAutoReplyType == "image") {
          formData.append("originalContentUrl", responseMessageImage, responseMessageImage.name);
          formData.append("previewImageUrl", responseMessageImage, responseMessageImage.name);
          is_file = true;
        }
        else if (selectedAutoReplyType == "video") {
          formData.append("originalContentUrl", responseMessageVideo, responseMessageVideo.name);
          formData.append("previewImageUrl", "", ""); // need to work on gerate thumbnail of video
          is_file = true;
        }
        else if (selectedAutoReplyType == "template") {
          richArea.template_id = selectedMessageTemplate;
        }
        else if (selectedAutoReplyType == "survey") {
          richArea.survey_id = selectedSurvey;
        }
        else if (selectedAutoReplyType == "referral") {
          richArea.referral_url = selectedReferralUrl;
        }
      }
      else if (seletedActionType == "uri") {
        richArea.action.label = label;
        richArea.action.type = seletedActionType;
        richArea.action.uri = uri;
      }
      // richArea = JSON.stringify(richArea);
      formData.append("richArea", JSON.stringify(richArea))
      try {
        const res: any = await addRichmenuArea(formData, is_file)
        setAddAreaLoading(false)
        setSubmitting(false)
        if (res.data.status == "success") {
          toast(res.data.msg,
            { type: "success" })
          resetForm();
          resetAllAddAreaFormValues();
          clearSelectedAreaInCanvas();
          onSetCanvasOffset();
          setAreas([...areas, res?.data?.data]);
        } else {
          toast(res.data.msg,
            { type: "error" })
        }
      }
      catch (error: any) {
        console.error(error)
        toast(intl.formatMessage({ id: 'ADD.AREA.FAIL' }), { type: "error" })
        setSubmitting(false)
        setAddAreaLoading(false)
      }
    }
  })

  function handleMouseUp(e) {
    isDrawing = false;
    canvas.style.cursor = "default";
    if (mouseX < 0) {
      startX = Math.floor((e.clientX + 1 - offsetX));
      mouseX = mouseX * -1;
    }
    if (mouseY < 0) {
      startY = Math.floor((e.clientY + 1 - offsetY));
      mouseY = mouseY * -1;
    }
    startX = Math.floor(startX * scale) + 1;
    startY = Math.floor(startY * scale) + 1;

    if (mouseX && mouseX) {

      setWidth(mouseX);
      setHeight(mouseY);
      setXValue(startX);
      setYValue(startY);
      formikAddArea.setFieldValue("xValue", startX);
      formikAddArea.setFieldValue("yValue", startY);
      formikAddArea.setFieldValue("width", mouseX);
      formikAddArea.setFieldValue("height", mouseY);
    }
  }
  function handleMouseMove(e) {
    if (isDrawing) {
      mouseX1 = Math.floor((e.clientX - offsetX) * scale);
      mouseY1 = Math.floor((e.clientY - offsetY) * scale);
      // console.log("e.clientX ", e.clientX);
      // console.log("offsetX ", offsetX);
      // console.log("startX ", startX);
      // console.log("scale ", scale);
      mouseX = Math.floor((e.clientX - offsetX - startX) * scale);
      mouseY = Math.floor((e.clientY - offsetY - startY) * scale);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      setCreatedAreas();
      ctx.beginPath();
      ctx.fillStyle = "rgba(255,255,255,0.70)";
      ctx.fillRect(startX, startY, (mouseX1 / scale) - startX, (mouseY1 / scale) - startY);
      ctx.strokeRect(startX, startY, (mouseX1 / scale) - startX, (mouseY1 / scale) - startY);
      ctx.font = "30px sans-serif";
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.strokeText(areas.length + 1, (mouseX1 / scale + startX) / 2, (mouseY1 / scale + startY) / 2);
      // ctx.stroke();
      if ((e.clientX - offsetX) < 1) {
        handleMouseUp(e)
      }
      if ((e.clientX + 2) > (canvas.width + offsetX)) {
        handleMouseUp(e)
      }
      if ((e.clientY - offsetY) < 1) {
        handleMouseUp(e)
      }
      if ((e.clientY + 2) > (canvas.height + offsetY)) {
        handleMouseUp(e)
      }
    }
  }
  function handleMouseDown(e: any) {
    canvas.style.cursor = "crosshair";
    isDrawing = true;
    startX = Math.floor((e.clientX + 1 - offsetX));
    startY = Math.floor((e.clientY + 1 - offsetY));
  }

  function messageResponseImageChange(e) {
    formikAddArea.setFieldValue("responseMessageImage", e.target.files[0])
    setResponseMessageImage(e.target.files[0])
  }
  function messageResponseVideoChange(e) {
    setResponseMessageVideo(e.target.files[0])
  }
  function messageResponseAudioChange(e) {
    setResponseMessageAudio(e.target.files[0])
  }

  function getTemplateList(admin_id = "", type = "template") {
    let form_data = { admin_id: admin_id, type: type }
    get_template_list(form_data).then((res) => {
      if (res.data.status == "success") {
        // console.log("get_template_list res.data.data ", res.data.data);
        setTemplateList(res.data.data);
      }
    });
  }
  function getReferralTemplateList(admin_id = "", type = "template", subtype = "referral_template") {
    let form_data = { admin_id: admin_id, type: type, subtype: subtype }
    get_template_list(form_data).then((res) => {
      if (res.data.status == "success") {
        // console.log("get_template_list res.data.data ", res.data.data);
        setReferralTemplateList(res.data.data);
      }
    });
  }
  function getSurveyList(bot_id) {
    let form_data = { bot_id: bot_id }
    get_survey_list(form_data).then((res) => {
      if (res.data.status == "success") {
        // console.log("get_survey_list res.data.data ", res.data.data);
        setSurveyList(res.data.data);
      }
    });
  }
  function deleteArea(area) {
    let form_data = { richmenu_id: richMenuId, area_id: area._id }
    delete_area(form_data).then((res) => {
      if (res.data.status == "success") {

        let localareas = [...areas];
        localareas.map((item: any, index) => {
          if (area._id == item._id) {
            localareas.splice(index, 1);
          }
        });
        setCanvasOffset(canvas.getBoundingClientRect());
        // ctx.clearRect(0, 0, canvas.width, canvas.height);
        // console.log("deleteArea localareas ", localareas)
        setAreas(localareas);
        // ctx.clearRect(area.bounds.x - 2, area.bounds.y - 2, area.bounds.width - area.bounds.x + 3, area.bounds.height - area.bounds.y + 3);
        toast(res.data.msg,
          { type: "success" })
      } else {
        toast(res.data.msg,
          { type: "error" })
      }
    });
  }

  function createRichMenu() {
    setCreateRichmanuLoading(true);
    let formData = new FormData();
    formData.append("_id", "" + richMenuId?.toString());
    formData.append("width", richMenuImageData.width);
    formData.append("height", richMenuImageData.height);
    formData.append("image", richMenuImage, richMenuImage.name);
    // let form_data = {
    //   _id: richMenuId,
    //   width: richMenuImageData.width,
    //   height: richMenuImageData.height,
    //   image: richMenuImage
    // }
    create_rich_menu(formData, true).then((res) => {
      setCreateRichmanuLoading(false);
      if (res.data.status == "success") {
        toast(res.data.msg,
          { type: "success" })
          navigate("/richmenus");
      } else {
        toast(res.data.msg,
          { type: "error" })
      }
    });
  }


  return (
    <>
      {/* begin::Row */}
      <KTCard className='mb-2'>
        <KTCardBody className='py-4'>
          <div className='row'>
            <div className='col-lg-3'>
              <div className="mb-5">
                <label className="form-label">{intl.formatMessage({ id: 'Select Bot' })}</label>
                <select
                  className='form-select border-light-blue text-light-blue'
                  value={selectedBot}
                  onChange={(e) => { setSelectedBot(e.target.value) }}
                  disabled={showAddArea}
                >
                  <option value="">{intl.formatMessage({ id: 'Select Bot' })}</option>
                  {
                    bots.map((item, index) => {
                      return (
                        <option key={item._id} value={item._id + "::" + item.admin_id._id}>{item.displayName}</option>
                      )
                    })
                  }
                </select>
              </div>
            </div>
            <div className='col-lg-3'>
              <div className="mb-5">
                <label className="form-label">{intl.formatMessage({ id: 'Name' })}</label>
                <input type="text" className='form-control' value={name} onChange={(e) => setName(e.target.value)} maxLength={30} placeholder={intl.formatMessage({ id: 'Name' })} disabled={showAddArea} />
              </div>
            </div>
            <div className='col-lg-3'>
              <div className="mb-5">
                <label className="form-label">{intl.formatMessage({ id: 'ChatBar Text' })}</label>
                <input type="text" className='form-control' value={chatBarText} onChange={(e) => setChatBarText(e.target.value)} maxLength={14} placeholder={intl.formatMessage({ id: 'ChatBar Text' })} disabled={showAddArea} />
              </div>
            </div>
            <div className='col-lg-3'>
              <div className="mb-5">
                <button className="btn dark-green-background text-light-blue mt-8 float-end" onClick={intiRichMenu} disabled={showAddArea}>
                  {intl.formatMessage({ id: 'Add Menu' })}
                  <i className="fas fa-plus text-light-blue ms-2"></i>
                </button>
              </div>
            </div>
          </div>
          {
            showAddArea ?
              <div className='row mt-20'>
                <div className='col-lg-4'>

                  {
                    richMenuImage ?
                      <div id='imgdiv'>
                        <canvas id="canvas" onMouseDown={(e) => handleMouseDown(e)} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
                          <img id="richmenuImage" src="" />
                        </canvas>
                        {/* <label className='text-muted'>Click and hold and then drag to select the area </label> */}
                      </div>
                      :
                      <>
                        <div className="d-flex justify-content-center align-items-center border mb-4 " style={{ width: "90%" }}>
                          <div className="text-center text-muted my-5" >
                            {intl.formatMessage({ id: 'Select a template and upload an appropriate background image' })}
                            <br/>
                            {intl.formatMessage({ id: 'Image size should be 2500x1686px or 2500x843px' })}
                          </div>
                        </div>
                        <div className='mt-8'>
                          <div className='d-flex align-items-center me-2'>
                            <input id="richmenuImageUploadButton" className="d-none" type="file" value={richMenuImage} onChange={richmenuChange} />
                            <button className="btn btn-primary btn-sm" onClick={handleUploadRichmenu}><i className="bi bi-upload fs-4 me-2"></i> {intl.formatMessage({ id: 'Upload Image' })}</button>
                          </div>
                        </div>
                      </>
                  }
                </div>

                <div className='col-lg-8'>
                  <form
                    className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
                    noValidate
                    id='kt_login_signup_form'
                    onSubmit={formikAddArea.handleSubmit}
                  >
                    <div className='row'>
                      <div className='col-lg-3'>
                        <div className="mb-5">
                          <label className="form-label">X</label>
                          <input
                            {...formikAddArea.getFieldProps('xValue')}
                            // className={clsx(
                            //   'form-control form-control-lg',
                            //   { 'is-invalid': formikAddArea.touched.xValue && formikAddArea.errors.xValue },
                            //   {
                            //     'is-valid': formikAddArea.touched.xValue && !formikAddArea.errors.xValue,
                            //   }
                            // )}
                            className='form-control form-control-lg'
                            type="text"
                            placeholder="X"
                            value={xValue}
                            name="xValue"
                            readOnly
                          />
                          {formikAddArea.touched.xValue && formikAddArea.errors.xValue && (
                            <div className='fv-plugins-message-container'>
                              <span className="text-danger">{formikAddArea.errors.xValue}</span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='col-lg-3'>
                        <div className="mb-5">
                          <label className="form-label">Y</label>
                          <input
                            {...formikAddArea.getFieldProps('yValue')}
                            // className={clsx(
                            //   'form-control form-control-lg',
                            //   { 'is-invalid': formikAddArea.touched.yValue && formikAddArea.errors.yValue },
                            //   {
                            //     'is-valid': formikAddArea.touched.yValue && !formikAddArea.errors.yValue,
                            //   }
                            // )}
                            className='form-control form-control-lg'
                            type="text"
                            placeholder="Y"
                            value={yValue}
                            name="yValue"
                            readOnly
                          />
                          {formikAddArea.touched.yValue && formikAddArea.errors.yValue && (
                            <div className='fv-plugins-message-container'>
                              <span className="text-danger">{formikAddArea.errors.yValue}</span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='col-lg-3'>
                        <div className="mb-5">
                          <label className="form-label">{intl.formatMessage({ id: 'Width' })}</label>
                          <input
                            {...formikAddArea.getFieldProps('width')}
                            // className={clsx(
                            //   'form-control form-control-lg',
                            //   { 'is-invalid': formikAddArea.touched.width && formikAddArea.errors.width },
                            //   {
                            //     'is-valid': formikAddArea.touched.width && !formikAddArea.errors.width,
                            //   }
                            // )}
                            className='form-control form-control-lg'
                            type="text"
                            placeholder={intl.formatMessage({ id: 'Width' })}
                            value={width}
                            name="width"
                            readOnly
                          />
                          {formikAddArea.touched.width && formikAddArea.errors.width && (
                            <div className='fv-plugins-message-container'>
                              <span className="text-danger">{formikAddArea.errors.width}</span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='col-lg-3'>
                        <div className="mb-5">
                          <label className="form-label">{intl.formatMessage({ id: 'Height' })}</label>
                          <input
                            {...formikAddArea.getFieldProps('height')}
                            // className={clsx(
                            //   'form-control form-control-lg',
                            //   { 'is-invalid': formikAddArea.touched.height && formikAddArea.errors.height },
                            //   {
                            //     'is-valid': formikAddArea.touched.height && !formikAddArea.errors.height,
                            //   }
                            // )}
                            className='form-control form-control-lg'
                            type="text"
                            placeholder={intl.formatMessage({ id: 'Height' })}
                            value={height}
                            name="height"
                            readOnly
                          />
                          {formikAddArea.touched.height && formikAddArea.errors.height && (
                            <div className='fv-plugins-message-container'>
                              <span className="text-danger">{formikAddArea.errors.height}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className="mb-5">
                        <label className="form-label">{intl.formatMessage({ id: 'Select Type' })}</label>
                        <select
                          {...formikAddArea.getFieldProps('seletedActionType')}
                          // className={clsx(
                          //   'form-select form-control-lg',
                          //   { 'is-invalid': formikAddArea.touched.seletedActionType && formikAddArea.errors.seletedActionType },
                          //   {
                          //     'is-valid': formikAddArea.touched.seletedActionType && !formikAddArea.errors.seletedActionType,
                          //   }
                          // )}
                          className='form-control form-control-lg'
                          name="seletedActionType"
                          value={seletedActionType}
                          onChange={(e) => {
                            formikAddArea.setFieldValue("seletedActionType", e.target.value);
                            setSeletedActionType(e.target.value);
                            setCanvasOffset(canvas.getBoundingClientRect());
                          }
                          }>
                          <option value="">{intl.formatMessage({ id: 'Select Type' })}</option>
                          {
                            actionTypes.map((item, index) => {
                              return (
                                <option key={index} value={item}>{item}</option>
                              )
                            })
                          }
                        </select>
                        {formikAddArea.touched.seletedActionType && formikAddArea.errors.seletedActionType && (
                          <div className='fv-plugins-message-container'>
                            <span className="text-danger">{formikAddArea.errors.seletedActionType}</span>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className='row'>
                      <div className="mb-5">
                        <label className="form-label">{intl.formatMessage({ id: 'Label' })}</label>
                        <input
                          {...formikAddArea.getFieldProps('label')}
                          // className={clsx(
                          //   'form-control form-control-lg',
                          //   { 'is-invalid': formikAddArea.touched.label && formikAddArea.errors.label },
                          //   {
                          //     'is-valid': formikAddArea.touched.label && !formikAddArea.errors.label,
                          //   }
                          // )}
                          className='form-control form-control-lg'
                          type="text"
                          placeholder={intl.formatMessage({ id: 'Label' })}
                          maxLength={20}
                          value={label}
                          name="label"
                          onChange={(e) => { setLabel(e.target.value); formikAddArea.setFieldValue("label", e.target.value) }}
                        />
                        {formikAddArea.touched.label && formikAddArea.errors.label && (
                          <div className='fv-plugins-message-container'>
                            <span className="text-danger">{formikAddArea.errors.label}</span>
                          </div>
                        )}
                      </div>
                    </div>
                    {
                      seletedActionType == "message" ?
                        <div className='row'>
                          <div className="mb-5">
                            <label className="form-label">{intl.formatMessage({ id: 'Message' })}</label>
                            <textarea
                              {...formikAddArea.getFieldProps('message')}
                              // className={clsx(
                              //   'form-control form-control-lg',
                              //   { 'is-invalid': formikAddArea.touched.message && formikAddArea.errors.message },
                              //   {
                              //     'is-valid': formikAddArea.touched.message && !formikAddArea.errors.message,
                              //   }
                              // )}
                              className='form-control form-control-lg'
                              placeholder={intl.formatMessage({ id: 'Message' })}
                              maxLength={300}
                              value={message}
                              name="message"
                              onChange={(e) => {
                                setMessage(e.target.value);
                                formikAddArea.setFieldValue("message", e.target.value);
                              }}
                            ></textarea>
                            {formikAddArea.touched.message && formikAddArea.errors.message && (
                              <div className='fv-plugins-message-container'>
                                <span className="text-danger">{formikAddArea.errors.message}</span>
                              </div>
                            )}
                          </div>
                        </div>
                        :
                        null
                    }
                    {
                      seletedActionType == "uri" ?
                        <>
                          <div className='row'>
                            <div className="mb-5">
                              <label className="form-label">{intl.formatMessage({ id: 'Uri' })}</label>
                              <input
                                {...formikAddArea.getFieldProps('uri')}
                                // className={clsx(
                                //   'form-control form-control-lg',
                                //   { 'is-invalid': formikAddArea.touched.uri && formikAddArea.errors.uri },
                                //   {
                                //     'is-valid': formikAddArea.touched.uri && !formikAddArea.errors.uri,
                                //   }
                                // )}
                                className='form-control form-control-lg'
                                type="url"
                                placeholder={intl.formatMessage({ id: 'Uri' })}
                                name="uri"
                                value={uri}
                                onChange={(e) => { setUri(e.target.value); formikAddArea.setFieldValue("uri", e.target.value) }}
                              />
                              {formikAddArea.touched.uri && formikAddArea.errors.uri && (
                                <div className='fv-plugins-message-container'>
                                  <span className="text-danger">{formikAddArea.errors.uri}</span>
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                        :
                        null
                    }
                    {
                      seletedActionType == "postback" ?
                        <>
                          <div className='row'>
                            <div className="mb-5">
                              <label className="form-label">{intl.formatMessage({ id: 'Display Text' })}</label>
                              <input
                                {...formikAddArea.getFieldProps('displayText')}
                                type="url"
                                className='form-control'
                                placeholder={intl.formatMessage({ id: 'Display Text' })}
                                name="displayText"
                                value={displayText}
                                onChange={(e) => { setDisplayText(e.target.value); formikAddArea.setFieldValue("displayText", e.target.value) }}
                              />
                              {formikAddArea.touched.displayText && formikAddArea.errors.displayText && (
                                <div className='fv-plugins-message-container'>
                                  <span className="text-danger">{formikAddArea.errors.displayText}</span>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='row'>
                            <div className="mb-5">
                              <label className="form-label">{intl.formatMessage({ id: 'Auto reply type' })}</label>
                              <select
                                {...formikAddArea.getFieldProps('selectedAutoReplyType')}
                                name="selectedAutoReplyType"
                                value={selectedAutoReplyType}
                                onChange={(e) => {
                                  if (e.target.value == "template") {
                                    getTemplateList();
                                  }
                                  else if (e.target.value == "survey") {
                                    getSurveyList(selectedBot);
                                  }
                                  else if (e.target.value == "referral") {
                                    getReferralTemplateList();
                                  }
                                  formikAddArea.setFieldValue("selectedAutoReplyType", e.target.value);
                                  setSelectedAutoReplyType(e.target.value)
                                }}
                                className='form-select border-light-blue text-light-blue'
                              >
                                <option>{intl.formatMessage({ id: 'Select auto reply type' })}</option>
                                {
                                  autoReplyType.map((item, index) => {
                                    return (
                                      <option key={item.id} value={item.id}>{item.display_text.charAt(0).toUpperCase() + item.display_text.slice(1)}</option>
                                    )
                                  })
                                }
                              </select>
                              {formikAddArea.touched.selectedAutoReplyType && formikAddArea.errors.selectedAutoReplyType && (
                                <div className='fv-plugins-message-container'>
                                  <span className="text-danger">{formikAddArea.errors.selectedAutoReplyType}</span>
                                </div>
                              )}
                            </div>
                          </div>
                          {
                            selectedAutoReplyType == "text" ?
                              <div className='row'>
                                <div className="mb-5">
                                  <label className="form-label">{intl.formatMessage({ id: 'Response Message' })}</label>
                                  <textarea
                                    {...formikAddArea.getFieldProps('responseMessageText')}
                                    // className={clsx(
                                    //   'form-control form-control-lg',
                                    //   { 'is-invalid': formikAddArea.touched.responseMessageText && formikAddArea.errors.responseMessageText },
                                    //   {
                                    //     'is-valid': formikAddArea.touched.responseMessageText && !formikAddArea.errors.responseMessageText,
                                    //   }
                                    // )}
                                    className='form-control form-control-lg'
                                    placeholder={intl.formatMessage({ id: 'Response Message' })}
                                    maxLength={20}
                                    value={responseMessageText}
                                    name="responseMessageText"
                                    onChange={(e) => { setResponseMessageText(e.target.value); formikAddArea.setFieldValue("responseMessageText", e.target.value) }}
                                  >

                                  </textarea>
                                  {formikAddArea.touched.responseMessageText && formikAddArea.errors.responseMessageText && (
                                    <div className='fv-plugins-message-container'>
                                      <span className="text-danger">{formikAddArea.errors.responseMessageText}</span>
                                    </div>
                                  )}
                                </div>
                              </div>
                              :
                              null
                          }


                          {
                            selectedAutoReplyType == "image" ?
                              <div className='row'>
                                <div className="mb-5">
                                  <label className="form-label">{intl.formatMessage({ id: 'Response Message' })}</label>
                                  <input
                                    {...formikAddArea.getFieldProps('responseMessageText')}
                                    className="form-control form-control-lg"
                                    type="file"
                                    name="responseMessageImage"
                                    value={undefined}
                                    onChange={messageResponseImageChange}
                                    placeholder={intl.formatMessage({ id: 'Select file' })}
                                  />
                                  {formikAddArea.touched.responseMessageImage && formikAddArea.errors.responseMessageImage && (
                                    <div className='fv-plugins-message-container'>
                                      <span className="text-danger">{formikAddArea.errors.responseMessageImage}</span>
                                    </div>
                                  )}
                                </div>
                              </div>
                              :
                              null
                          }
                          {
                            selectedAutoReplyType == "video" ?
                              <div className='row'>
                                <div className="mb-5">
                                  <label className="form-label">{intl.formatMessage({ id: 'Response Message' })}</label>
                                  <input
                                    className="form-control form-control-lg"
                                    type="file"
                                    value={undefined}
                                    onChange={messageResponseVideoChange}
                                  />
                                </div>
                              </div>
                              :
                              null
                          }
                          {
                            selectedAutoReplyType == "audio" ?
                              <div className='row'>
                                <div className="mb-5">
                                  <label className="form-label">{intl.formatMessage({ id: 'Response Message' })}</label>
                                  <input
                                    className="form-control form-control-lg"
                                    type="file"
                                    value={undefined}
                                    onChange={messageResponseAudioChange}
                                  />
                                </div>
                              </div>
                              :
                              null
                          }
                          {
                            selectedAutoReplyType == "template" ?
                              <div className='row'>
                                <div className="mb-5">
                                  <label className="form-label">{intl.formatMessage({ id: 'Message Template' })}</label>
                                  <select
                                    {...formikAddArea.getFieldProps('selectedMessageTemplate')}
                                    name="selectedMessageTemplate"
                                    value={selectedMessageTemplate}
                                    className='form-select border-light-blue text-light-blue'
                                    onChange={(e) => {
                                      formikAddArea.setFieldValue("selectedMessageTemplate", e.target.value);
                                      setSelectedMessageTemplate(e.target.value)
                                      setCanvasOffset(canvas.getBoundingClientRect());
                                    }}
                                  >
                                    <option>{intl.formatMessage({ id: 'Select Message Template' })}</option>
                                    {
                                      templates.map((item, index) => {
                                        return (
                                          <option key={item._id} value={item._id}>{item.template.template_name}</option>

                                        )
                                      })
                                    }
                                  </select>
                                  {formikAddArea.touched.selectedMessageTemplate && formikAddArea.errors.selectedMessageTemplate && (
                                    <div className='fv-plugins-message-container'>
                                      <span className="text-danger">{formikAddArea.errors.selectedMessageTemplate}</span>
                                    </div>
                                  )}
                                </div>
                              </div>
                              :
                              null
                          }
                          {
                            selectedAutoReplyType == "survey" ?
                              <div className='row'>
                                <div className="mb-5">
                                  <label className="form-label">{intl.formatMessage({ id: 'Survey' })}</label>
                                  <select className='form-select'>
                                    <option>{intl.formatMessage({ id: 'Select Survey' })}</option>
                                    {
                                      surveys.map((item, index) => {
                                        return (
                                          <option key={item._id} value={item._id}>{item.survey_name}</option>

                                        )
                                      })
                                    }
                                  </select>
                                </div>
                              </div>
                              :
                              null
                          }
                          {
                            selectedAutoReplyType == "referral" ?
                              <div className='row'>
                                <div className="mb-5">
                                  <label className="form-label">{intl.formatMessage({ id: 'Referral Template' })}</label>
                                  <select
                                    {...formikAddArea.getFieldProps('selectedReferralUrl')}
                                    name="selectedReferralUrl"
                                    value={selectedReferralUrl}
                                    className='form-select'
                                    onChange={(e) => {
                                      formikAddArea.setFieldValue("selectedReferralUrl", e.target.value);
                                      setSelectdeReferralUrl(e.target.value);
                                      setCanvasOffset(canvas.getBoundingClientRect());
                                    }}>
                                    <option>{intl.formatMessage({ id: 'Select Referral Template' })}</option>
                                    {
                                      referral_templates.map((item, index) => {
                                        return (
                                          <option key={item._id} value={item._id}>{item.template.template_name}</option>
                                        )
                                      })
                                    }
                                  </select>
                                  {formikAddArea.touched.selectedReferralUrl && formikAddArea.errors.selectedReferralUrl && (
                                    <div className='fv-plugins-message-container'>
                                      <span className="text-danger">{formikAddArea.errors.selectedReferralUrl}</span>
                                    </div>
                                  )}
                                </div>
                              </div>
                              :
                              null
                          }
                          {
                            // selectedAutoReplyType == "reservation" ?
                            //   <div className='row'>
                            //     <div className="mb-5">
                            //       <label className="form-label">Reservation Url</label>
                            //       <select className='form-select'>
                            //         <option>Select Reservation Url</option>
                            //         <option>Option 1</option>
                            //         <option>Option 1</option>
                            //       </select>
                            //     </div>
                            //   </div>
                            //   :
                            //   null
                          }
                        </>
                        :
                        null
                    }
                    <div className='row'>
                      <div className="mb-5">
                        <button type="submit"
                          className="btn btn-primary"
                          disabled={formikAddArea.isSubmitting || !formikAddArea.isValid}>
                          {!addAreaLoading && <span><i className="bi bi-plus-lg fs-4 me-2"></i>{intl.formatMessage({ id: "Add Area" })}</span>}
                          {addAreaLoading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              {intl.formatMessage({ id: "WAIT" })}
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              :
              null
          }
        </KTCardBody>
      </KTCard>
      {
        areas.length ?
          <>
            <div className='row'>
              {
                areas.map((item: any, index) => {
                  return (

                    <div className='col-3' key={index}>
                      <KTCard className='mb-2 fixed-area-card-height'>
                        <KTCardBody className='px-3 py-4'>
                          <h4>
                            <div className='row'>
                              <div className='col-6 align-middle'>{intl.formatMessage({ id: 'Area' })} {index + 1}</div>
                              <div className='col-6 text-end'>
                                <OverlayTrigger
                                  delay={{ hide: 50, show: 50 }}
                                  overlay={(props) => (
                                    <Tooltip {...props}>
                                      {intl.formatMessage({ id: 'Delete' })}
                                    </Tooltip>
                                  )}
                                  placement="top">
                                  <button className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" onClick={() => deleteArea(item)}><i className="fas fa-trash"></i></button>
                                </OverlayTrigger>
                              </div>
                            </div>
                          </h4>
                          <table className='table border table-row-dashed'>
                            <tbody>
                              <tr>
                                <td className='p-1 fw-bold'>X</td>
                                <td className='p-1'>{item.bounds.x}</td>
                              </tr>
                              <tr>
                                <td className='p-1 fw-bold'> Y</td>
                                <td className='p-1'>{item.bounds.y}</td>
                              </tr>
                              <tr>
                                <td className='p-1 fw-bold'>{intl.formatMessage({ id: 'Width' })}</td>
                                <td className='p-1'>{item.bounds.width}</td>
                              </tr>
                              <tr>
                                <td className='p-1 fw-bold'> {intl.formatMessage({ id: 'Height' })}</td>
                                <td className='p-1'>{item.bounds.height}</td>
                              </tr>
                              <tr>
                                <td className='p-1 fw-bold'> {intl.formatMessage({ id: 'Select Type' })}</td>
                                <td className='p-1'>{item.action?.type}</td>
                              </tr>
                              <>
                                <tr>
                                  <td className='p-1 fw-bold'> {intl.formatMessage({ id: 'Label' })}</td>
                                  <td className='p-1'>{item.action.label}</td>
                                </tr>
                                {
                                  item.action.type == "message" ?
                                    <tr>
                                      <td className='p-1 fw-bold'> {intl.formatMessage({ id: 'Message' })}</td>
                                      <OverlayTrigger
                                        delay={{ hide: 50, show: 50 }}
                                        overlay={(props) => (
                                          <Tooltip {...props}>
                                            {item.action.text}
                                          </Tooltip>
                                        )}
                                        placement="top">
                                        <td className='p-1'>
                                          {item.action.text.length > 20 ? item.action.text.substring(0, 20) + "..." : item.action.text}
                                        </td>
                                      </OverlayTrigger>
                                    </tr>
                                    :
                                    null
                                }
                              </>
                              {
                                item.action.type == "uri" ?

                                  <tr>
                                    <td className='p-1 fw-bold'> {intl.formatMessage({ id: 'Uri' })}</td>
                                    <OverlayTrigger
                                      delay={{ hide: 50, show: 50 }}
                                      overlay={(props) => (
                                        <Tooltip {...props}>
                                          {item.action.uri}
                                        </Tooltip>
                                      )}
                                      placement="top">

                                      <td className='p-1 text-break'><a href={item.action.uri} target="_blank"> {item.action.uri.length > 20 ? item.action.uri.substring(0, 20) + "..." : item.action.uri}</a></td>
                                    </OverlayTrigger>
                                  </tr>

                                  :
                                  null
                              }
                              {
                                item.action.type == "postback" ?
                                  <>

                                    <tr>
                                      <td className='p-1 fw-bold'> {intl.formatMessage({ id: 'Display Text' })}</td>
                                      <OverlayTrigger
                                        delay={{ hide: 50, show: 50 }}
                                        overlay={(props) => (
                                          <Tooltip {...props}>
                                            {item.action.text}
                                          </Tooltip>
                                        )}
                                        placement="top">

                                        <td className='p-1'>
                                          {item.action.text.length > 15 ? item.action.text.substring(0, 15) + "..." : item.action.text}
                                        </td>
                                      </OverlayTrigger>
                                    </tr>
                                    <tr>
                                      <td className='p-1 fw-bold'> {intl.formatMessage({ id: 'Auto reply type' })}</td>
                                      <td className='p-1'> {item.message.type}</td>
                                    </tr>
                                    {
                                      item.message.type == "text" ?
                                        <tr>
                                          <td className='p-1 fw-bold'> {intl.formatMessage({ id: 'Response Message' })}</td>
                                          <OverlayTrigger
                                            delay={{ hide: 50, show: 50 }}
                                            overlay={(props) => (
                                              <Tooltip {...props}>
                                                {item.message.text}
                                              </Tooltip>
                                            )}
                                            placement="top">

                                            <td className='p-1'>
                                              {item.message.text.length > 20 ? item.message.text.substring(0, 20) + "..." : item.message.text}
                                            </td>
                                          </OverlayTrigger>
                                        </tr>
                                        :
                                        null
                                    }
                                    {
                                      item.message.type == "image" ?
                                        <tr>
                                          <td className='p-1 fw-bold'> {intl.formatMessage({ id: 'Response Message' })}</td>
                                          <td className='p-1'> <img src={item.message.previewImageUrl} width="40" /></td>
                                        </tr>
                                        :
                                        null
                                    }
                                    {
                                      item.message.type == "video" ?
                                        <tr>
                                          <td className='p-1 fw-bold'> {intl.formatMessage({ id: 'Response Message' })}</td>
                                          <td className='p-1'> sdfsfg</td>
                                        </tr>
                                        :
                                        null
                                    }
                                    {
                                      item.message.type == "template" ?
                                        <tr>
                                          <td className='p-1 fw-bold'> {intl.formatMessage({ id: 'Message Template' })}</td>
                                          <td className='p-1'> {item.message.template_id}</td>
                                        </tr>
                                        :
                                        null
                                    }
                                    {
                                      item.message.type == "survey" ?
                                        <tr>
                                          <td className='p-1 fw-bold'> {intl.formatMessage({ id: 'Survey' })}</td>
                                          <td className='p-1'> {item.message.template_id}</td>
                                        </tr>
                                        :
                                        null
                                    }
                                    {
                                      item.message.type == "referral" ?
                                        <tr>
                                          <td className='p-1 fw-bold'> {intl.formatMessage({ id: 'Referral Template' })}</td>
                                          <td className='p-1'> {item.message.template_id}</td>
                                        </tr>
                                        :
                                        null
                                    }
                                  </>
                                  :
                                  null
                              }
                            </tbody>
                          </table>
                        </KTCardBody>
                      </KTCard>
                    </div>
                  )
                })
              }
            </div>
            <div className='row pb-10 pt-10'>
              <div className='col-3'>
                <button
                  className="btn btn-primary"
                  onClick={() => createRichMenu()}
                  disabled={createRichmanuLoading}>
                  {!createRichmanuLoading && <span><i className="bi bi-plus-lg fs-4 me-2"></i>{intl.formatMessage({ id: "Create Rich Menu" })}</span>}
                  {createRichmanuLoading && (
                    <span className='indicator-progress' style={{ display: 'block' }}>
                      {intl.formatMessage({ id: "WAIT" })}
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </>
          :
          null
      }
      <Modal
        show={mobileViewModal}
        size="sm">
        <Modal.Header >
          <Modal.Title className='text-light-blue'>{intl.formatMessage({ id: 'Phone' })}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='text-light-blue'>
          <p>{intl.formatMessage({ id: 'This feature is not available for phone' })}</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn text-light-blue"
            data-bs-dismiss="modal"
            id="close_create_bot_modal"
            onClick={() => navigate("/richmenus")}
          >
            {intl.formatMessage({ id: 'GO TO LIST' })}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const CreateRichMenusWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Create Rich Menu' })}</PageTitle>
      <CreateRichMenu />
    </>
  )
}

export { CreateRichMenusWrapper }
