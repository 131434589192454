import { toast } from "react-toastify";

export const checkAccess = function (currentUser, feature, withSuperAdmin = false) {
    if (withSuperAdmin) {
        if (currentUser?.role === "superAdmin" || (Object.prototype.hasOwnProperty.call(currentUser, "unique_features") && currentUser?.unique_features.includes(feature))) {
            return true;
        } else {
            return false;
        }
    } else {
        if ((Object.prototype.hasOwnProperty.call(currentUser, "unique_features") && currentUser?.unique_features.includes(feature))) {
            return true;
        } else {
            return false;
        }
    }
}
export const getRedirectRoute = function (currentUser) {
    return "/bots";
    let route: any = "";
    if (Object.prototype.hasOwnProperty.call(currentUser, "unique_features")) {
        if (currentUser?.unique_features.includes("bots")) {
            return "/bots";
        }
        else if (currentUser?.unique_features.includes("richmenus")) {
            return "/richmenus"
        }
        else if (currentUser?.unique_features.includes("scenerio")) {
            return "/scenarios"
        }
        else if (currentUser?.unique_features.includes("groups")) {
            return "/groups"
        }
        else if (currentUser?.unique_features.includes("users")) {
            return "/users"
        }
        else if (currentUser?.unique_features.includes("message_templates")) {
            return "/message_templates"
        }
        else {
            return "/error/404";
        }
    } else {
        toast("Please contact to superadmin to get some features.", { type: "error" })
        return "/error/404";
    }
} 