/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { KTCard, KTCardBody } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import ReactPaginate from 'react-paginate'
import moment from 'moment'
import { Row, Col, Modal, Container } from 'react-bootstrap'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { get_bots, update_user_tag, get_followers } from '../../utills/api_helper'
import { useAuth } from '../../modules/auth'

const lang = localStorage.getItem('i18nConfig')?.slice(17, 19)

function PaginatedItems({items}) {
  const intl = useIntl()
  // const [searchTerm, setSearchTerm] = useState<string>("");
  const [admin, setAdmin] = useState<string | undefined>()
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [forcePage, setForcePage] = useState<number>(0)
  const [remountComponent, setRemountComponent] = useState(0)
  const [userGroupList, setUserGroupList] = useState<any>([])
  const [displayViewModal, setDisplayViewModal] = useState(false)
  const [displayName, setDisplayName] = useState('')
  const [uniqId, setUniqId] = useState('')
  const [createdAt, setCreatedAt] = useState('')
  const [tagName, setTagName] = useState('')
  const [refreshAdmin, setRefreshAdmin] = useState(0)
  const [botName, setBotName] = useState('')
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState('')

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0)

  useEffect(() => {
    get_followers({ bot_id: admin }).then((res) => {
      setUserGroupList(res.data.data)
    })
  }, [admin, refreshAdmin])

  const handleClose = () => {
    setDisplayViewModal(false)
    // formik.resetForm();
  }

  const handleShow = (user) => {
    setDisplayName(user.displayName)
    setUniqId(user.referral_code)
    setCreatedAt(user.created_at)
    setBotName(user.bot_id.displayName)
    setTagName(user.tagName)
    setId(user._id)
    formik_tagName.setFieldValue('tagName', user.tagName)
    setDisplayViewModal(true)
  }

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage
  const showingItemsText = intl.formatMessage({ id: "Showing results from [firstRow] to [lastRow]" }).replace("[firstRow]", "" + (itemOffset + 1)).replace("[lastRow]", "" + (endOffset <= userGroupList.length ? endOffset : userGroupList.length));

  // const showingItemsText = `Showing items from ${itemOffset + 1} to ${
  //   endOffset <= userGroupList.length ? endOffset : userGroupList.length
  // }`
  // console.log(`Loading items from ${itemOffset} to ${endOffset} item.length ${items.length}`);
  // const currentItems = items.slice(itemOffset, endOffset);
  const currentItems = userGroupList.slice(itemOffset, endOffset)

  const pageCount = Math.ceil(userGroupList.length / itemsPerPage)

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % userGroupList.length
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setItemOffset(newOffset)
  }

  const onChangeTagName = (event) => {
    setTagName(event.target.value)
    formik_tagName.setFieldValue('tagName', event.target.value)
  }

  const createTagSchema = Yup.object().shape({
    tagName: Yup.string().max(30, intl.formatMessage({ id: 'CHARACTES.LIMIT' })),
  })

  const formik_tagName = useFormik({
    initialValues: {
      tagName: '',
    },
    validationSchema: createTagSchema,
    onSubmit: async (values, { resetForm, setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const res: any = await update_user_tag({ _id: id, tagName: values.tagName, lang: lang })
        if (res.data.status == 'success') {
          setSubmitting(false)
          setLoading(false)
          toast(res.data.msg, { type: 'success' })
          resetForm()
          const close_popUp = document.getElementById('close_tag_modal') as HTMLInputElement
          close_popUp.click()
          setRefreshAdmin(Math.random() * 100)
        } else {
          toast(res.data.msg, { type: 'error' })
          const close_popUp = document.getElementById('close_tag_modal') as HTMLInputElement
          close_popUp.click()
        }
      } catch (error: any) {
        console.error(error)
        toast('User creation failed', { type: 'error' })
        resetForm()
        setSubmitting(false)
        setLoading(false)
        const close_popUp = document.getElementById('close_tag_modal') as HTMLInputElement
        close_popUp.click()
      }
    },
  })

  return (
    <>
      <KTCard>
        <KTCardBody className='py-4'>
          <div className='border-0'>
            <div className='row'>
              <div className='col-lg-1'>
                <div className='col-lg-12 py-2'>
                  {/* setRemountComponent is used to reset selection of active pagination to 1 */}
                  <select
                    className='form-select border-light-blue text-light-blue'
                    onChange={(e) => {
                      setItemOffset(0)
                      setItemsPerPage(parseInt(e.target.value))
                      setForcePage(0)
                      setRemountComponent(Math.random())
                    }}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                  </select>
                </div>
              </div>
              {/* begin::Card toolbar */}
              <div className='col-lg-10'>
                <div className='row'>
                  <div className='col-lg-3 py-2'>
                    <select
                      className='form-select border-light-blue text-light-blue'
                      // data-placeholder='Select Admin'
                      onChange={(e) => setAdmin(e.target.value)}
                      value={admin}
                      style={{ fontWeight: 200 }}
                    >
                      <option value=''>{intl.formatMessage({ id: 'SELECT.BOT' })}</option>
                      {items.map((bots: any, index) => {
                        return (
                          <option key={index.toString()} value={bots._id}>
                            {bots.displayName}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                  <div className='col-lg-3 py-2'>
                    <button
                      type='button'
                      className='btn border-light-blue text-light-blue fw-bold me-2 px-6'
                      onClick={() => {
                        setAdmin('')
                      }}
                    >
                      {intl.formatMessage({ id: 'RESET' })}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </KTCardBody>
      </KTCard>
      <KTCard className='mt-3'>
        <KTCardBody className='py-4'>
          {/* USERLIST FOR BOT DISPLAY MODAL */}
          <div className='table-responsive'>
            <table
              id='kt_table_users'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer text-light-blue'
            >
              <thead className='border-bottom-very-light-blue'>
                <tr className='text-start  fw-bolder fs-7 text-uppercase gs-0'>
                  {/* <td>{intl.formatMessage({id: 'ID'})}</td> */}
                  <td className='text-nowrap'>{intl.formatMessage({ id: 'NAME' })}</td>
                  <td className='text-nowrap'>{intl.formatMessage({ id: 'REFERRED.BY' })}</td>
                  <td className='text-nowrap'>{intl.formatMessage({ id: 'TOTAL.REFERRALS' })}</td>
                  <td className='text-nowrap'>{intl.formatMessage({ id: 'TAG' })}</td>
                  {/* <td>{intl.formatMessage({id: 'FOLLOW.UNFOLLOW'})}</td> */}
                  <td className='text-nowrap'>{intl.formatMessage({ id: 'ACTION' })}</td>
                </tr>
              </thead>
              <tbody className=' fw-bold'>
                {currentItems.length ? (
                  currentItems &&
                  currentItems.map((item, index) => (
                    <tr key={index}>
                      {/* <td>{item.referral_code}</td> */}
                      <td className='text-nowrap'>{item.displayName}</td>
                      {item?.referral_parent && item?.referral_parent !== null ? (
                        <Link
                          to={'/user/referrals/' + item.referral_parent._id + '/' + admin}
                          target='_blank'
                        >
                          <td>{item?.referral_parent?.displayName}</td>
                        </Link>
                      ) : (
                        <td>---</td>
                      )}
                      <td className='text-nowrap'>{item.referral_count}</td>
                      <td className='text-nowrap'>{!item.tagName ? '---' : item.tagName}</td>
                      {/* <td>{item.follower_event_type}</td> */}
                      <td className='text-nowrap'>
                        <OverlayTrigger
                          delay={{ hide: 50, show: 50 }}
                          overlay={(props) => (
                            <Tooltip {...props}>{intl.formatMessage({ id: 'VIEW' })}</Tooltip>
                          )}
                          placement='top'
                        >
                          <button
                            type='button'
                            className='btn btn-icon btn-sm btn-bg-light green-background me-1'
                            onClick={() => {
                              handleShow(item)
                            }}
                          >
                            <i className='bi bi-eye-fill text-light-blue'></i>
                          </button>
                        </OverlayTrigger>

                        <OverlayTrigger
                          delay={{ hide: 50, show: 50 }}
                          overlay={(props) => (
                            <Tooltip {...props}>{intl.formatMessage({ id: 'REF.TREE' })}</Tooltip>
                          )}
                          placement='top'
                        >
                          <Link
                            to={'/user/referrals/' + item._id + '/' + admin}
                            type='button'
                            className='btn btn-icon btn-sm btn-bg-light green-background me-1'
                          >
                            {' '}
                            <i className='bi bi-sign-intersection-y text-light-blue'></i>
                          </Link>
                        </OverlayTrigger>

                        <OverlayTrigger
                          delay={{ hide: 50, show: 50 }}
                          overlay={(props) => (
                            <Tooltip {...props}>{intl.formatMessage({ id: 'INTERACTIONS' })}</Tooltip>
                          )}
                          placement='top'
                        >
                          <Link
                            to={'/user/interactions/' + item._id + '/' + admin}
                            type='button'
                            className='btn btn-icon btn-sm btn-bg-light green-background me-1'
                          >
                            {' '}
                            <i className='bi bi bi-people text-light-blue'></i>
                          </Link>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        {intl.formatMessage({ id: 'NO.RECORDS' })}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className='row' key={remountComponent}>
            <div className='col-lg-6 d-flex align-items-center'>{showingItemsText}</div>
            <ReactPaginate
              nextLabel={intl.formatMessage({ id: "next >" })}
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel={intl.formatMessage({ id: "< previous" })}
              pageClassName='page-item'
              pageLinkClassName='page-link text-light-blue'
              previousClassName='page-item'
              previousLinkClassName='page-link text-light-blue'
              nextClassName='page-item'
              nextLinkClassName='page-link text-light-blue'
              breakLabel='...'
              breakClassName='page-item text-light-blue'
              breakLinkClassName='page-link text-light-blue'
              containerClassName='col-lg-6 pagination justify-content-end'
              activeClassName='green-background rounded'
              renderOnZeroPageCount={() => null}
              forcePage={forcePage}
            />
          </div>
        </KTCardBody>
      </KTCard>

      <Modal
        size='lg'
        show={displayViewModal}
        onHide={handleClose}
        dialogClassName='modal-90w'
        aria-labelledby='example-custom-modal-styling-title'
      >
        <Modal.Header>
          <Modal.Title >
            <b className='text-light-blue'>{intl.formatMessage({ id: 'USER.DETAILS' })}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='show-grid text-light-blue'>
          <Container>
            <Row>
              <Col xs={6} md={6} sm={2}>
                <b>{intl.formatMessage({ id: 'NAME' })}</b> : {displayName}
              </Col>
              <Col xs={6} md={6} sm={2}>
                <b>{intl.formatMessage({ id: 'ID' })}</b> :{uniqId}
              </Col>
            </Row>
            <Row style={{ marginTop: '30px' }}>
              <Col xs={6} md={6} sm={2}>
                <b>{intl.formatMessage({ id: 'BOT.NAME' })}</b> : {botName}
              </Col>
              <Col xs={6} md={6} sm={2}>
                <b>{intl.formatMessage({id: 'CREATED.ON'})}</b> :
                {moment(createdAt).format('MMM D YYYY, h:mm:ss a')}
              </Col>
            </Row>
            <Row style={{ marginTop: '40px' }}>
              <Col xs={12} md={8} sm={4}>
                <OverlayTrigger
                  delay={{ hide: 50, show: 50 }}
                  overlay={(props) => (
                    <Tooltip {...props}>{intl.formatMessage({ id: 'PUSH.MSG' })}</Tooltip>
                  )}
                  placement='top'
                >
                  <Link
                    to={'/chat/user/' + id}
                    type='button'
                    className='btn btn-icon btn-sm btn-bg-light green-background me-1'
                  >
                    {' '}
                    <i className='bi bi-chat-square-text-fill text-light-blue'></i>
                  </Link>
                </OverlayTrigger>

                <OverlayTrigger
                  delay={{ hide: 50, show: 50 }}
                  overlay={(props) => (
                    <Tooltip {...props}>{intl.formatMessage({ id: 'REF.TREE' })}</Tooltip>
                  )}
                  placement='top'
                >
                  <Link
                    to={'/user/referrals/' + id + '/' + admin}
                    type='button'
                    className='btn btn-icon btn-sm btn-bg-light green-background me-1'
                  >
                    {' '}
                    <i className='bi bi-sign-intersection-y text-light-blue'></i>
                  </Link>
                </OverlayTrigger>
              </Col>
            </Row>
            <form onSubmit={formik_tagName.handleSubmit} style={{ marginTop: '20px' }}>
              <div className='col-md-6' style={{ marginBottom: '15px' }}>
                <label className='form-label text-light-blue'>{intl.formatMessage({ id: 'TAG.NAME' })}</label>
                <input
                  {...formik_tagName.getFieldProps('tagName')}
                  className={clsx(
                    'form-control form-control-lg border-light-blue',
                    { 'is-invalid': formik_tagName.touched.tagName && formik_tagName.errors.tagName },
                    {
                      'is-valid': formik_tagName.touched.tagName && !formik_tagName.errors.tagName,
                    }
                  )}
                  name='tagName'
                  type='text'
                  maxLength={30}
                  onChange={(e) => onChangeTagName(e)}
                  value={tagName ? tagName : ''}
                  placeholder={intl.formatMessage({ id: 'TAG.NAME.PLACEHOLDER' })}
                />
                {formik_tagName.touched.tagName && formik_tagName.errors.tagName && (
                  <div className='fv-plugins-message-container'>
                    <span className="text-danger">{formik_tagName.errors.tagName}</span>
                  </div>
                )}
              </div>
              <button type='submit' className='btn green-background text-light-blue'>
                {intl.formatMessage({ id: 'UPDATE' })}
              </button>
            </form>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' className='btn green-background text-light-blue' id='close_tag_modal' onClick={handleClose}>
            {intl.formatMessage({ id: 'CLOSE' })}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const UserList: FC = () => {
  const [botList, setBotList] = useState([])
  const { currentUser } = useAuth();


  useEffect(() => {
    let filter: any = {}
    if (currentUser?.role === 'superAdmin') {
      filter = {}
    } else {
      filter = { admin_id: currentUser?._id }
    }
    get_bots(filter).then((res) => {
      setBotList(res?.data?.data)
    })
  }, [])

  return (
    <PaginatedItems items={botList} />
  )
}

const UsersWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.USERS' })}</PageTitle>
      <UserList />
    </>
  )
}

export { UsersWrapper }
