/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import Select from 'react-select';
import { PageTitle } from '../../../_metronic/layout/core'
import { KTCard, KTCardBody, KTSVG } from '../../../_metronic/helpers'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { create_template } from '../../utills/api_helper'
import { toast } from 'react-toastify';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';

const lang = localStorage.getItem('i18nConfig')?.slice(17, 19);


const ConfirmTemplate: FC = () => {

  return (
    <>
      <KTCard>
        <KTCardBody className='p-2'>
          <PaginatedItems />
        </KTCardBody>
      </KTCard>
    </>
  )
}

function PaginatedItems() {
  const navigate = useNavigate();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [text, setText] = useState("");
  const [templateType, setTemplateType] = useState("message");
  const [actions, setActions] = useState<any | undefined>([{ type: "message", label: "", text: "" }])


  const initialValues = {
    templateName: '',
    text: '',
  }

  const createButtonSchema = Yup.object().shape({
    templateName: Yup.string()
      .required(intl.formatMessage({ id: 'TEMPLATE.REQ' })),
    text: Yup.string()
      .required(intl.formatMessage({ id: 'NAME.REQUIRED' }))
  })

  const formik_confirm_type = useFormik({
    initialValues,
    validationSchema: createButtonSchema,
    onSubmit: async (values, { resetForm, setStatus, setSubmitting }) => {
      if (actions.length >= 2) {
        let template = JSON.stringify({
          template_name: values.templateName,
          type: "confirm",
          text: values.text,
          actions: actions,
        })
        let form_data = new FormData();

        form_data.append('type', "template" + "");
        form_data.append('altText', "This is confirm template" + "");
        form_data.append('lang', lang + "");
        form_data.append('template', template + "");
        setLoading(true)
        try {
          const res: any = await create_template(form_data)
          if (res.data.status == "success") {
            setSubmitting(false)
            setLoading(false)
            toast(res.data.msg, { type: "success" })
            resetForm()
            navigate("/message-templates")
            // setTimeout(() => {
            //   window.location.href = "/message-templates";
            // }, 1500);
          } else {
            setSubmitting(false)
            setLoading(false)
            toast(res.data.msg, { type: "error" })
            // resetForm()
          }
        } catch (error: any) {
          console.error(error)
          toast(intl.formatMessage({ id: 'TEMPLATE.CREATE.FAIL' }), { type: "error" })
          setSubmitting(false)
          setLoading(false)
          // resetForm()
        }
      } else {
        toast(intl.formatMessage({ id: 'Please add 2 actions' }), { type: "error" })
      }
    }
  })

  const onChangeTemplateName = (event) => {
    setTemplateName(event.target.value)
    formik_confirm_type.setFieldValue("templateName", event.target.value)
  }

  const onChangeText = (e) => {
    setText(e.target.value)
    formik_confirm_type.setFieldValue("text", e.target.value)
  }

  const handleLabelChange = (index, value) => {
    const updatedFields = [...actions];
    updatedFields[index].label = value;
    setActions(updatedFields);
  }
  const handleDataChange = (index, value) => {
    const updatedFields = [...actions];
    updatedFields[index].text = value;
    setActions(updatedFields);
  }

  function addAction() {
    if (actions.length < 2) {
      setActions([...actions, { type: templateType, label: "", text: "" }])
    } else {
      toast(intl.formatMessage({ id: 'MAX.ALLOWED.2' }), { type: "error" })
    }
  }

  function deleteAction(index) {
    if (actions.length === 1) {
      toast(intl.formatMessage({ id: 'MIN.ALLOWED.1' }), { type: "error" })
    } else {
      const updatedFields = [...actions];
      updatedFields.splice(index, 1);
      setActions(updatedFields);
    }
  }

  function checkLabelValidation(textbox) {
    if (textbox.target.value === '') {
      textbox.target.setCustomValidity(intl.formatMessage({ id: 'LABEL.REQ' }));
    } else {
      textbox.target.setCustomValidity('');
    }
    return true;
  }

  function checkDataValidation(textbox) {
    if (textbox.target.value === '') {
      textbox.target.setCustomValidity(intl.formatMessage({ id: 'DATA.REQ' }));
    } else {
      textbox.target.setCustomValidity('');
    }
    return true;
  }



  return (
    <>
      <div className='border-0' >
        <div className='col-lg-7 py-5'>
          <form onSubmit={formik_confirm_type.handleSubmit}>
            <div className='row my-6 '>
              <h4><label className="form-label text-light-blue">{intl.formatMessage({ id: 'TEMPLATE.NAME' })}*</label></h4>
              <input
                {...formik_confirm_type.getFieldProps('templateName')}
                className={clsx(
                  'form-control form-control-lg border-light-blue text-light-blue',
                  { 'is-invalid': formik_confirm_type.touched.templateName && formik_confirm_type.errors.templateName },
                  {
                    'is-valid': formik_confirm_type.touched.templateName && !formik_confirm_type.errors.templateName,
                  }
                )}
                name='templateName'
                type="text"
                maxLength={30}
                onChange={(e) => onChangeTemplateName(e)}
                value={templateName}
              />
            </div>
            <div className='row my-6 '>
              <h4><label className="form-label text-light-blue">{intl.formatMessage({ id: 'TEXT' })}*</label></h4>
              <textarea
                {...formik_confirm_type.getFieldProps('text')}
                className={clsx(
                  'form-control form-control-lg border-light-blue text-light-blue',
                  { 'is-invalid': formik_confirm_type.touched.text && formik_confirm_type.errors.text },
                  {
                    'is-valid': formik_confirm_type.touched.text && !formik_confirm_type.errors.text,
                  }
                )}
                name='text'
                // type="text"
                maxLength={240}
                onChange={(e) => onChangeText(e)}
                value={text}
              />
            </div>
            <div className='row my-6'>
              <div className='col-lg-3 py-2'>
                <h4><label className="form-label text-light-blue">{intl.formatMessage({ id: 'ACTIONS' })}*</label></h4>
                <button className='btn green-background text-light-blue btn-sm btn-block' onClick={() => addAction()}>
                  {intl.formatMessage({ id: 'ADD.ACTION' })}
                  <i className="fas fa-plus text-light-blue ms-2"></i>
                </button>
              </div>
            </div>
            <div className='row my-6 '>
              {actions.map((item, index) => {
                return (
                  <div key={index} className='row my-6'>
                    <div className='col-lg-4 py-2'>
                      <h4><label className="form-label text-light-blue">{intl.formatMessage({ id: 'TEMPLATE.TYPE' })}*</label></h4>
                      <input
                        className="form-control form-control-lg border-light-blue text-light-blue"
                        name='action.label'
                        type="text"
                        defaultValue={templateType}
                        disabled
                      />
                    </div>
                    <div className='col-lg-3 py-2'>
                      <h4><label className="form-label text-light-blue">{intl.formatMessage({ id: 'TEMPLATE.LABEL' })}*</label></h4>
                      <input
                        className="form-control form-control-lg border-light-blue text-light-blue"
                        name='action.label'
                        type="text"
                        maxLength={240}
                        onChange={(event) => handleLabelChange(index, event.target.value)}
                        value={actions[index].label}
                        required
                        onInvalid={(e) => checkLabelValidation(e)}
                        onInput={(e) => checkLabelValidation(e)}
                      />
                    </div>
                    <div className='col-lg-3 py-2'>
                      <h4><label className="form-label text-light-blue">{intl.formatMessage({ id: 'TEXT' })}*</label></h4>
                      <input
                        className="form-control form-control-lg border-light-blue text-light-blue"
                        name='action.text'
                        type="text"
                        maxLength={100}
                        onChange={(event) => handleDataChange(index, event.target.value)}
                        value={actions[index].text}
                        required
                        onInvalid={(e) => checkDataValidation(e)}
                        onInput={(e) => checkDataValidation(e)}
                      />

                    </div>
                    <div className='col-lg-2 py-2 mt-10'>
                      <OverlayTrigger
                        delay={{ hide: 50, show: 50 }}
                        overlay={(props) => (
                          <Tooltip {...props}>
                            {intl.formatMessage({ id: 'DEL.ACTION' })}
                          </Tooltip>
                        )}
                        placement="top">
                        <button
                          type='button'
                          style={{ display: index === 0 ? 'none' : 'inline-block' }}
                          className='btn btn-icon btn-md green-background btn-color-danger me-1'
                          onClick={() => deleteAction(index)}
                        >
                          <i className='fa fa-trash'></i>
                        </button>
                      </OverlayTrigger>
                    </div>

                  </div>
                )
              })}
            </div>
            <div className='row my-4'>
              <div className='col-lg-3 py-2'>
                {!loading && <button type="submit" className='btn green-background text-light-blue btn-sm btn-block'>{intl.formatMessage({ id: 'SUBMIT' })}</button>}
                {loading && (
                  <button className='btn green-background text-light-blue btn-sm btn-block' disabled>{intl.formatMessage({ id: 'WAIT' })}</button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}


const ConfirmTemplateWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'CONFIRM' })}</PageTitle>
      <ConfirmTemplate />
    </>
  )
}

export { ConfirmTemplateWrapper }