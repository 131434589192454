/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { KTCard, KTCardBody } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import ReactPaginate from 'react-paginate';
import moment from 'moment'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap'
import Select from 'react-select';
import {
  remove_group_user,
  get_followers,
  add_group_user,
  get_user_groups,
  update_group_name
} from '../../utills/api_helper'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useParams } from "react-router-dom";


const lang : any = localStorage.getItem('i18nConfig')?.slice(17, 19);


const GroupViewWrapperList: FC = () => {

  return (
    <PaginatedItems />
  )
}

function PaginatedItems() {
  const intl = useIntl()
  let params = useParams();
  const [details, setDetails] = useState<any>([]);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [forcePage, setForcePage] = useState<number>(0)
  const [remountComponent, setRemountComponent] = useState(0);
  const [groupName, setGroupName] = useState("");
  const [groupId, setGroupId] = useState("");
  const [refreshGroup, setRefreshGroup] = useState(0);
  const [displayAddModal, setDisplayAddModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedMembers, setSelectedMembers] = useState<any>([]);
  const [totalSelected, setTotalSelected] = useState<any>([]);
  const [prevAdmins, setPrevAdmins] = useState<any>([]);
  const [options, setOptions] = useState([{ value: "", label: "" }]);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    moment.locale(lang);
    get_user_groups({ bot_id: params.botid, group_id: params.userid }).then((res) => {
      setDetails(res?.data?.data[0]);
      setGroupName(res?.data?.data[0].name)
      setGroupId(res?.data?.data[0]._id)
      let prevAdmins: any = [];
      res?.data?.data[0]?.join_details.map((prevJoinee, idx) => {
        prevAdmins.push(prevJoinee.user_id)
        setPrevAdmins(prevAdmins)
      })
    });
  }, [refreshGroup])

  const usersOptions = async () => await get_followers({ bot_id: params.botid }).then((res) => {
    if (res.data.data) {
      setDisplayAddModal(true)
      let option: any = [];
      res.data.data.map((admin, index) => {
        option.push({ value: admin._id, label: admin.displayName })
      })
      const displayOptions = option.filter((obj: any) => !prevAdmins.includes(obj.value))
      setOptions(displayOptions);
    }
  })

  const endOffset = itemOffset + itemsPerPage;
  const showingItemsText = intl.formatMessage({ id: "Showing results from [firstRow] to [lastRow]" }).replace("[firstRow]", "" + (itemOffset + 1)).replace("[lastRow]", "" + (endOffset <= details?.join_details?.length ? endOffset : details?.join_details?.length));
  // const showingItemsText = `Showing items from ${itemOffset + 1} to ${endOffset <= details?.join_details?.length ? endOffset : details?.join_details?.length}`;
  // const currentItems = details?.join_details?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(details?.join_details?.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % details?.join_details?.length;
    setItemOffset(newOffset);
  };

  async function removeUser(id) {
    await remove_group_user({ _id: groupId, user_id: id,lang: lang }).then(success => {
      setRefreshGroup(Math.random() * 100)
      toast(success.data.msg, { type: "success" })
    }).catch(err => {
      setRefreshGroup(Math.random() * 100)
      toast(err.data.msg, { type: "error" })
    })
  }

  const handleClose = () => {
    setDisplayAddModal(false);
  };

  const onChangeGroupName = (event) => {
    setGroupName(event.target.value)
    formik_groupName.setFieldValue("groupName", event.target.value)
  }


  const changeGroupSchema = Yup.object().shape({
    groupName: Yup.string()
      .max(30, intl.formatMessage({ id: 'CHARACTES.LIMIT' })),
  })

  function onChangeSelectedMembers(opts) {
    let values: any = [];
    opts.map((admin, index) => {
      values.push(admin.value)
    })
    setTotalSelected(values)
    setSelectedMembers(opts ? opts.map((opt) => opt) : []);
  }


  const formik_groupName = useFormik({
    initialValues: {
      groupName: ''
    },
    validationSchema: changeGroupSchema,
    onSubmit: async (values, { resetForm, setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const res: any = await update_group_name({ _id: details._id, name: values.groupName, lang: lang })
        if (res.data.status == "success") {
          setSubmitting(false)
          setLoading(false)
          toast(res.data.msg, { type: "success" })
          setRefreshGroup(Math.random() * 100)
        } else {
          toast(res.data.msg, { type: "error" })
        }
      } catch (error: any) {
        console.error(error)
        toast("Group Name Updation failed", { type: "error" })
        resetForm()
        setSubmitting(false)
        setLoading(false)
      }
    }
  })

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let res = await add_group_user({ _id: details._id, members: totalSelected, lang: lang })
      if (res.data.status === "success") {
        toast(res.data.msg, { type: "success" })
        setRefreshGroup(Math.random() * 100)
        setSelectedMembers("")
        const close_popUp = document.getElementById('close_add_modal') as HTMLInputElement
        close_popUp.click()
      } else {
        toast(res.data.msg, { type: "error" })
        setTotalSelected("")
        const close_popUp = document.getElementById('close_add_modal') as HTMLInputElement
        close_popUp.click()
      }
    } catch (err: any) {
      console.error(err)
      setTotalSelected("")
      toast("Add User failed", { type: "error" })
      const close_popUp = document.getElementById('close_add_modal') as HTMLInputElement
      close_popUp.click()
    }
  }


  return (
    <>
      <KTCard>
        <KTCardBody className='py-4'>
          <div className='row'>

            <div className='row'>
              <div className='col-lg-6 py-2' style={{ fontSize: '15px', marginTop: '10px' }}>
                <form onSubmit={formik_groupName.handleSubmit}>
                  <div className='row'>
                    <div className='col-lg-3 d-flex align-items-center'>

                      <b>{intl.formatMessage({ id: 'GRP.NAME' })}</b>
                    </div>
                    <div className='col-lg-6'>
                      <input
                        {...formik_groupName.getFieldProps('groupName')}
                        className={clsx(
                          'form-control form-control-lg border-light-blue',
                          { 'is-invalid': formik_groupName.touched.groupName && formik_groupName.errors.groupName },
                          {
                            'is-valid': formik_groupName.touched.groupName && !formik_groupName.errors.groupName,
                          }
                        )}
                        name='groupName'
                        type="text"
                        maxLength={30}
                        onChange={(e) => onChangeGroupName(e)}
                        value={groupName ? groupName : ""}
                      />
                      {formik_groupName.touched.groupName && formik_groupName.errors.groupName && (
                        <div className='fv-plugins-message-container'>
                          <span className="text-danger">{formik_groupName.errors.groupName}</span>
                        </div>
                      )}
                    </div>
                    <div className='col-lg-3 d-flex align-items-center ml-2 mt-3'>
                      <button type="submit" className='btn btn-sm dark-green-background text-light-blue'>{intl.formatMessage({ id: 'UPDATE' })}</button>
                    </div>
                  </div>
                </form>
              </div>
              <div className='col-lg-6 py-2 d-flex align-items-center' style={{ fontSize: '15px', marginTop: '10px' }}>
                <b className='text-nowrap'>{intl.formatMessage({ id: 'BOT.NAME' })}: </b> &nbsp;&nbsp;<span>{details?.bot_id?.displayName}</span>
              </div>
              <div className='col-lg-6 py-2 d-flex align-items-center' style={{ fontSize: '15px', marginTop: '10px' }}>
                <b>{intl.formatMessage({ id: 'MEMBERS' })}:</b> &nbsp;&nbsp;<span>{details?.members?.length}</span>
              </div>
              <div className='col-lg-6 py-2 d-flex align-items-center' style={{ fontSize: '15px', marginTop: '10px' }}>
                <b>{intl.formatMessage({ id: 'CREATED.ON' })}:</b> &nbsp;&nbsp;<span>{moment(details?.created_at).format('LL')}</span>
              </div>
            </div>
            {/* <div className='row'>
              <form onSubmit={formik_groupName.handleSubmit} style={{ marginTop: '20px' }}>
                <div className='col-md-4' style={{ marginBottom: '15px' }}>
                  <label className="form-label">{intl.formatMessage({ id: 'GRP.NAME' })}</label>
                  <input
                    {...formik_groupName.getFieldProps('groupName')}
                    className={clsx(
                      'form-control form-control-lg ',
                      { 'is-invalid': formik_groupName.touched.groupName && formik_groupName.errors.groupName },
                      {
                        'is-valid': formik_groupName.touched.groupName && !formik_groupName.errors.groupName,
                      }
                    )}
                    name='groupName'
                    type="text"
                    maxLength={30}
                    onChange={(e) => onChangeGroupName(e)}
                    value={groupName ? groupName : ""}
                  />
                  {formik_groupName.touched.groupName && formik_groupName.errors.groupName && (
                    <div className='fv-plugins-message-container'>
                      <span className="text-danger">{formik_groupName.errors.groupName}</span>
                    </div>
                  )}
                </div>
                <button type="submit" className='btn btn-primary'>{intl.formatMessage({ id: 'UPDATE' })}</button>
              </form>
            </div> */}
          </div>

        </KTCardBody>
      </KTCard>
      <KTCard className='mt-3'>
        <KTCardBody className='py-4'>
          <div className='row'>
            <div className='col-lg-1'>
              <div className='col-lg-12'>
                {/* setRemountComponent is used to reset selection of active pagination to 1 */}
                <select className='form-select border-light-blue text-light-blue' onChange={(e) => { setItemOffset(0); setItemsPerPage(parseInt(e.target.value)); setForcePage(0); setRemountComponent(Math.random()); }}>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                </select>
              </div>
            </div>
            <div className='col-lg-11'>
              <button type="submit" style={{ float: 'right' }} className='btn dark-green-background text-light-blue mt-3' onClick={() => usersOptions()}>{intl.formatMessage({ id: 'ADD.USER' })}</button>
            </div>
          </div>
        </KTCardBody>
      </KTCard>
      <KTCard className='mt-3'>
        <KTCardBody className='py-4'>

          {/* USERLIST FOR BOT DISPLAY MODAL */}
          <div className='table-responsive'>
            <table
              id='kt_table_users'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            >
              <thead className='border-bottom-very-light-blue'>
                <tr className='text-start text-light-blue fw-bolder fs-7 text-uppercase gs-0'>
                  <td>#</td>
                  <td className='text-nowrap'>{intl.formatMessage({ id: 'NAME' })}</td>
                  <td className='text-nowrap'>{intl.formatMessage({ id: 'JOINED.ON' })}</td>
                  <td className='text-nowrap'>{intl.formatMessage({ id: 'ACTION' })}</td>
                </tr>
              </thead>
              <tbody className='text-light-blue fw-bold'>
                {details?.members?.length ?
                  details?.members &&
                  details.members.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className='text-nowrap'>{item?.displayName}</td>
                      <td className='text-nowrap'>{moment(item?.created_at).format('LL')}</td>
                      <td className='text-nowrap'>
                        <OverlayTrigger
                          delay={{ hide: 50, show: 50 }}
                          overlay={(props) => (
                            <Tooltip {...props}>
                              {intl.formatMessage({ id: 'REMOVE.USER' })}
                            </Tooltip>
                          )}
                          placement="top">
                          <button type='button' className='btn btn-icon btn-sm green-background btn-color-danger me-1' onClick={() => removeUser(item._id)}><i className='fa fa-trash'></i></button>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))
                  : (
                    <tr>
                      <td colSpan={7}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          {intl.formatMessage({ id: 'NO.RECORDS' })}
                        </div>
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
          <div className='row' key={remountComponent}>

            <div className='col-lg-6 d-flex align-items-center text-light-blue'>{showingItemsText}</div>
            <ReactPaginate
              nextLabel={intl.formatMessage({ id: "next >" })}
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel={intl.formatMessage({ id: "< previous" })}
              pageClassName="page-item"
              pageLinkClassName="page-link text-light-blue"
              previousClassName="page-item"
              previousLinkClassName="page-link text-light-blue"
              nextClassName="page-item"
              nextLinkClassName="page-link text-light-blue"
              breakLabel="..."
              breakClassName="page-item text-light-blue"
              breakLinkClassName="page-link text-light-blue"
              containerClassName="col-lg-6 pagination justify-content-end"
              activeClassName="green-background rounded"
              renderOnZeroPageCount={() => null}
              forcePage={forcePage}
            />
          </div>
        </KTCardBody>
      </KTCard>

      {/* ADD USER MODAL */}
      <Modal
        size="lg"
        show={displayAddModal}
        onHide={handleClose}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header>
          <Modal.Title id="example-custom-modal-styling-title text-light-blue"><b className='text-light-blue'>{intl.formatMessage({ id: 'ADD.USER' })}</b></Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body className="show-grid">
            <div className='col-md-12 mb-3' >
              <b className='text-light-blue'>{intl.formatMessage({ id: 'GRP.NAME' })} :</b> {groupName}
            </div>
            <div className='col-md-12 mb-3 mt-8' >
              <label className="form-label mb-3 text-light-blue">{intl.formatMessage({ id: 'ADD.MEMBERS' })}</label>
              <Select
                isMulti
                value={selectedMembers}
                onChange={onChangeSelectedMembers}
                placeholder={<div>{intl.formatMessage({ id: 'SELECT.MEMBERS' })}</div>}
                options={options}
                className="react-select-border-light-blue text-light-blue"
                styles={{ control: (styles, state) => ({ ...styles, borderStyle: 'none !important', backgroundColor: "transparent" }) }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn text-light-blue" id="close_add_modal" onClick={handleClose}>{intl.formatMessage({ id: 'CLOSE' })}</button>
            <button type="submit" className='btn dark-green-background text-light-blue'>{intl.formatMessage({ id: 'ADD' })}</button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}

const GroupViewWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'GRP.VIEW' })}</PageTitle>
      <GroupViewWrapperList />
    </>
  )
}

export { GroupViewWrapper }