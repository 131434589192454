/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import Select from 'react-select'
import { PageTitle } from '../../../_metronic/layout/core'
import { KTCard, KTCardBody } from '../../../_metronic/helpers'
import { ButtonsTemplateWrapper } from './ButtonsTemplate'
import { CarouselTemplateWrapper } from './CarouselTemplate'
import { ConfirmTemplateWrapper } from './ConfirmTemplate'
import { ReferralTemplate } from './ReferralTemplate'
import { ImageCarouselTemplateWrapper } from './ImageCarouselTemplate'
import { useParams } from "react-router-dom";
import { get_template_list_by_id } from '../../utills/api_helper'

const TemplateList: FC = () => {
  return (
    <>
      <KTCard>
        <KTCardBody>
          <PaginatedItems />
        </KTCardBody>
      </KTCard>
    </>
  )
}

function PaginatedItems() {
  const intl = useIntl();
  let params = useParams();
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [template, setTemplate] = useState<any>(null);


  const options = [
    { value: 'Select', label: intl.formatMessage({ id: 'SELECT' }) },
    { value: 'Buttons', label: intl.formatMessage({ id: 'BUTTONS' }) },
    { value: 'Confirm', label: intl.formatMessage({ id: 'CONFIRM' }) },
    { value: 'Carousel', label: intl.formatMessage({ id: 'CAROUSEL' }) },
    { value: 'Image Carousel', label: intl.formatMessage({ id: 'IMG.CAROUSEL' }) },
    { value: 'Referral', label: intl.formatMessage({ id: 'REFERRAL' }) },
  ];

  useEffect(() => {
    if (params.id) {
      get_template_details(params.id);
    }
  }, [])

  const onChangeSelection = (e) => {
    setSelectedOption(e)
  }

  function get_template_details(id) {
    let filter = { type: "template", id: id };
    get_template_list_by_id(filter).then((res) => {
      setTemplate(res?.data?.data);
      let selected_option: any = {};
      selected_option = res?.data?.data.template.type == "referral_template" ? options[5] : options[0];
      setSelectedOption(selected_option);
    });
  }

  return (
    <>
      <div className='border-0'>
        <div className='row'>
          <div className='col-lg-3 py-3'>
            <label><h4 className='text-light-blue'>{intl.formatMessage({ id: 'SELECT.TYPE' })}</h4></label>
            <Select
              // defaultValue={template && getDefaultTemplateSelect(template.template.type)}
              onChange={(e) => onChangeSelection(e)}
              options={options}
              value={selectedOption}
              className="react-select-border-light-blue text-light-blue"
              styles={{ control: (styles, state) => ({ ...styles, borderStyle: 'none !important', backgroundColor: "transparent" }) }}
              isDisabled={params.id ? true :false}
            />
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              {selectedOption && selectedOption.value === 'Buttons' && <ButtonsTemplateWrapper />}
              {selectedOption && selectedOption.value === 'Confirm' && <ConfirmTemplateWrapper />}
              {selectedOption && selectedOption.value === 'Carousel' && <CarouselTemplateWrapper />}
              {selectedOption && selectedOption.value === 'Image Carousel' && <ImageCarouselTemplateWrapper />}
              {selectedOption && selectedOption.value === 'Referral' && <ReferralTemplate templateDetails={template} />}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const CreateMessageTemplateWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'CREATE.TEMPLATE' })}</PageTitle>
      <TemplateList />
    </>
  )
}

export { CreateMessageTemplateWrapper }
