/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import Select from 'react-select';
import { PageTitle } from '../../../_metronic/layout/core'
import { KTCard, KTCardBody, KTSVG } from '../../../_metronic/helpers'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { create_template } from '../../utills/api_helper'
import { toast } from 'react-toastify';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';


const lang = localStorage.getItem('i18nConfig')?.slice(17, 19);

const ButtonsTemplate: FC = () => {

  return (
    <>
      <KTCard>
        <KTCardBody className='p-2'>
          <PaginatedItems />
        </KTCardBody>
      </KTCard>
    </>
  )
}

function PaginatedItems(props) {
  const navigate = useNavigate();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [imgBgColor, setImgBgColor] = useState("#FFFFFF");
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [imgAspect, setImgAspect] = useState<any>(null);
  const [imgSize, setImgSize] = useState<any>(null);
  const [inputFile, setInputFile] = useState<any>(null);

  const img_aspect_ratio_options = [
    { value: "rectangle", label: intl.formatMessage({ id: 'RECTANGLE' }) },
    { value: "square", label: intl.formatMessage({ id: 'SQUARE' }) }
  ]

  const img_size_options = [
    { value: "cover", label: intl.formatMessage({ id: 'COVER' }) },
    { value: "contain", label: intl.formatMessage({ id: 'CONTAIN' }) }
  ]

  const add_actions_options = [
    { value: "postback", label: intl.formatMessage({ id: 'POSTBACK' }) },
    { value: "uri", label: intl.formatMessage({ id: 'URI' }) }
  ]

  const [actions, setActions] = useState<any | undefined>([{ type: add_actions_options[0].value, label: "", data: "" }])
  const [selectOptions, setSelectOptions] = useState<any>(add_actions_options);


  const initialValues = {
    templateName: '',
    thumbnailImg: {},
    img_bg_color: '#FFFFFF',
    img_aspect_ratio: img_aspect_ratio_options[0].value,
    img_size: img_size_options[0].value,
    title: '',
    text: '',
  }

  const createButtonSchema = Yup.object().shape({
    templateName: Yup.string()
      .required(intl.formatMessage({ id: 'TEMPLATE.REQ' })),
    text: Yup.string()
      .required(intl.formatMessage({ id: 'NAME.REQUIRED' }))
  })


  const formik_buttons_type = useFormik({
    initialValues,
    validationSchema: createButtonSchema,
    onSubmit: async (values, { resetForm, setStatus, setSubmitting }) => {
      // console.log("onSubmit called actions", actions);
      let updatedActions = [...actions];
      actions.map((item, index) => {
        if (item.type == "message") {
          updatedActions[index].text = updatedActions[index].data;
        } else if (item.type == "uri") {
          updatedActions[index].uri = updatedActions[index].data;
        }
      });
      setActions(updatedActions);

      let template = JSON.stringify({
        template_name: values.templateName,
        type: "buttons",
        text: values.text,
        actions: updatedActions,
        title: values.title,
        imageBackgroundColor: values.img_bg_color,
        imageAspectRatio: values.img_aspect_ratio
      })
      let form_data = new FormData();

      form_data.append('type', "template" + "");
      form_data.append('altText', "This is button template" + "");
      form_data.append('lang', lang + "");
      form_data.append('thumbnailImageUrl', inputFile);
      form_data.append('template', template + "");
      setLoading(true)
      try {
        const res: any = await create_template(form_data)
        if (res.data.status == "success") {
          setSubmitting(false)
          setLoading(false)
          toast(res.data.msg, { type: "success" })
          resetForm()
          navigate("/message-templates")
          // setTimeout(() => {
          //   window.location.href = "/message-templates";
          // }, 1500);
        } else {
          setSubmitting(false)
          setLoading(false)
          toast(res.data.msg, { type: "error" })
          // resetForm()
        }
      } catch (error: any) {
        console.error(error)
        toast(intl.formatMessage({ id: 'TEMPLATE.CREATE.FAIL' }), { type: "error" })
        setSubmitting(false)
        setLoading(false)
        // resetForm()
      }
    }
  })

  const onChangeTemplateName = (event) => {
    setTemplateName(event.target.value)
    formik_buttons_type.setFieldValue("templateName", event.target.value)
  }

  const onChangeImgAspect = (e) => {
    setImgAspect(e.value)
    formik_buttons_type.setFieldValue("img_aspect_ratio", e.value)
  }
  const onChangeImgSize = (e) => {
    setImgSize(e.value)
    formik_buttons_type.setFieldValue("img_size", e.value)
  }
  const onChangeImgBgColor = (e) => {
    setImgBgColor(e.target.value)
    formik_buttons_type.setFieldValue("img_bg_color", e.target.value)
  }
  const onChangeTitle = (e) => {
    setTitle(e.target.value)
    formik_buttons_type.setFieldValue("title", e.target.value)
  }
  const onChangeText = (e) => {
    setText(e.target.value)
    formik_buttons_type.setFieldValue("text", e.target.value)
  }

  const handleTypeChange = (index, e) => {
    const updatedAction = { ...actions[index], type: e.target.value };
    const updatedActions = [...actions];
    updatedActions[index] = updatedAction;
    setActions(updatedActions);
  }

  const handleLabelChange = (index, value) => {
    const updatedFields = [...actions];
    updatedFields[index].label = value;
    setActions(updatedFields);
  }
  const handleDataChange = (index, value) => {
    const updatedFields = [...actions];
    updatedFields[index].data = value;
    setActions(updatedFields);
  }

  function addAction() {
    if (actions.length < 4) {
      setSelectOptions([...add_actions_options])
      setActions([...actions, { type: selectOptions[0].value, label: "", data: "" }])
    } else {
      toast(intl.formatMessage({ id: 'MAX.ALLOWED.4' }), { type: "error" })
    }
  }

  function deleteAction(index) {
    if (actions.length === 1) {
      toast(intl.formatMessage({ id: 'MIN.ALLOWED.1' }), { type: "error" })
    } else {
      const updatedFields = [...actions];
      updatedFields.splice(index, 1);
      setActions(updatedFields);
    }
  }

  const file_choosen = (event) => {
    let filePath = event.target.value;
    const file = event.target.files?.[0];
    let fileExt = filePath.substring(filePath.lastIndexOf('.') + 1, filePath.length).toLowerCase();
    if (!file) {
      return;
    }
    if (fileExt == "png" || fileExt == "jpeg") {
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = new Image();
        // image.src = e?.target?.result as string;
        image.src = event.target.files[0];
        image.onload = () => {
          if (image.width > 1024) {
            toast(intl.formatMessage({ id: 'IMG.WIDTH.ERR' }), { type: "error" });
            return;
          }
          if (file.size > 10000000) { // 10MB
            toast(intl.formatMessage({ id: 'IMG.SIZE.ERR' }), { type: "error" });
            return;
          }
          formik_buttons_type.handleChange(event);
        };
      };
      reader.readAsDataURL(file);
      setInputFile(event.target.files[0]);
    } else {
      // setInputFile(null)
      event.target.value = "";
      toast(intl.formatMessage({ id: 'IMG.ALLOWED' }), { type: "error" });
      return;
    }
  };

  function checkLabelValidation(textbox) {
    if (textbox.target.value === '') {
      textbox.target.setCustomValidity(intl.formatMessage({ id: 'LABEL.REQ' }));
    } else {
      textbox.target.setCustomValidity('');
    }
    return true;
  }

  function checkDataValidation(textbox) {
    if (textbox.target.value === '') {
      textbox.target.setCustomValidity(intl.formatMessage({ id: 'DATA.REQ' }));
    } else {
      textbox.target.setCustomValidity('');
    }
    return true;
  }

  function getActionLabel(item) {
    if (item.type == "message") {
      return <label className="form-label">{intl.formatMessage({ id: 'TEXT' })}*</label>
    } else if (item.type == "uri") {
      return <label className="form-label">{intl.formatMessage({ id: 'URI' })}*</label>
    } else {
      return <label className="form-label">{intl.formatMessage({ id: 'TEMPLATE.DATA' })}*</label>
    }
  }

  return (
    <>
      <div className='border-0' >
        <div className='col-lg-7 py-5'>
          <form onSubmit={formik_buttons_type.handleSubmit} onInvalid={() => console.log("invalid form")}>
            <div className='row my-6 '>
              <h4><label className="form-label">{intl.formatMessage({ id: 'TEMPLATE.NAME' })}*</label></h4>
              <input
                {...formik_buttons_type.getFieldProps('templateName')}
                className={clsx(
                  'form-control form-control-lg ',
                  { 'is-invalid': formik_buttons_type.touched.templateName && formik_buttons_type.errors.templateName },
                  {
                    'is-valid': formik_buttons_type.touched.templateName && !formik_buttons_type.errors.templateName,
                  }
                )}
                name='templateName'
                type="text"
                maxLength={30}
                onChange={(e) => onChangeTemplateName(e)}
                value={templateName}
              />
            </div>
            <div className='row my-6 '>
              <div className='col-lg-7 mb-10'>
                <h4><label className="form-label">{intl.formatMessage({ id: 'THUMBNAIL.IMAGE' })}</label></h4>
                <label htmlFor='input-file' className='input_file_styles' style={{ marginLeft: '40px' }}><i className="bi bi-upload " style={{ fontSize: '30px', marginTop: '10px' }}></i></label>
                <input
                  {...formik_buttons_type.getFieldProps('thumbnailImg')}
                  className={clsx(
                    'form-control form-control-lg ',
                    { 'is-invalid': formik_buttons_type.touched.thumbnailImg && formik_buttons_type.errors.thumbnailImg },
                    {
                      'is-valid': formik_buttons_type.touched.thumbnailImg && !formik_buttons_type.errors.thumbnailImg,
                    }
                  )}
                  type="file"
                  id="input-file"
                  style={{ visibility: 'hidden' }}
                  name="thumbnailImg"
                  value={undefined}
                  onChange={(e) => file_choosen(e)}
                />
              </div>
              <div className='col-lg-4' style={{ marginLeft: '30px' }}>
                {
                  inputFile ?
                    <div >
                      <div id="templateImg-display border border-dark rounded mb-6 float-right">
                        <img id="templateImg" src={URL.createObjectURL(inputFile)} style={{ width: "100px", height: '100px', borderRadius: '5px' }} />
                      </div>
                    </div>
                    :
                    <>
                      <div style={{ border: '1px solid black', width: '100px', height: '100px', borderRadius: '5px' }}>
                        <div className="text-center text-muted my-5"><h5>{intl.formatMessage({ id: 'TEMPLATE.SELECT' })}</h5></div>
                      </div>
                    </>
                }
              </div>
            </div>
            <div className='row my-6 '>
              <h4><label className="form-label">{intl.formatMessage({ id: 'IMAGE.BG' })}</label></h4>
              <input
                {...formik_buttons_type.getFieldProps('img_bg_color')}
                className={clsx(
                  'form-control form-control-lg ',
                  { 'is-invalid': formik_buttons_type.touched.img_bg_color && formik_buttons_type.errors.img_bg_color },
                  {
                    'is-valid': formik_buttons_type.touched.img_bg_color && !formik_buttons_type.errors.img_bg_color,
                  }
                )}
                name='imgBgColor'
                type="color"
                onChange={(e) => onChangeImgBgColor(e)}
                value={imgBgColor}
              />
            </div>
            <div className='row my-6 '>
              <h4><label className="form-label">{intl.formatMessage({ id: 'IMAGE.ASPECT' })}</label></h4>
              <Select
                defaultValue={img_aspect_ratio_options[0]}
                onChange={(e) => onChangeImgAspect(e)}
                options={img_aspect_ratio_options}
                className="react-select-border-light-blue text-light-blue"
                styles={{ control: (styles, state) => ({ ...styles, borderStyle: 'none !important', backgroundColor: "transparent" }) }}
              />
            </div>
            <div className='row my-6 '>
              <h4><label className="form-label">{intl.formatMessage({ id: 'IMAGE.SIZE' })}</label></h4>
              <Select
                defaultValue={img_size_options[0]}
                onChange={(e) => onChangeImgSize(e)}
                options={img_size_options}
                className="react-select-border-light-blue text-light-blue"
                styles={{ control: (styles, state) => ({ ...styles, borderStyle: 'none !important', backgroundColor: "transparent" }) }}
              />
            </div>
            <div className='row my-6 '>
              <h4><label className="form-label">{intl.formatMessage({ id: 'TITLE' })}</label></h4>
              <input
                {...formik_buttons_type.getFieldProps('title')}
                className={clsx(
                  'form-control form-control-lg ',
                  { 'is-invalid': formik_buttons_type.touched.title && formik_buttons_type.errors.title },
                  {
                    'is-valid': formik_buttons_type.touched.title && !formik_buttons_type.errors.title,
                  }
                )}
                name='title'
                type="text"
                maxLength={40}
                onChange={(e) => onChangeTitle(e)}
                value={title}
              />
            </div>
            <div className='row my-6 '>
              <h4><label className="form-label">{intl.formatMessage({ id: 'TEXT' })}*</label></h4>
              <textarea
                {...formik_buttons_type.getFieldProps('text')}
                className={clsx(
                  'form-control form-control-lg ',
                  { 'is-invalid': formik_buttons_type.touched.text && formik_buttons_type.errors.text },
                  {
                    'is-valid': formik_buttons_type.touched.text && !formik_buttons_type.errors.text,
                  }
                )}
                name='text'
                // type="text"
                maxLength={inputFile ? 60 : 160}
                onChange={(e) => onChangeText(e)}
                value={text}
              />
            </div>
            <div className='row my-6'>
              <div className='col-lg-3 py-2'>
                <h4><label className="form-label">{intl.formatMessage({ id: 'ACTIONS' })}*</label></h4>
                <button className='btn green-background text-light-blue btn-sm btn-block' onClick={() => addAction()}>

                  {intl.formatMessage({ id: 'ADD.ACTION' })}
                  <i className="fas fa-plus text-light-blue ms-2"></i>
                </button>
              </div>
            </div>
            <div className='row my-6 '>
              {actions.map((item, index) => {
                return (
                  <div key={index} className='row my-6'>
                    <div className='col-lg-4 py-2'>
                      <h4><label className="form-label">{intl.formatMessage({ id: 'TEMPLATE.TYPE' })}*</label></h4>
                      <select
                        // defaultInputValue={selectOptions[0].value}
                        value={actions[index].type}
                        onChange={(e) => handleTypeChange(index, e)}
                        // options={selectOptions}
                        className="form-control border-light-blue text-light-blue"
                      >
                        {/* <option value=""></option> */}
                        {
                          selectOptions.map((item, index1) => {
                            return <option key={index1} value={item.value}>{item.label}</option>
                          })
                        }
                      </select>
                    </div>
                    <div className='col-lg-3 py-2'>
                      <h4><label className="form-label">{intl.formatMessage({ id: 'TEMPLATE.LABEL' })}*</label></h4>
                      <input
                        {...formik_buttons_type.getFieldProps('action.label')}
                        className="form-control form-control-lg "
                        name='action.label'
                        id='action_label'
                        type="text"
                        maxLength={250}
                        onChange={(event) => handleLabelChange(index, event.target.value)}
                        value={actions[index].label}
                        required
                        onInvalid={(e) => checkLabelValidation(e)}
                        onInput={(e) => checkLabelValidation(e)}
                      />
                    </div>
                    <div className='col-lg-3 py-2'>
                      <h4>
                        {
                          getActionLabel(item)
                        }
                      </h4>
                      <input
                        {...formik_buttons_type.getFieldProps('action.data')}
                        className="form-control form-control-lg "
                        name='action.data'
                        id='action_data'
                        type={actions[index].type == "uri" ? "url" : "text"}
                        maxLength={100}
                        onChange={(event) => { handleDataChange(index, event.target.value); }}
                        value={actions[index].data}
                        required
                        onInvalid={(e) => checkDataValidation(e)}
                        onInput={(e) => checkDataValidation(e)}
                      />

                    </div>
                    <div className='col-lg-2 py-2 mt-10'>
                      <OverlayTrigger
                        delay={{ hide: 50, show: 50 }}
                        overlay={(props) => (
                          <Tooltip {...props}>
                            {intl.formatMessage({ id: 'DEL.ACTION' })}
                          </Tooltip>
                        )}
                        placement="top">
                        <button
                          type='button'
                          style={{ display: index === 0 ? 'none' : 'inline-block' }}
                          className='btn btn-icon btn-md btn-bg-light btn-color-danger me-1'
                          onClick={() => deleteAction(index)}
                        >
                          <i className='fa fa-trash'></i>
                        </button>
                      </OverlayTrigger>
                    </div>

                  </div>
                )
              })}
            </div>
            <div className='row my-4'>
              <div className='col-lg-2 py-2'>
                {!loading && <button type="submit" className='btn green-background text-light-blue btn-sm'>{intl.formatMessage({ id: 'SUBMIT' })}</button>}
                {loading && (
                  <button className='btn green-background text-light-blue btn-sm' disabled>{intl.formatMessage({ id: 'WAIT' })}</button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}


const ButtonsTemplateWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'BUTTONS' })}</PageTitle>
      <ButtonsTemplate />
    </>
  )
}

export { ButtonsTemplateWrapper }
