/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { KTCard, KTCardBody, KTSVG } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import ReactPaginate from 'react-paginate';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { toast } from 'react-toastify';
import { Tooltip, OverlayTrigger, Modal } from 'react-bootstrap'
import { get_admins, createBot, get_bots } from '../../utills/api_helper'
import { Link } from "react-router-dom";
import { useAuth } from '../../modules/auth';
import { debounce } from "lodash"
import moment from 'moment'

const lang = localStorage.getItem('i18nConfig')?.slice(17, 19);

function Items({ currentItems }) {
  const intl = useIntl()

  return (
    <>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead className='border-bottom-very-light-blue'>
            <tr className='text-start text-light-blue fw-bolder fs-7 text-uppercase gs-0'>
              <td className='text-nowrap'>{intl.formatMessage({ id: 'BOT.NAME' })}</td>
              <td className='text-nowrap'>{intl.formatMessage({ id: 'BASIC.ID' })}</td>
              <td className='text-nowrap'>{intl.formatMessage({ id: 'REFERRAL.LINK' })}</td>
              <td className='text-nowrap'>{intl.formatMessage({ id: 'STATUS' })}</td>
              <td className='text-nowrap'>{intl.formatMessage({ id: 'CREATED' })}</td>
              <td className='text-nowrap'>{intl.formatMessage({ id: 'ACTION' })}</td>
            </tr>
          </thead>
          <tbody className='text-light-blue fw-bold'>
            {currentItems.length ?
              currentItems &&
              currentItems.map((item, index) => (
                <tr key={item._id}>
                  <td className='text-nowrap'>{item.displayName}</td>
                  <td className='text-nowrap'>{item.basicId}</td>
                  <td className='text-nowrap'><a href={item.referral_link} target="_blank">{item.referral_link}</a></td>
                  <td className={item.status ? 'text-success text-nowrap' : 'text-danger text-norap'}>{item.status ? intl.formatMessage({ id: 'ACTIVE' }) : intl.formatMessage({ id: 'INACTIVE' })}</td>
                  <td className='text-nowrap'>{moment(item.created_at).format('LL')}</td>
                  <td className='text-nowrap'>
                    <OverlayTrigger
                      delay={{ hide: 50, show: 50 }}
                      overlay={(props) => (
                        <Tooltip {...props}>
                          {intl.formatMessage({ id: 'SEND.MESSAGE' })}
                        </Tooltip>
                      )}
                      placement="top">
                      <Link to={'/chat/bot/' + item._id} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 green-background" ><i className={clsx('bi fs-3', "bi-chat-square-dots text-light-blue")}></i></Link>
                    </OverlayTrigger>
                  </td>

                </tr>
              ))
              : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      {intl.formatMessage({ id: 'NO.RECORDS' })}
                    </div>
                  </td>
                </tr>
              )}
          </tbody>
        </table>
      </div>
    </>
  );
}

function PaginatedItems({ admins }) {

  useEffect(() => {
    get_botlist();
  }, [])

  const intl = useIntl()
  const { currentUser } = useAuth();
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState<string>("");
  const [admin, setAdmin] = useState<string | undefined>()
  const [createAdminList, setcreateAdminList] = useState<string | undefined>()
  const [channelSecret, setchannelSecret] = useState<string | undefined>()
  const [channelAccessToken, setchannelAccessToken] = useState<string | undefined>()
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [forcePage, setForcePage] = useState<number>(0)
  const [remountComponent, setRemountComponent] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [bots, setBots] = useState([]);
  const [createModal, setCreateModalShow] = useState(false);

  const handleClose = () => {
    setCreateModalShow(false);
    setcreateAdminList('');
    formik.resetForm();
  };
  const handleShow = () => setCreateModalShow(true);

  // Simulate fetching bots from another resources.
  // (This could be bots from props; or bots loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  const showingItemsText = intl.formatMessage({ id: "Showing results from [firstRow] to [lastRow]" }).replace("[firstRow]", "" + (itemOffset + 1)).replace("[lastRow]", "" + (endOffset <= bots.length ? endOffset : bots.length));
  const currentItems = bots.slice(itemOffset, endOffset);

  const pageCount = Math.ceil(bots.length / itemsPerPage);
  const API_URL = process.env.REACT_APP_API_URL;

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % bots.length;
    setItemOffset(newOffset);
  };

  function get_botlist(admin_id: any = null, search: string = "") {
    let filter: any = {}
    if (currentUser?.role === 'superAdmin') {
      filter = { admin_id: admin_id, displayName: search }

    } else {
      filter = { admin_id: currentUser?._id, displayName: search }
    }
    get_bots(filter).then((res) => {
      if (res.data.status == "success") {
        setBots(res.data.data);
      }
    });
  }

  function adminChange(value) {
    setAdmin(value);
    get_botlist(value)
  }

  function searchChange(text) {
    setSearch(text);
    if (text === "") {
      get_botlist(null, "");
    }
  }

  const searchQuery = useCallback(debounce(() => {
    get_botlist(admin ? admin : null, search)
  }, 500), [search]);


  // function filter() {
  //   get_botlist(admin, search);
  // }

  useEffect(() => {
    searchQuery();
    // Cancel the debounce on useEffect cleanup.
    return searchQuery.cancel;
  }, [search, searchQuery]);

  function clearFilter() {
    // setAdmin(undefined);
    setAdmin("");
    setSearch("");
    get_botlist(null, "");
  }

  function validationWithcondition() {
    let commonValidation: any = {
      channelSecret: Yup.string()
        .required(intl.formatMessage({ id: 'SECRET.KEY' })),
      channelAccessToken: Yup.string()
        .required(intl.formatMessage({ id: 'ACCESS.KEY' }))

    };
    if (currentUser?.role === 'superAdmin') {
      commonValidation.createAdminList = Yup.string()
        .required(intl.formatMessage({ id: 'EMAIL.REQUIRED' }))
    }

    return Yup.object().shape(commonValidation)
  }

  const initialValues = {
    createAdminList: '',
    channelSecret: '',
    channelAccessToken: '',
  }


  const formik = useFormik({
    initialValues,
    validationSchema: validationWithcondition,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      console.log("values ", values);
      setLoading(true)
      try {
        const res: any = await createBot({ admin_id: currentUser?.role === 'superAdmin' ? values.createAdminList : currentUser?._id, channelSecret: values.channelSecret, channelAccessToken: values.channelAccessToken, lang: lang })
        setLoading(false)
        if (res.data.status == "success") {
          setSubmitting(false)
          toast(res.data.msg,
            { type: "success" })
          resetForm();
          let close_el = document.getElementById('close_create_bot_modal') as HTMLInputElement;
          close_el.click()
          let newBots: any = bots;
          newBots.unshift(res.data.data);
          setBots(newBots)
        } else {
          toast(res.data.msg, { type: "error" })
        }
      } catch (error: any) {
        console.error(error)
        toast(intl.formatMessage({ id: 'BOT.CREATE.FAIL' }), { type: "error" })
        setSubmitting(false)
        setLoading(false)
      }
    }
  })

  return (
    <>
      <KTCard>
        <KTCardBody className='py-4'>
          <div className='border-0' >
            <div className='row'>
              <div className='col-lg-1'>
                <div className='col-lg-12 py-2'>
                  {/* setRemountComponent is used to reset selection of active pagination to 1 */}
                  <select className='form-select border-light-blue text-light-blue'
                    onChange={(e) => {
                      setItemOffset(0);
                      setItemsPerPage(parseInt(e.target.value));
                      setForcePage(0);
                      setRemountComponent(Math.random());
                    }}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                  </select>
                </div>
              </div>
              {/* begin::Card toolbar */}
              <div className='col-lg-9'>
                <div className='row'>
                  {currentUser?.role === 'superAdmin' && (
                    <>
                      <div className='col-lg-4 py-2'>
                        <select
                          className='form-select border-light-blue text-light-blue'
                          onChange={(e) => adminChange(e.target.value)}
                          value={admin}
                          style={{ fontWeight: 200 }}
                        >
                          <option value=''>{intl.formatMessage({ id: 'SELECT.ADMIN' })}</option>
                          {
                            admins.map((admin: any, index) => {
                              return (
                                <option key={index.toString()} value={admin._id}>{admin.name}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                    </>
                  )}
                  <div className='col-lg-4 py-2'>
                    <input type="text" value={search} onChange={(e) => searchChange(e.target.value)} className='form-control border-light-blue text-light-blue placeholder-light-blue' placeholder={intl.formatMessage({ id: "Serach by name" })} style={{ fontWeight: 200 }} />
                  </div>
                  {/* <div className='col-lg-2 py-2'>
                    <button
                      type='button'
                      className='btn btn-light-primary me-3'
                      onClick={filter}
                    >
                      <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                      {intl.formatMessage({ id: 'FILTER' })}
                    </button>
                  </div> */}
                  <div className='col-lg-2 py-2'>
                    <button
                      type='button'
                      className='btn border-light-blue text-light-blue fw-bold me-2 px-6'
                      data-kt-menu-dismiss='true'
                      data-kt-user-table-filter='reset'
                      onClick={clearFilter}
                    >
                      {intl.formatMessage({ id: 'RESET' })}
                    </button>
                  </div>

                </div>
              </div>
              <div className='col-lg-2 py-2'>
                {/* end::Filter Button */}
                {/* begin::Add user */}
                {/* <button type='button' className='btn btn-primary ms-4 float-end' data-bs-toggle="modal" data-bs-target="#bot_create_modal" >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              {intl.formatMessage({ id: 'ADD.BOT' })}
            </button> */}
                <button type='button' className='btn dark-green-background text-light-blue ms-4 float-end' onClick={handleShow} >
                  {intl.formatMessage({ id: 'ADD.BOT' })}
                  <i className="fas fa-plus text-light-blue ms-2"></i>
                </button>
                {/* end::Add user */}

              </div>
            </div>
            {/* end::Card toolbar */}
          </div>
        </KTCardBody>
      </KTCard>
      <KTCard className='mt-3'>
        <KTCardBody className='py-4'>
          <Items currentItems={currentItems} />
          <div className='row' key={remountComponent}>

            <div className='col-lg-6 text-light-blue d-flex align-items-center'>{showingItemsText}</div>
            <ReactPaginate
              nextLabel={intl.formatMessage({ id: "next >" })}
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel={intl.formatMessage({ id: "< previous" })}
              pageClassName="page-item"
              pageLinkClassName="page-link text-light-blue"
              previousClassName="page-item"
              previousLinkClassName="page-link text-light-blue"
              nextClassName="page-item"
              nextLinkClassName="page-link text-light-blue"
              breakLabel="..."
              breakClassName="page-item text-light-blue"
              breakLinkClassName="page-link text-light-blue"
              containerClassName="col-lg-6 pagination justify-content-end"
              activeClassName="green-background rounded"
              renderOnZeroPageCount={() => null}
              forcePage={forcePage}
            />
          </div>
        </KTCardBody>
      </KTCard>
      {/* Add bot Modal start*/}
      <Modal
        show={createModal}
        onHide={handleClose}
        size="lg">
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
          <Modal.Header >
            <Modal.Title className='text-light-blue'>{intl.formatMessage({ id: 'ADD.BOT' })}</Modal.Title>
          </Modal.Header>
          <Modal.Body className='text-light-blue'>
            <p>{intl.formatMessage({ id: 'NOTE' })}:<br />

              1. {intl.formatMessage({ id: 'ADD.THIS' })} <b>{process.env.REACT_APP_API_URL}/webhook</b>{intl.formatMessage({ id: 'ADD.DESC' })}<br />
              2. {intl.formatMessage({ id: 'URL.DESC' })} <b>{intl.formatMessage({ id: 'USE.WEBHOOK' })}</b>
            </p>
            <div>
              <div className='row'>
                {currentUser?.role === 'superAdmin' ? (
                  <div className='col-md-6'>
                    <label className="form-label text-light-blue">{intl.formatMessage({ id: 'ADMIN.SEARCH' })}*</label>
                    <select
                      {...formik.getFieldProps('createAdminList')}
                      className={clsx(
                        'form-select form-control-lg form-select-solid',
                        { 'is-invalid': formik.touched.createAdminList && formik.errors.createAdminList },
                        {
                          'is-valid': formik.touched.createAdminList && !formik.errors.createAdminList,
                        }
                      )}
                      name="createAdminList"
                      onChange={(e) => {
                        formik.setFieldValue("createAdminList", e.target.value);
                        setcreateAdminList(e.target.value)
                      }}
                      value={createAdminList}
                      style={{ fontWeight: 200 }}
                    >
                      <option value=''>{intl.formatMessage({ id: 'SELECT.ADMIN' })}</option>

                      <>
                        {
                          admins.map((admin: any, index) => {
                            return (
                              <option key={index.toString()} value={admin._id}>{admin.email}</option>
                            )
                          })
                        }
                      </>


                    </select>
                    {formik.touched.createAdminList && formik.errors.createAdminList && (
                      <div className='fv-plugins-message-container'>
                        <span className="text-danger">{formik.errors.createAdminList}</span>
                      </div>
                    )}
                  </div>
                ) : (
                  null
                )}
                <div className='col-md-6'>
                  <label className="form-label text-light-blue">{intl.formatMessage({ id: 'KEY.SECRET' })}*</label>
                  <input
                    {...formik.getFieldProps('channelSecret')}
                    className={clsx(
                      'form-control form-control-lg ',
                      { 'is-invalid': formik.touched.channelSecret && formik.errors.channelSecret },
                      {
                        'is-valid': formik.touched.channelSecret && !formik.errors.channelSecret,
                      }
                    )}
                    value={channelSecret}
                    name='channelSecret'
                    type="text"
                  />
                  {formik.touched.channelSecret && formik.errors.channelSecret && (
                    <div className='fv-plugins-message-container'>
                      <span className="text-danger">{formik.errors.channelSecret}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mt-2'>
              <div className='col-md-12'>
                <label className="form-label text-light-blue">{intl.formatMessage({ id: 'KEY.ACCESS' })}*</label>
                <textarea
                  {...formik.getFieldProps('channelAccessToken')}
                  className={clsx(
                    'form-control form-control-lg ',
                    { 'is-invalid': formik.touched.channelAccessToken && formik.errors.channelAccessToken },
                    {
                      'is-valid': formik.touched.channelAccessToken && !formik.errors.channelAccessToken,
                    }
                  )}
                  value={channelAccessToken}
                  name='channelAccessToken'
                ></textarea>
                {formik.touched.channelAccessToken && formik.errors.channelAccessToken && (
                  <div className='fv-plugins-message-container'>
                    <span className="text-danger">{formik.errors.channelAccessToken}</span>
                  </div>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn text-light-blue"
              data-bs-dismiss="modal"
              id="close_create_bot_modal"
              onClick={handleClose}
            >
              {intl.formatMessage({ id: 'CLOSE' })}
            </button>
            <button type="submit" className="btn btn-primary dark-green-background text-light-blue">
              {!loading && <span className='indicator-label'>{intl.formatMessage({ id: 'CREATE.BOT' })}</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  {intl.formatMessage({ id: 'WAIT' })}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      {/* Add bot Modal end*/}

    </>
  );
}


const BotList: FC = () => {
  const [admins, setAdmins] = useState([]);
  const { currentUser } = useAuth();

  useEffect(() => {
    moment.locale(lang)
    if (currentUser?.role === 'superAdmin') {
      get_admins({}).then((res) => {
        if (res.data.status == "success") {
          setAdmins(res.data.data)
        }
      });
    } else {
      setAdmins([])
    }

  }, [])

  return (
    <PaginatedItems admins={admins} />
  )
}

const BotsWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.BOTS' })}</PageTitle>
      <BotList />
    </>
  )
}

export { BotsWrapper }
