/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { KTCard, KTCardBody, KTSVG } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import ReactPaginate from 'react-paginate';
import { Tooltip, OverlayTrigger, Modal, Button } from 'react-bootstrap'
import { delete_invoice_generate, get_bots, get_invoice_generate } from '../../utills/api_helper'
import { Link } from "react-router-dom";
import moment from 'moment';
import { debounce } from "lodash"
import { toast } from 'react-toastify';


const lang = localStorage.getItem('i18nConfig')?.slice(17, 19);




function Items({ currentItems, itemOffset, deleteInvoiceGenerateCB }) {
  const intl = useIntl()

  const [viewModal, setViewModalShow] = useState<boolean>(false);


  const handleCloseView = () => {
    setViewModalShow(false);
  };
  const handleShowView = (richMenu) => {
    setViewModalShow(true);
  }

  function deleteInvoiceGenerate(invoiceGenerate) {
    deleteInvoiceGenerateCB(invoiceGenerate)
  }


  return (
    <>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead className='border-bottom-very-light-blue'>
            <tr className='text-start text-light-blue fw-bolder fs-7 text-uppercase gs-0'>
              <td className='text-nowrap'>#</td>
              <td className='text-nowrap'>{intl.formatMessage({ id: 'Name' })}</td>
              <td className='text-nowrap'>{intl.formatMessage({ id: 'Amount' })}</td>
              <td className='text-nowrap'>{intl.formatMessage({ id: 'Message' })}</td>
              {/* <td className='text-nowrap'>{intl.formatMessage({ id: 'BOT.NAME' })}</td> */}
              <td>{intl.formatMessage({ id: 'Payment Link' })}</td>
              {/* <td className='text-nowrap'>{intl.formatMessage({ id: 'Current Status' })}</td> */}
              <td className='text-nowrap'>{intl.formatMessage({ id: 'Invoice generated date' })}</td>
              <td className='text-nowrap'>{intl.formatMessage({ id: 'Payment Due Date' })}</td>
              {/* <td className='text-nowrap' style={{ textAlign: "right" }}>{intl.formatMessage({ id: 'ACTION' })}</td> */}
            </tr>
          </thead>
          <tbody className='text-light-blue fw-bold'>
            {currentItems.length ?
              currentItems &&
              currentItems.map((item, index) => (
                <tr key={item._id}>
                  <td>{itemOffset + index + 1}</td>
                  <td>{item?.name}</td>
                  <td>{item?.amount}</td>
                  <td>{item?.message}</td>
                  {/* <td>{item?.bot_id?.displayName}</td> */}
                  <td >{item?.payment_link}</td>
                  <td className='text-nowrap'>{moment(item.created_at).format('lll')}</td>
                  <td className='text-nowrap'>{moment(item.payment_due_date).format('lll')}</td>
                  {/* <td className='text-nowrap' style={{ textAlign: "right" }}>
                    <Link to={"/edit-generated-invoices/" + item._id}>
                      <OverlayTrigger
                        delay={{ hide: 50, show: 50 }}
                        overlay={(props) => (
                          <Tooltip {...props}>
                            {intl.formatMessage({ id: 'EDIT.USER' })}
                          </Tooltip>
                        )}
                        placement="top">
                        <button className='btn btn-icon btn-sm m-1 green-background' onClick={() => { }}><i className="bi bi-pencil-square text-light-blue"></i></button>
                      </OverlayTrigger>
                    </Link>
                    <OverlayTrigger
                      delay={{ hide: 50, show: 50 }}
                      overlay={(props) => (
                        <Tooltip {...props}>
                          {intl.formatMessage({ id: 'Delete' })}
                        </Tooltip>
                      )}
                      placement="top">
                      <button
                        className="btn btn-icon btn-sm m-1 green-background" onClick={() => deleteInvoiceGenerate(item)}><i className="fas fa-trash text-light-blue"></i>
                      </button>
                    </OverlayTrigger>
                  </td> */}


                </tr>
              ))
              : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      {intl.formatMessage({ id: 'NO.RECORDS' })}
                    </div>
                  </td>
                </tr>
              )}
          </tbody>
        </table>
        {/* View rich_menu Modal start*/}
        <Modal
          show={viewModal}
          onHide={handleCloseView}
          size="lg">

          <Modal.Header className='p-5'>
            <Modal.Title className=' w-100'>
              <div className='row w-100'>

                <div className='col-6 d-flex align-items-center'>
                  {intl.formatMessage({ id: 'Rich menu Details' })}
                </div>
                <div className='col-6 text-end p-0'>
                  <OverlayTrigger
                    delay={{ hide: 50, show: 50 }}
                    overlay={(props) => (
                      <Tooltip {...props}>
                        {intl.formatMessage({ id: 'CLOSE' })}
                      </Tooltip>
                    )}
                    placement="top">
                    <button className="btn btn-icon  btn-active-color-primary btn-sm me-1 green-background" onClick={() => handleCloseView()}><i className="fas fa-times text-light-blue"></i></button>
                  </OverlayTrigger>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

          </Modal.Body>

        </Modal>

        {/* Add rich_menu Modal end*/}

      </div>
    </>
  );
}

function PaginatedItems({ bots }) {



  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState<string>("");
  const [bot, setBot] = useState<string | undefined>("")
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [forcePage, setForcePage] = useState<number>(0)
  const [remountComponent, setRemountComponent] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [invoiceGenerates, setInvoiceGenerates] = useState([]);
  const [updateInvoiceGenerate, setUpdateInvoiceGenerate] = useState<any>();
  const [deleteConfirmModalShow, setDeleteConfirmModalShow] = useState(false);


  // Simulate fetching rich_menus from another resources.
  // (This could be rich_menus from props; or rich_menus loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  const showingItemsText = intl.formatMessage({ id: "Showing results from [firstRow] to [lastRow] of [totalLength]" })
    .replace("[firstRow]", "" + (itemOffset + 1))
    .replace("[lastRow]", "" + (endOffset <= invoiceGenerates.length ? endOffset : invoiceGenerates.length))
    .replace("[totalLength]", "" + invoiceGenerates.length);
  const currentItems = invoiceGenerates.slice(itemOffset, endOffset);

  const pageCount = Math.ceil(invoiceGenerates.length / itemsPerPage);


  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % invoiceGenerates.length;
    setItemOffset(newOffset);
  };

  function get_invoiceGenerates_list(bot_id: any = null, search: string = "") {
    let filter = { bot_id: bot_id, name: search }
    get_invoice_generate(filter).then((res) => {
      if (res.data.status == "success") {
        setInvoiceGenerates(res.data.data);
      }
    });
  }

  function botChange(value) {
    setBot(value);
    get_invoiceGenerates_list(value, search);
  }

  function searchChange(text) {
    setSearch(text);
  }

  const searchQuery = useCallback(debounce(() => {
    get_invoiceGenerates_list(bot, search);
  }, 500), [search]);


  useEffect(() => {
    searchQuery();
    // Cancel the debounce on useEffect cleanup.
    return searchQuery.cancel;
  }, [search, searchQuery]);

  function filter() {
    get_invoiceGenerates_list(bot, search);
  }
  function clearFilter() {
    setBot("");
    setSearch("");
    get_invoiceGenerates_list(null, "");
  }


  function deleteInvoiceGenerate(invoiceGenerate) {
    setUpdateInvoiceGenerate(invoiceGenerate);
    setDeleteConfirmModalShow(true)
  }

  function confirmDeleteRichMenu() {
    let filter = { _id: updateInvoiceGenerate?._id, lang: lang }
    delete_invoice_generate(filter).then((res) => {
      if (res.data.status == "success") {
        let localInvoiceGenerates = invoiceGenerates;
        localInvoiceGenerates.map((item: any, index) => {
          if (item._id == updateInvoiceGenerate?._id) {
            localInvoiceGenerates.splice(index, 1);
          }
        });
        setInvoiceGenerates(localInvoiceGenerates);
        toast(res.data.msg, { type: "success" })
        setDeleteConfirmModalShow(false)
      } else {
        toast(res.data.msg, { type: "error" })
      }
    });
  }

  return (
    <>
      <KTCard>
        <KTCardBody className='py-4'>
          <div className='border-0' >
            <div className='row'>
              <div className='col-lg-1'>
                <div className='col-lg-12 py-2'>
                  {/* setRemountComponent is used to reset selection of active pagination to 1 */}
                  <select className='form-select border-light-blue text-light-blue'
                    onChange={(e) => {
                      setItemOffset(0);
                      setItemsPerPage(parseInt(e.target.value));
                      setForcePage(0);
                      setRemountComponent(Math.random());
                    }}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                  </select>
                </div>
              </div>
              {/* begin::Card toolbar */}
              <div className='col-lg-9'>
                <div className='row'>
                  <div className='col-lg-4 py-2'>

                    <select
                      className='form-select border-light-blue text-light-blue'
                      onChange={(e) => botChange(e.target.value)}
                      value={bot}
                      style={{ fontWeight: 200 }}
                    >
                      <option value=''>{intl.formatMessage({ id: 'SELECT.BOT' })}</option>
                      {
                        bots.map((bot: any, index) => {
                          return (
                            <option key={index.toString()} value={bot._id}>{bot.displayName}</option>
                          )
                        })
                      }
                    </select>
                  </div>
                  <div className='col-lg-4 py-2'>
                    <input type="text" onChange={(e) => searchChange(e.target.value)} value={search} className='form-control border-light-blue text-light-blue placeholder-light-blue' placeholder={intl.formatMessage({ id: "Serach by name" })} style={{ fontWeight: 200 }} />
                  </div>
                  {/* <div className='col-lg-2 py-2'>
                <button
                  type='button'
                  className='btn btn-light-primary me-3'
                  onClick={filter}
                >
                  <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                  {intl.formatMessage({ id: 'FILTER' })}
                </button>
              </div> */}
                  <div className='col-lg-2 py-2'>
                    <button
                      type='button'
                      className='btn fw-bold me-2 px-6 border-light-blue text-light-blue'
                      data-kt-menu-dismiss='true'
                      data-kt-user-table-filter='reset'
                      onClick={clearFilter}
                    >
                      {intl.formatMessage({ id: 'RESET' })}
                    </button>
                  </div>
                </div>
              </div>
              <div className='col-lg-2 py-2'>
                {/* end::Filter Button */}
                {/* begin::Add user */}
                {/* <button type='button' className='btn btn-primary ms-4 float-end' data-bs-toggle="modal" data-bs-target="#rich_menu_create_modal" >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              {intl.formatMessage({ id: 'ADD.BOT' })}
            </button> */}
                <Link to={"/generate-invoice"} type='button' className='btn dark-green-background text-light-blue ms-4 float-end' >
                  {intl.formatMessage({ id: 'Generate' })}
                  <i className="fas fa-plus text-light-blue ms-2"></i>
                </Link>
                {/* end::Add user */}

              </div>
            </div>
            {/* end::Card toolbar */}
          </div>
        </KTCardBody>
      </KTCard>
      <KTCard className='mt-3'>
        <KTCardBody className='py-4'>
          <Items currentItems={currentItems} itemOffset={itemOffset} deleteInvoiceGenerateCB={deleteInvoiceGenerate}
          />
          <div className='row' key={remountComponent}>

            <div className='col-lg-6 d-flex align-items-center text-light-blue fw-bold'>{showingItemsText}</div>
            <ReactPaginate
              nextLabel={intl.formatMessage({ id: "next >" })}
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel={intl.formatMessage({ id: "< previous" })}
              pageClassName="page-item"
              pageLinkClassName="page-link text-light-blue"
              previousClassName="page-item"
              previousLinkClassName="page-link text-light-blue"
              nextClassName="page-item"
              nextLinkClassName="page-link text-light-blue"
              breakLabel="..."
              breakClassName="page-item text-light-blue"
              breakLinkClassName="page-link text-light-blue"
              containerClassName="col-lg-6 pagination justify-content-end"
              activeClassName="green-background rounded"
              renderOnZeroPageCount={() => null}
              forcePage={forcePage}
            />
          </div>
        </KTCardBody>
      </KTCard>
      {/* delete confirm modal start */}
      <Modal
        show={deleteConfirmModalShow}
        onHide={() => { setDeleteConfirmModalShow(false) }}
      >

        <Modal.Header className='p-5'>
          <Modal.Title className=' w-100'>
            <div className='row w-100'>

              <div className='col-6 d-flex align-items-center'>
                {intl.formatMessage({ id: 'Delete Invoice' })}
              </div>
              <div className='col-6 text-end p-0'>
                <OverlayTrigger
                  delay={{ hide: 50, show: 50 }}
                  overlay={(props) => (
                    <Tooltip {...props}>
                      {intl.formatMessage({ id: 'CLOSE' })}
                    </Tooltip>
                  )}
                  placement="top">
                  <button className="btn btn-icon  btn-active-color-primary btn-sm me-1 green-background" onClick={() => setDeleteConfirmModalShow(false)}><i className="fas fa-times text-light-blue"></i></button>
                </OverlayTrigger>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>{intl.formatMessage({ id: 'Are you sure you want to delete "[invoiceGenerateName]" invoiceGenerate?' })
            .replace("[invoiceGenerateName]", "" + updateInvoiceGenerate?.invoiceGenerate_name)}</h6>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light"
            data-bs-dismiss="modal"
            onClick={() => setDeleteConfirmModalShow(false)}
          >
            {intl.formatMessage({ id: 'CLOSE' })}
          </button>
          <button className="btn green-background text-light-blue" onClick={confirmDeleteRichMenu} >
            <span className='indicator-label'>{intl.formatMessage({ id: 'Delete' })}</span>
          </button>
        </Modal.Footer>
      </Modal>
      {/* delete confirm modal end */}

    </>
  );
}


const InvoiceGenerateList: FC = () => {
  const [bots, setBots] = useState([]);

  useEffect(() => {
    moment.locale(lang)
    let filter = { bot_id: null, search: "" }
    get_bots(filter).then((res) => {
      if (res.data.status == "success") {
        setBots(res.data.data)
      }
    });
  }, [])

  return (
    <>
      <PaginatedItems bots={bots} />
    </>
  )
}

const InvoiceGeneratesWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'INVOICES' })}</PageTitle>
      <InvoiceGenerateList />
    </>
  )
}

export { InvoiceGeneratesWrapper }
