/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { useAuth } from '../../../../app/modules/auth'
import { checkAccess } from './../../../../app/utills/helper_functions'


export function AsideMenuMain() {
  const intl = useIntl();
  const { currentUser } = useAuth();

  return (
    <>
      {/* SUPERADMIN ROUTES */}
      {
        checkAccess(currentUser, "", true) && (
          <>
            {/* <AsideMenuItem
              to='/dashboard'
              // icon='fas fa-envelope-open-tex'
              title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
              fontIcon='bi-graph-up-arrow'
            /> */}
            <AsideMenuItem
              to='/admins'
              // icon='fas fa-envelope-open-tex'
              title={intl.formatMessage({ id: 'MENU.ADMINS' })}
              fontIcon='bi-person-check-fill'
            />
          </>
        )}


      {/* ADMIN ROUTES */}

      {/* {
        checkAccess(currentUser, "bots", true) ? */}
          <AsideMenuItem
            to='/bots'
            // icon='fas fa-envelope-open-tex'
            title={intl.formatMessage({ id: 'MENU.BOTS' })}
            fontIcon='bi-robot'
          />
          {/* :
          null
      } */}
      {
        currentUser?.role !== 'superAdmin' ?
          <AsideMenuItem
            to='/groups'
            // icon='fas fa-envelope-open-tex'
            title={intl.formatMessage({ id: 'MENU.GROUPS' })}
            fontIcon='bi-person-plus-fill'
          />
          :
          null
      }
      {/* {
        checkAccess(currentUser, "users", true) ? */}
          <AsideMenuItem
            to='/users'
            // icon='fas fa-envelope-open-tex'
            title={intl.formatMessage({ id: 'MENU.USERS' })}
            fontIcon='bi-people'
          />
          {/* :
          null
      } */}
      {/* {
        checkAccess(currentUser, "richmenus", true) ? */}
          <AsideMenuItem
            to='/richmenus'
            // icon='fas fa-envelope-open-tex'
            title={intl.formatMessage({ id: 'MENU.RICHMENUS' })}
            fontIcon='bi-menu-up'
          />
          {/* :
          null
      } */}
      {/* {
        checkAccess(currentUser, "message_templates", false) ? */}
          <AsideMenuItem
            to='/message-templates'
            // icon='fas fa-envelope-open-tex'
            title={intl.formatMessage({ id: 'MENU.MESSAGE_TEMPLATES' })}
            fontIcon='bi-chat-text-fill'
          />
          {/* :
          null
      } */}
      {
        checkAccess(currentUser, "scenario", false) ?
          <AsideMenuItem
            to='/scenarios'
            // icon='fas fa-envelope-open-tex'
            title={intl.formatMessage({ id: 'MENU.SCENARIOS' })}
            fontIcon='bi-send-check'
          />
          :
          null
      }
      {/* {
        checkAccess(currentUser, "scenario", false) ? */}
          <AsideMenuItem
            to='/generated-invoice'
            // icon='fas fa-envelope-open-tex'
            title={intl.formatMessage({ id: 'INVOICES' })}
            fontIcon='bi-receipt'
          />
          {/* :
          null
      } */}
      {/* <AsideMenuItem
        to='/surveys'
        // icon='fas fa-envelope-open-tex'
        title={intl.formatMessage({ id: 'MENU.SURVEYS' })}
        fontIcon='bi-megaphone-fill'
      /> */}

      {/* SUPERADMIN ROUTES */}

      {checkAccess(currentUser, "", true) && (
        <>
          <AsideMenuItemWithSub
            to='/settings'
            // icon='fas fa-envelope-open-tex'
            title={intl.formatMessage({ id: 'MENU.SETTINGS' })}
            fontIcon='bi-sliders'
          >
            <AsideMenuItem
              to='/settings/mail-configurations'
              // icon='fas fa-envelope-open-tex'
              title={intl.formatMessage({ id: 'MENU.MAIL_CONFIGRATIONS' })}
              fontIcon='bi-envelope-check-fill'
            />
          </AsideMenuItemWithSub>
          <AsideMenuItem
            to='/view/contacts'
            // icon='fas fa-envelope-open-tex'
            title={intl.formatMessage({ id: 'MENU.CONTACTSLIST' })}
            fontIcon='bi bi-person-lines-fill'
          />
          <AsideMenuItem
            to='/features'
            // icon='fas fa-envelope-open-tex'
            title={intl.formatMessage({ id: 'MENU.FEATURES' })}
            fontIcon='bi bi-diagram-3'
          />
        </>
      )}
      {/* <AsideMenuItem
        to='/reservations'
        // icon='fas fa-envelope-open-tex'
        title={intl.formatMessage({ id: 'MENU.RESERVATIONS' })}
        fontIcon='bi-calendar2-check-fill'
      /> */}
    </>
  )
}
