/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import Select from 'react-select';
import { PageTitle } from '../../../_metronic/layout/core'
import { KTCard, KTCardBody, KTSVG } from '../../../_metronic/helpers'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { create_template, update_template } from '../../utills/api_helper'
import { toast } from 'react-toastify';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';

// const lang = localStorage.getItem('i18nConfig')?.slice(17, 19);


const ReferralTemplate = (props) => {

  return (
    <>
      <KTCard>
        <KTCardBody className='p-2'>
          <PaginatedItems templateDetails={props.templateDetails} />
        </KTCardBody>
      </KTCard>
    </>
  )
}

function PaginatedItems(props) {
  const intl = useIntl();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [text, setText] = useState("");
  const [inputFile, setInputFile] = useState<any>(null);
  const [templateType, setTemplateType] = useState("uri");
  const [columns, setColumns] = useState<any | undefined>([{ imageUrl: null, action: { type: "uri", uri: "" } }])

  useEffect(() => {
    if (props.templateDetails) {
      setTemplateName(props.templateDetails.template.template_name);
      formik_referral_type.setFieldValue("templateName", props.templateDetails.template.template_name)
      setText(props.templateDetails.template.text);
      formik_referral_type.setFieldValue("text", props.templateDetails.template.template_name)
      setColumns(props.templateDetails.template.columns);
    }
  }, [])

  const initialValues = {
    templateName: '',
    text: '',
  }

  const createButtonSchema = Yup.object().shape({
    templateName: Yup.string()
      .required(intl.formatMessage({ id: 'TEMPLATE.REQ' })),
    text: Yup.string()
      .required(intl.formatMessage({ id: 'NAME.REQUIRED' }))
  })

  const formik_referral_type = useFormik({
    initialValues,
    validationSchema: createButtonSchema,
    onSubmit: async (values, { resetForm, setStatus, setSubmitting }) => {
      let template = JSON.stringify({
        template_name: values.templateName,
        type: "referral_template",
        text: values.text,
        columns: columns,
      })
      let form_data = new FormData();

      form_data.append('type', "template" + "");
      form_data.append('altText', "This is referral template" + "");
      // form_data.append('lang', lang + "");
      form_data.append('imageUrl0', inputFile);
      form_data.append('template', template + "");
      setLoading(true);
      let res: any;
      try {
        if (props.templateDetails) {
          form_data.append('_id', props.templateDetails._id);
          res = await update_template(form_data, true)
        } else {
          res = await create_template(form_data, true)
        }
        if (res.data.status == "success") {
          setSubmitting(false)
          setLoading(false)
          toast(res.data.msg, { type: "success" })
          resetForm()
          navigate("/message-templates")
          // setTimeout(() => {
          //   window.location.href = "/message-templates";
          // }, 2000);
        } else {
          setSubmitting(false)
          setLoading(false)
          toast(res.data.msg, { type: "error" })
          // resetForm()
        }
      } catch (error: any) {
        console.error(error)
        toast(intl.formatMessage({ id: 'TEMPLATE.CREATE.FAIL' }), { type: "error" })
        setSubmitting(false)
        setLoading(false)
        // resetForm()
      }
    }
  })

  const onChangeTemplateName = (event) => {
    setTemplateName(event.target.value)
    formik_referral_type.setFieldValue("templateName", event.target.value)
  }

  const onChangeText = (e) => {
    setText(e.target.value)
    formik_referral_type.setFieldValue("text", e.target.value)
  }


  const handleUriChange = (event) => {
    const updatedFields = [...columns];
    updatedFields[0].action.uri = event.target.value;
    setColumns(updatedFields);
  }

  const file_choosen = (event) => {
    let filePath = event.target.value;
    const file = event.target.files[0];
    let fileExt = filePath.substring(filePath.lastIndexOf('.') + 1, filePath.length).toLowerCase();
    if (!file) {
      return;
    }
    if (fileExt == "png" || fileExt == "jpeg" || fileExt == "jpg") {
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = new Image();
        image.src = event.target.files[0];
        image.onload = () => {
          if (image.width > 1024) {
            toast(intl.formatMessage({ id: 'IMG.WIDTH.ERR' }), { type: "error" });
            return;
          }
          if (file.size > 10000000) { // 10MB
            toast(intl.formatMessage({ id: 'IMG.SIZE.ERR' }), { type: "error" });
            return;
          }
          formik_referral_type.handleChange(event);
        };
      };
      reader.readAsDataURL(file);
      setInputFile(event.target.files[0]);
      const updatedFields = [...columns];
      updatedFields[0].imageUrl = event.target.value;
      setColumns(updatedFields);
    } else {
      // setInputFile(null)
      event.target.value = "";
      toast(intl.formatMessage({ id: 'IMG.ALLOWED' }), { type: "error" });
      return;
    }
  };

  function checkLabelValidation(textbox) {
    if (textbox.target.value === '') {
      textbox.target.setCustomValidity(intl.formatMessage({ id: 'LABEL.REQ' }));
    } else {
      textbox.target.setCustomValidity('');
    }
    return true;
  }

  function checkDataValidation(textbox) {
    if (textbox.target.value === '') {
      textbox.target.setCustomValidity(intl.formatMessage({ id: 'DATA.REQ' }));
    } else {
      textbox.target.setCustomValidity('');
    }
    return true;
  }


  return (
    <>
      <div className='border-0' >
        <div className='col-lg-7 py-5'>
          <form onSubmit={formik_referral_type.handleSubmit}>
            <div className='row my-6 '>
              <h4><label className="form-label">{intl.formatMessage({ id: 'TEMPLATE.NAME' })}*</label></h4>
              <input
                {...formik_referral_type.getFieldProps('templateName')}
                className={clsx(
                  'form-control form-control-lg '
                )}
                name='templateName'
                type="text"
                maxLength={30}
                onChange={(e) => onChangeTemplateName(e)}
                value={templateName}
                readOnly={props.templateDetails ? true : false}
              />
            </div>
            <div className='row my-6 '>
              <div className='col-lg-7 mb-10'>
                <h4><label className="form-label">{intl.formatMessage({ id: 'THUMBNAIL.IMAGE' })}</label></h4>
                <label htmlFor='input-file' className='input_file_styles' style={{ marginLeft: '40px' }}><i className="bi bi-upload " style={{ fontSize: '30px', marginTop: '10px' }}></i></label>
                <input
                  className="form-control form-control-lg "
                  type="file"
                  id="input-file"
                  name="thumbnailImg"
                  style={{ visibility: 'hidden' }}
                  value={undefined}
                  onChange={(e) => file_choosen(e)}
                />
              </div>
              <div className='col-lg-4' style={{ marginLeft: '30px' }}>
                {
                  inputFile ?
                    <div >
                      <div id="templateImg-display border border-dark rounded mb-6 float-right">
                        <img id="templateImg" src={URL.createObjectURL(inputFile)} style={{ width: "100px", height: '100px', borderRadius: '5px' }} />
                      </div>
                    </div>
                    :
                    props?.templateDetails?.template?.columns[0]?.imageUrl ?
                      <div id="templateImg-display border border-dark rounded mb-6 float-right">
                        <img src={props.templateDetails.template?.columns[0]?.imageUrl} style={{ width: "100px", height: '100px', borderRadius: '5px' }} />
                      </div>
                      :
                      <>
                        <div className='border-light-blue' style={{ width: '100px', height: '100px', borderRadius: '5px' }}>
                          <div className="text-center text-light-blue my-5"><h5>{intl.formatMessage({ id: 'TEMPLATE.SELECT' })}</h5></div>
                        </div>
                      </>
                }
              </div>
            </div>
            <div className='row my-6 '>
              <h4><label className="form-label">{intl.formatMessage({ id: 'TEXT' })}*</label></h4>
              <textarea
                {...formik_referral_type.getFieldProps('text')}
                className={clsx(
                  'form-control form-control-lg '
                )}
                name='text'
                // type="text"
                maxLength={240}
                onChange={(e) => onChangeText(e)}
                value={text}
                readOnly={props.templateDetails ? true : false}
              />
            </div>
            <div className='row my-6'>
              <div className='col-lg-2 py-2'>
                <h4><label className="form-label">{intl.formatMessage({ id: 'COLUMNS' })}*</label></h4>
              </div>
            </div>
            <div className='row my-6 '>
              <div className='col-lg-4 py-2'>
                <h4><label className="form-label">{intl.formatMessage({ id: 'TEMPLATE.TYPE' })}*</label></h4>
                <input
                  className="form-control form-control-lg "
                  name='columns.label'
                  type="text"
                  defaultValue={templateType}
                  disabled
                />
              </div>
              <div className='col-lg-3 py-2'>
                <h4><label className="form-label">{intl.formatMessage({ id: 'URI' })}*</label></h4>
                <input
                  className="form-control form-control-lg "
                  name='columns.uri'
                  type="url"
                  maxLength={250}
                  onChange={(event) => handleUriChange(event)}
                  value={columns[0].action.uri}
                  required
                  onInvalid={(e) => checkLabelValidation(e)}
                  onInput={(e) => checkLabelValidation(e)}
                />
              </div>
            </div>
            <div className='row my-4'>
              <div className='col-lg-3 py-2'>
                {!loading && <button type="submit" className='btn green-background text-light-blue btn-sm btn-block'>{props.templateDetails ? intl.formatMessage({ id: 'UPDATE' }) : intl.formatMessage({ id: 'SUBMIT' })}</button>}
                {loading && (
                  <button className='btn green-background text-light-blue btn-sm btn-block' disabled>{intl.formatMessage({ id: 'WAIT' })}</button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}


// const ReferralTemplateWrapper = (props) => {
//   const intl = useIntl()
//   return (
//     <>
//       <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'REFERRAL'})}</PageTitle>
//       <ReferralTemplate />
//     </>
//   )
// }

export { ReferralTemplate }