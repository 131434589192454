/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { toast } from 'react-toastify';
import { reset_password } from '../../utills/api_helper'
import { KTCard, KTCardBody } from '../../../_metronic/helpers'

const lang = localStorage.getItem('i18nConfig')?.slice(17, 19);


const ResetPassword: FC = () => {
  const intl = useIntl();
  const [loading, setLoading] = useState(false);

  const resetPwdSchema = Yup.object().shape({
    current_password: Yup.string()
      .required(intl.formatMessage({ id: 'CURR_PWD.REQUIRED' })),
    new_password: Yup
      .string()
      .min(8, intl.formatMessage({ id: 'MIN_LENGTH.PWD' }))
      .matches(/[0-9]/, intl.formatMessage({ id: 'NUM_REQ_PWD' }))
      .matches(/[a-z]/, intl.formatMessage({ id: 'LOWER_CASE_REQ.PWD' }))
      .matches(/[A-Z]/, intl.formatMessage({ id: 'UPPER_CASE_REQ.PWD' }))
      .matches(/[^\w]/, intl.formatMessage({ id: 'SPECIAL_CHAR_REQ.PWD' }))
      .required(intl.formatMessage({ id: 'NEW_PWD.REQUIRED' })),
    confirm_password: Yup
      .string()
      .oneOf([Yup.ref('new_password'), null], intl.formatMessage({ id: 'PWD.MATCH' })) //pass is field name
      .required(intl.formatMessage({ id: 'CONFIRM_PWD.REQUIRED' })),
  })

  const initialValues = {
    current_password: '',
    new_password: '',
    confirm_password: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: resetPwdSchema,
    onSubmit: async (values, { resetForm, setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const res: any = await reset_password({ oldPassword: values.current_password, newPassword: values.new_password, lang: lang })
        if (res.data.status == "success") {
          toast(res.data.msg, { type: "success" })
          resetForm()
          setSubmitting(false)
          setLoading(false)
        } else {
          toast(res.data.msg, { type: "error" })
          setSubmitting(false)
          setLoading(false)
        }
      } catch (error: any) {
        console.error(error)
        // console.error(error.response)
        toast(intl.formatMessage({ id: 'LOGIN.FAIL' }), { type: "error" })
        setSubmitting(false)
        setLoading(false)
      }
    },
  })


  return (
    <>
      <KTCard>
        <KTCardBody className='py-4'>
          <div className='row'>
          <form
            className='form col-lg-6'
            onSubmit={formik.handleSubmit}
            noValidate
            id='kt_reset_password_form'
          >
            {/* begin::Heading */}
            <div className='fv-row mb-10'>
              <label className='form-label fs-6 fw-bolder text-light-blue'>{intl.formatMessage({ id: "CURRENT.PWD" })}</label>
              <input
                placeholder={intl.formatMessage({ id: "CURRENT.PWD" })}
                {...formik.getFieldProps('current_password')}
                className={clsx(
                  'form-control form-control-lg ',
                  { 'is-invalid': formik.touched.current_password && formik.errors.current_password },
                  {
                    'is-valid': formik.touched.current_password && !formik.errors.current_password,
                  }
                )}
                type='password'
                autoComplete='off'
              />
              {formik.touched.current_password && formik.errors.current_password && (
                <div className='fv-plugins-message-container'>
                  <span className="text-danger">{formik.errors.current_password}</span>
                </div>
              )}
            </div>
            <div className='fv-row mb-10'>
              <div className='d-flex justify-content-between mt-n5'>
                <div className='d-flex flex-stack mb-2'>
                  <label className='form-label fw-bolder text-light-blue fs-6 mb-0'>{intl.formatMessage({ id: "NEW.PWD" })}</label>
                </div>
              </div>
              <input
                placeholder={intl.formatMessage({ id: "NEW.PWD" })}
                type='password'
                autoComplete='off'
                {...formik.getFieldProps('new_password')}
                className={clsx(
                  'form-control form-control-lg ',
                  {
                    'is-invalid': formik.touched.new_password && formik.errors.new_password,
                  },
                  {
                    'is-valid': formik.touched.new_password && !formik.errors.new_password,
                  }
                )}
              />
              {formik.touched.new_password && formik.errors.new_password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className="text-danger">{formik.errors.new_password}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='fv-row mb-10'>
              <div className='d-flex justify-content-between mt-n5'>
                <div className='d-flex flex-stack mb-2'>
                  <label className='form-label fw-bolder text-light-blue fs-6 mb-0'>{intl.formatMessage({ id: "CONFIRM.PWD" })}</label>
                </div>
              </div>
              <input
                placeholder={intl.formatMessage({ id: "CONFIRM.PWD" })}
                type='password'
                autoComplete='off'
                {...formik.getFieldProps('confirm_password')}
                className={clsx(
                  'form-control form-control-lg ',
                  {
                    'is-invalid': formik.touched.confirm_password && formik.errors.confirm_password,
                  },
                  {
                    'is-valid': formik.touched.confirm_password && !formik.errors.confirm_password,
                  }
                )}
              />
              {formik.touched.confirm_password && formik.errors.confirm_password && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span className="text-danger">{formik.errors.confirm_password}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Action */}
            <div className='fv-row  mb-5 mx-0'>
              <button
                type='submit'
                id='kt_password_reset_submit'
                className='btn btn-lg green-background text-light-blue'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className='indicator-label'>{intl.formatMessage({ id: "CONTINUE" })}</span>}
                {loading && (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    {intl.formatMessage({ id: "WAIT" })}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
          </div>
        </KTCardBody>
      </KTCard>
    </>
  )
}

const ResetPasswordPage: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'RESET.PWD' })}</PageTitle>
      <ResetPassword />
    </>
  )
}

export { ResetPasswordPage }
