/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import {
  toAbsoluteUrl,
  defaultMessages,
  defaultUserInfos,
  MessageModel,
  UserInfoModel,
  messageFromClient,
} from '../../../_metronic/helpers'
import {
  useParams
} from "react-router-dom";
import { broadcast_msg, get_chats, push_msg, group_msg } from '../../utills/api_helper'
import moment from 'moment';
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


const lang: any = localStorage.getItem('i18nConfig')?.slice(17, 19);
const bufferMessages = defaultMessages

const ChatInner = ({ isDrawer = false, details }) => {
  const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [messages, setMessages] = useState<any>([])
  const [userInfos] = useState<UserInfoModel[]>(defaultUserInfos)
  const [inputFile, setInputFile] = useState<HTMLInputElement | null>(null);
  const [attachment, setAttachment] = useState<any>();
  const [sendingMsgLoading, setSendingMsgLoading] = useState<boolean>(false);

  let params = useParams();
  const messagesEndRef = useRef<any>(null)
  const intl = useIntl();


  useEffect(() => {
    moment.locale(lang)
    setInputFile(document.getElementById("input-file") as HTMLInputElement);
    get_messages();
    setTimeout(() => {
      //need to call after messages are loaded from api,  coz did not get another easy way
      scrollToBottom()
    }, 1000);
  }, [])

  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" })
  }

  function get_messages() {
    if (details) {
      let form_data = {};
      if (params.page == "bot") {
        form_data = {
          uniq_id: details.uniq_id,
          thing_type: "broadcast_msg"
        };

      } else if (params.page == "user") {
        form_data = {
          bot_id: details.bot_id,
          send_to: details._id,
          thing_type: "push_msg"
        };
      }
      else if (params.page == "group") {
        form_data = {
          uniq_id: details.uniq_id,
          thing_type: "group_msg"
        };
      }
      get_chats(form_data).then((res) => {
        if (res.data.status == "success") {
          setMessages(res.data.data);
        }
      });
    }
  }

  const sendMessage = () => {
    setSendingMsgLoading(true);
    // if (message) {
    // let form_data = {};
    let payload_types: any = [];
    let form_data = new FormData();
    let msg_type = "";
    if (attachment) {
      form_data.append('previewImageUrl', attachment);
      form_data.append('originalContentUrl', attachment);
      msg_type = "flex";
      payload_types.push("image");
    }
    if (message) {
      form_data.append('message', message);
      msg_type = "flex";
      payload_types.push("text");
    }

    if (attachment && message) {
      msg_type = "flex";
    }
    form_data.append('msg_type', msg_type);
    form_data.append('payload_types', JSON.stringify(payload_types));
    if (params.page == "bot") {
      form_data.append('bot_id', details._id + "");
      broadcast_msg(form_data, true).then((res) => {
        setSendingMsgLoading(false);
        if (res.data.status == "success") {
          setMessages([...messages, res.data.data]);
          setMessage("");
          setAttachment(undefined);
          let messageInputField: any = document.getElementById("messageInputField");
          messageInputField.innerHTML = "";
          scrollToBottom();
          toast(res.data.msg,
            { type: "success" });
        } else {
          toast(res.data.msg,
            { type: "error" });
        }
      });

    } else if (params.page == "user") {
      form_data.append('bot_id', details.bot_id + "");
      form_data.append('send_to', details._id + "");
      form_data.append('thing_type', "push_msg");

      // let form_data = {};
      // form_data = {
      //   message: message,
      //   msg_type: "text",
      //   bot_id: details.bot_id,
      //   send_to: details._id,
      //   thing_type: "push_msg"
      // };
      push_msg(form_data, true).then((res) => {
        setSendingMsgLoading(false);
        if (res.data.status == "success") {
          setMessages([...messages, res.data.data]);
          setMessage("");
          setAttachment(undefined);
          let messageInputField: any = document.getElementById("messageInputField");
          messageInputField.innerHTML = "";
          scrollToBottom();
          toast(res.data.msg,
            { type: "success" });
        } else {
          toast(res.data.msg,
            { type: "error" });
        }
      });
    }
    else if (params.page == "group") {
      form_data.append('group_id', details._id + "");
      form_data.append('thing_type', "group_msg");
      // form_data = {
      //   message: message,
      //   msg_type: "text",
      //   group_id: details._id,
      //   send_to: details._id,
      //   thing_type: "group_msg"
      // };
      group_msg(form_data, true).then((res) => {
        setSendingMsgLoading(false);
        if (res.data.status == "success") {
          setMessages([...messages, res.data.data]);
          setMessage("");
          setAttachment(undefined);
          let messageInputField: any = document.getElementById("messageInputField");
          messageInputField.innerHTML = "";
          scrollToBottom();
          toast(res.data.msg,
            { type: "success" });
        } else {
          toast(res.data.msg,
            { type: "error" });
        }
      });
    }


    // }
    // const newMessage: MessageModel = {
    //   user: 2,
    //   type: 'out',
    //   text: message,
    //   time: 'Just now',
    // }

    // bufferMessages.push(newMessage)
    // setMessages(bufferMessages)
    // toggleChatUpdateFlat(!chatUpdateFlag)
    // setMessage('')
    // setTimeout(() => {
    //   bufferMessages.push(messageFromClient)
    //   setMessages(() => bufferMessages)
    //   toggleChatUpdateFlat((flag) => !flag)
    // }, 1000)
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }

  const message_component = (message) => {
    if (message.msg_type == "text") {
      return (
        <>
          <div> {message?.message}</div>
          <div className={clsx('text-muted mt-1 fw-bold fs-7 text-end')}>{moment(message?.created_at).format('lll')}</div>
        </>
      )
    } else if (message.msg_type == "image") {
      return (
        <>
          <div> <img src={process.env.REACT_APP_WEB_URL + "/" + message?.previewImageUrl} height="150" /></div>
          <div className={clsx('text-muted mt-1 fw-bold fs-7 text-end')}>{moment(message?.created_at).format('lll')}</div>
        </>
      )
    }
    else if (message.msg_type == "flex") {
      return (
        <>
          <div>
            {
               message?.previewImageUrl ?
               <img src={process.env.REACT_APP_WEB_URL + "/" + message?.previewImageUrl} height="150" />
               :
               null
            }
            {
              message?.message ? (
                <div className='mt-3'> {message?.message}</div>
              ) :
              null
            }
          </div>
          <div className={clsx('text-muted mt-1 fw-bold fs-7 text-end')}>{moment(message?.created_at).format('lll')}</div>
        </>
      )
    }
  }

  const handleUpload = () => {
    inputFile?.click();
  };

  const file_choosen = (input) => {
    console.log("file_choosen caleed");
    let filePath = input.target.value;
    let files = input.target.files[0];
    input.target.value = ''
    if (!filePath) {
      return;
    }

    // let fileExt = input.value.split('.')[1].toLowerCase();
    let fileExt = filePath.substring(filePath.lastIndexOf('.') + 1, filePath.length).toLowerCase();

    let msg_type = "";
    let imageType = "";
    switch (fileExt) {
      case "png":
        imageType = "image/png";
        msg_type = "image";
        break;
      case "jpg":
      case "jpeg":
        imageType = "image/jpeg";
        msg_type = "image";
        break;
      // case "mp4":
      //   imageType = "video/mp4"
      //   msg_type = "video";
      //   break;
      /* case "m4a":
        imageType = "audio/m4a";
        msg_type = "audio";
        break; */
      default:
        input.value = "";
        msg_type = "";
        imageType = "";
        toast(intl.formatMessage({ id: 'Only images(PNG/JPG/JPEG) are supported' }),
          { type: "error" });

        return;
    }
    console.log("files ", files)
    setAttachment(files)
    // if (params.page == "bot") {
    //   let form_data = new FormData();
    //   form_data.append('bot_id', details._id + "");
    //   form_data.append('msg_type', msg_type + "");
    //   form_data.append('previewImageUrl', files);
    //   form_data.append('originalContentUrl', files);
    //   // broadcast_msg(form_data, true).then((res) => {
    //   //   if (res.data.status == "success") {
    //   //     setMessages([...messages, res.data.data]);
    //   //     scrollToBottom();
    //   //   } else {
    //   //     toast(res.data.msg,
    //   //       { type: "error" });
    //   //   }
    //   // });
    // }
    // else if (params.page == "user") {
    //   let form_data = new FormData();
    //   form_data.append('bot_id', details.bot_id + "");
    //   form_data.append('msg_type', msg_type + "");
    //   form_data.append('send_to', params.id + "");
    //   form_data.append('thing_type', "push_msg");
    //   form_data.append('previewImageUrl', files);
    //   form_data.append('originalContentUrl', files);
    //   push_msg(form_data, true).then((res) => {
    //     if (res.data.status == "success") {
    //       setMessages([...messages, res.data.data]);
    //       scrollToBottom();
    //     } else {
    //       toast(res.data.msg,
    //         { type: "error" });
    //     }
    //   });
    // }
    // else if (params.page == "group") {
    //   // console.log(files)
    //   let form_data = new FormData();
    //   form_data.append('group_id', details._id + "");
    //   form_data.append('msg_type', msg_type + "");
    //   form_data.append('send_to', details._id + "");
    //   form_data.append('thing_type', "group_msg");
    //   form_data.append('previewImageUrl', files);
    //   form_data.append('originalContentUrl', files);
    //   group_msg(form_data, true).then((res) => {
    //     if (res.data.status == "success") {
    //       setMessages([...messages, res.data.data]);
    //       scrollToBottom();
    //     } else {
    //       toast(res.data.msg,
    //         { type: "error" });
    //     }
    //   });
    // }
  }

  function delete_attachment() {
    setAttachment(null)
  }

  return (
    <div
      className='card-body p-1'
      id={isDrawer ? 'send_msg_drawer_messenger_body' : 'kt_chat_messenger_body'}
    >
      <div
        className={clsx('scroll-y me-n5 pe-5', { 'h-300px h-lg-auto': !isDrawer })}
        data-kt-element='messages'
        data-kt-scroll='true'
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies={
          isDrawer
            ? '#send_msg_drawer_messenger_header, #send_msg_drawer_messenger_footer'
            : '#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
        }
        data-kt-scroll-wrappers={
          isDrawer ? '#send_msg_drawer_messenger_body' : '#kt_content, #kt_chat_messenger_body'
        }
        data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}
      >
        {messages && messages.length > 0 ? (
          messages.map((message, index) => {
            const contentClass = `${isDrawer ? '' : 'd-flex'} ${message.by == "superAdmin" ? "justify-content-end" : "justify-content-start"} mb-10`
            return (
              <div
                key={`message${index}`}
                className={clsx('d-flex', contentClass, 'mb-10 mx-5')}
                ref={messagesEndRef}
              >
                <div
                  className={clsx(
                    'p-5 rounded',
                    `bg-light-info`,
                    'text-dark fw-bold mw-lg-400px',
                    `text-end'}`
                  )}
                  data-kt-element='message-text'
                // dangerouslySetInnerHTML={{ __html: message?.message }}
                >

                  {
                    message_component(message)
                  }

                </div>
              </div>
            )
          })
        ) : (
          <div
            className={clsx(
              'p-5 rounded',
              `bg-light-info`,
              'text-dark fw-bold mw-lg',
              `text-center`,
              `text-end'}`
            )}
            data-kt-element='message-text'
          // dangerouslySetInnerHTML={{ __html: message?.message }}
          >
            {intl.formatMessage({ id: "NO.CHATS" })}
          </div>
        )}
      </div>

      <div
        className='card-footer p-2'
        id={isDrawer ? 'send_msg_drawer_messenger_footer' : 'kt_chat_messenger_footer'}
      >
        <div className='border-light-blue mb-3' style={{ width: "100%", padding: 5 }}>
          <div
            className='no-border editable'
            id="messageInputField"
            data-placeholder={intl.formatMessage({ id: "Type a message" })}
            contentEditable="true"
            onInput={function (e) {
              if (e.currentTarget.textContent && e.currentTarget.textContent?.length <= 400) {
                setMessage(e.currentTarget.textContent ? e.currentTarget.textContent : "");
                // setMessageEditable(true);
              } else {
                let messageInputField: any = document.getElementById("messageInputField");
                if (messageInputField) {
                  messageInputField.innerHTML = message;
                  // code to push cursor at the end --- start
                  messageInputField.focus();
                  // select all the content in the element
                  document.execCommand('selectAll', false, undefined);
                  // collapse selection to the end
                  let getSelection = document.getSelection();
                  if (getSelection) {
                    getSelection.collapseToEnd();
                  }
                  // code to push cursor at the end --- end
                }
              }
            }}
            style={{ minHeight: attachment ? 20 : 80 }}>
          </div>
          {
            attachment ?
              <div onClick={delete_attachment}>
                <div style={{ position: "relative", top: 10, right: -65 }}>
                  <OverlayTrigger
                    delay={{ hide: 50, show: 50 }}
                    overlay={(props) => (
                      <Tooltip {...props}>
                        {intl.formatMessage({ id: 'Delete' })}
                      </Tooltip>
                    )}
                    placement="top">
                    <i className="fas fa-trash green-background text-light-blue rounded-circle" style={{ padding: 5, fontSize: 10 }}></i>
                  </OverlayTrigger>
                </div>
                <img src={URL.createObjectURL(attachment)} style={{ height: 70, width: 70, borderRadius: 5 }} />
              </div>
              :
              null
          }
        </div>
        {/* <textarea
          className='form-control form-control-flush mb-3 rounded'
          rows={3}
          data-kt-element='input'
          placeholder={intl.formatMessage({ id: "Type a message" })}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={onEnterPress}
          maxLength={5000}
        ></textarea> */}

        <div className='d-flex flex-stack'>
          <OverlayTrigger
            delay={{ hide: 50, show: 50 }}
            overlay={(props) => (
              <Tooltip {...props}>
                {intl.formatMessage({ id: 'Only images(PNG/JPG/JPEG) are supported' })}
              </Tooltip>
            )}
            placement="top">
            <div className='d-flex align-items-center me-2'>
              <input id="input-file" className="d-none" type="file" onChange={file_choosen} />
              <button
                className='btn btn-sm btn-icon btn-active-light-primary me-1'
                type='button'
                data-bs-toggle='tooltip'
                onClick={handleUpload}
              >
                <i className='bi bi-paperclip fs-3 text-light-blue'></i>
              </button>

            </div>
          </OverlayTrigger>
          <button
            className='btn dark-green-background text-light-blue'
            type='button'
            data-kt-element='send'
            onClick={sendMessage}
            disabled={sendingMsgLoading}
          >

            {!sendingMsgLoading && <span className='indicator-label'>{intl.formatMessage({ id: "Send" })}</span>}
            {sendingMsgLoading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                {intl.formatMessage({ id: "WAIT" })}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </div>
  )
}

export { ChatInner }
