import React, { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { requestPassword } from '../core/_requests'
import { useIntl } from 'react-intl'
import { toast } from 'react-toastify'


const initialValues = {
  email: '',
}

const lang: any = localStorage.getItem('i18nConfig')?.slice(17, 19);




export function ForgotPassword() {
  const intl = useIntl();
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'EMAIL.REQUIRED' }))
      .required(intl.formatMessage({ id: 'EMAIL.VALIDATION' })),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      requestPassword(values.email, lang)
        .then((res: any) => {
          toast(res?.data?.msg, { type: "success" })
          setLoading(false)
        })
        .catch((err) => {
          toast(err?.response?.data?.msg, { type: "error" })
          // setHasErrors(true)
          setLoading(false)
          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          <div className='text-gray-400 fw-bold fs-4'>{intl.formatMessage({ id: 'EMAIL_PWD_RESET' })}</div>
        </div>

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>{intl.formatMessage({ id: "LOGIN.Email" })}</label>
          <input
            type='email'
            placeholder={intl.formatMessage({ id: "LOGIN.Email" })}
            autoComplete='off'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg',
              { 'is-invalid': formik.touched.email && formik.errors.email },
              {
                'is-valid': formik.touched.email && !formik.errors.email,
              }
            )}
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className="text-danger">{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg dark-green-background text-light-blue fw-bolder me-4'
          >
            <span className='indicator-label'>{intl.formatMessage({ id: "SUBMIT" })}</span>
            {loading && (
              <span className='indicator-progress'>
                {intl.formatMessage({ id: "WAIT" })}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg text-light-blue fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {intl.formatMessage({ id: "CANCEL" })}
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
