/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { KTCard, KTCardBody } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import ReactPaginate from 'react-paginate';
import moment from 'moment'
import { richmenu_interactions, get_followers } from '../../utills/api_helper'
import { useParams } from "react-router-dom";

const lang = localStorage.getItem('i18nConfig')?.slice(17, 19);


const InteractionWrapperList: FC = () => {

  return (
    <PaginatedItems />
  )
}

function PaginatedItems() {
  const intl = useIntl()
  let params = useParams();
  const [details, setDetails] = useState<any>([]);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [forcePage, setForcePage] = useState<number>(0)
  const [remountComponent, setRemountComponent] = useState(0);
  const [userInteractionsList, setUserInteractionsList] = useState<any>([]);

  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    moment.locale(lang)
    get_followers({ bot_id: params.botid, _id: params.userid }).then((res) => {
      setDetails(res?.data?.data);
    });

    richmenu_interactions({ bot_id: params.botid, user_id: params.userid }).then((res) => {
      setUserInteractionsList(res.data.data);
    })
  }, [])

  const endOffset = itemOffset + itemsPerPage;
  const showingItemsText = `Showing items from ${itemOffset + 1} to ${endOffset <= userInteractionsList.length ? endOffset : userInteractionsList.length}`;
  const currentItems = userInteractionsList.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(userInteractionsList.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % userInteractionsList.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <KTCard>
        <KTCardBody className='py-4'>
          <div className='border-0' >
            <div className='row'>
              <div className='col-lg-1'>
                <div className='col-lg-12 py-2'>
                  {/* setRemountComponent is used to reset selection of active pagination to 1 */}
                  <select className='form-select border-light-blue text-light-blue' onChange={(e) => { setItemOffset(0); setItemsPerPage(parseInt(e.target.value)); setForcePage(0); setRemountComponent(Math.random()); }}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                  </select>
                </div>
              </div>
              {/* begin::Card toolbar */}
              <div className='col-lg-10'>
                <div className='row'>
                  <div className='col-lg-5 py-2' style={{ fontSize: '15px', marginTop: '10px' }}>
                    <b>{intl.formatMessage({ id: 'BOT.NAME' })}</b> : {details[0]?.bot_id?.displayName}
                  </div>
                  <div className='col-lg-3 py-2' style={{ fontSize: '15px', marginTop: '10px' }}>
                    <b>{intl.formatMessage({ id: 'USER' })}</b> : {details[0]?.displayName}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </KTCardBody>
      </KTCard>
      <KTCard className='mt-3'>
        <KTCardBody className='py-4'>

          {/* USERLIST FOR BOT DISPLAY MODAL */}
          <div className='table-responsive'>
            <table
              id='kt_table_users'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer text-light-blue'
            >
              <thead className='border-bottom-very-light-blue'>
                <tr className='text-start fw-bolder fs-7 text-uppercase gs-0'>
                  <td>#</td>
                  <td className='text-nowrap'>{intl.formatMessage({ id: 'RICHMENU' })}</td>
                  <td className='text-nowrap'>{intl.formatMessage({ id: 'ACTION.TYPE' })}</td>
                  <td className='text-nowrap'>{intl.formatMessage({ id: 'TEXT' })}</td>
                  <td className='text-nowrap'>{intl.formatMessage({ id: 'MSG.DETAILS' })}</td>
                  <td className='text-nowrap text-center'>{intl.formatMessage({ id: 'CLICKS' })}</td>
                  <td className='text-nowrap'>{intl.formatMessage({ id: 'LAST.CLICK' })}</td>
                </tr>
              </thead>
              <tbody className='fw-bold'>
                {userInteractionsList.length ?
                  userInteractionsList &&
                  userInteractionsList.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className='text-nowrap'>{item?.area_id?.richMenu_id?.name ? item?.area_id?.richMenu_id?.name : "---"}</td>
                      <td className='text-nowrap'>{item?.area_id?.action?.type ? item?.area_id?.action?.type : "---"}</td>
                      <td className='text-nowrap'>{item?.area_id?.action?.text ? item?.area_id?.action?.text : "---"}</td>
                      <td className='text-nowrap'>{intl.formatMessage({ id: 'MSG.TYPE' })} : {!item?.area_id?.message?.type ? "---" : item?.area_id?.message?.type}</td>
                      <td className='text-nowrap text-center'>{!item?.area_clicks ? "---" : item?.area_clicks}</td>
                      <td className='text-nowrap'>{item?.updated_at ? moment(item?.updated_at).format('MMM D YYYY, h:mm:ss a') : "---"}</td>
                    </tr>
                  ))
                  : (
                    <tr>
                      <td colSpan={7}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          {intl.formatMessage({ id: 'NO.RECORDS' })}
                        </div>
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
          <div className='row' key={remountComponent}>

            <div className='col-lg-6 d-flex align-items-center'>{showingItemsText}</div>
            <ReactPaginate
              nextLabel="next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel="< previous"
              pageClassName="page-item"
              pageLinkClassName="page-link text-light-blue"
              previousClassName="page-item"
              previousLinkClassName="page-link text-light-blue"
              nextClassName="page-item"
              nextLinkClassName="page-link text-light-blue"
              breakLabel="..."
              breakClassName="page-item text-light-blue"
              breakLinkClassName="page-link text-light-blue"
              containerClassName="col-lg-6 pagination justify-content-end"
              activeClassName="green-background rounded"
              renderOnZeroPageCount={() => null}
              forcePage={forcePage}
            />
          </div>
        </KTCardBody>
      </KTCard>
    </>
  );
}

const InteractionWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.INTERACTIONS' })}</PageTitle>
      <InteractionWrapperList />
    </>
  )
}

export { InteractionWrapper }