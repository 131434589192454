/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {toast} from 'react-toastify'
import {
  send_mail,
  create_mail_config,
  get_mail_config,
  edit_mail_config,
} from '../../utills/api_helper'
import { useAuth } from '../../modules/auth'
import { ErrorsPage } from '../../modules/errors/ErrorsPage'

const lang = localStorage.getItem('i18nConfig')?.slice(17, 19)

const MailConfigrationList: FC = () => {
  const [mailValues, setmailValues] = useState({})
  const [host, setHost] = useState('')
  const [username, setUsername] = useState('')
  const [port, setPort] = useState(0)
  const [password, setPassword] = useState('')
  const [id, setId] = useState('')
  const [email, setEmail] = useState('')
  const [loadingUpdate, setLoadingUpdate] = useState(false)
  const [loadingMail, setLoadingMail] = useState(false)
  const intl = useIntl()

  const initialValues = {
    host: '',
    port: '',
    username: '',
    password: '',
  }

  useEffect(() => {
    get_mail_config().then((res) => {
      setId(res.data.data[0]._id)
      setHost(res.data.data[0].mail_config.host)
      setUsername(res.data.data[0].mail_config.username)
      setPort(res.data.data[0].mail_config.port)
      setPassword(res.data.data[0].mail_config.password)
      setmailValues(res.data.data[0].mail_config)
      formik.setFieldValue('host', res.data.data[0].mail_config.host)
      formik.setFieldValue('port', res.data.data[0].mail_config.port)
      formik.setFieldValue('username', res.data.data[0].mail_config.username)
      formik.setFieldValue('password', res.data.data[0].mail_config.password)
    })
  }, [])

  async function onChangeEmail(event) {
    formik.setFieldValue('email', event.target.value)
    setEmail(event.target.value)
  }

  async function onChangeHost(event) {
    formik.setFieldValue('host', event.target.value)
    setHost(event.target.value)
  }

  async function onChangePort(event) {
    formik.setFieldValue('port', event.target.value)
    setPort(event.target.value)
  }

  async function onChangeUsername(event) {
    formik.setFieldValue('username', event.target.value)
    setUsername(event.target.value)
  }

  async function onChangePass(event) {
    formik.setFieldValue('password', event.target.value)
    setPassword(event.target.value)
  }

  const formik_mail = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email(intl.formatMessage({id: 'EMAIL.VALIDATION'})),
    }),
    onSubmit: async (values, {resetForm, setSubmitting}) => {
      setLoadingMail(true)
      try {
        const res: any = await send_mail({email: values.email, lang: lang})
        if (res.data.status == 'success') {
          setSubmitting(false)
          setLoadingMail(false)
          toast(res.data.msg, {type: 'success'})
          resetForm()
        } else {
          setSubmitting(false)
          setLoadingMail(false)
          toast(res.data.msg, {type: 'error'})
        }
      } catch (error: any) {
        console.error(error)
        toast('User creation failed', {type: 'error'})
        setSubmitting(false)
        setLoadingMail(false)
      }
    },
  })

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      host: Yup.string().max(30, intl.formatMessage({id: 'CHARACTES.LIMIT'})),
      port: Yup.number(),
      username: Yup.string().max(30, intl.formatMessage({id: 'CHARACTES.LIMIT'})),
      password: Yup.string(),
    }),
    onSubmit: async (values, {setSubmitting}) => {
      setLoadingUpdate(true)
      try {
        const res: any = await edit_mail_config({
          _id: id,
          host: values.host,
          port: values.port,
          username: values.username,
          password: values.password,
          lang: lang,
        })
        if (res.data.status == 'success') {
          setSubmitting(false)
          setLoadingUpdate(false)
          toast(res.data.msg, {type: 'success'})
        } else {
          setSubmitting(false)
          setLoadingUpdate(false)
          toast(res.data.msg, {type: 'error'})
        }
      } catch (error: any) {
        console.error(error)
        toast('User creation failed', {type: 'error'})
        setSubmitting(false)
        setLoadingUpdate(false)
      }
    },
  })

  return (
    <>
      <div className='row'>
        <div className='card col-md-6' style={{width: '50rem' , padding : '20px'}}>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_edit_form'
            onSubmit={formik.handleSubmit}
          >
            <div className='form-group'>
              <label htmlFor='host' style={{marginBottom: '10px', marginTop: '10px'}}>
                <h4 className='text-light-blue'>{intl.formatMessage({id: 'HOST'})}</h4>
              </label>
              <input
                {...formik.getFieldProps('host')}
                className={clsx(
                  'form-control form-control-lg ',
                  {'is-invalid': formik.touched.host && formik.errors.host},
                  {
                    'is-valid': formik.touched.host && !formik.errors.host,
                  }
                )}
                id='host'
                name='host'
                type='text'
                value={host}
                onChange={(e) => {
                  onChangeHost(e)
                }}
              />
              {formik.touched.host && formik.errors.host && (
                  <div className='fv-plugins-message-container'>
                    <span className="text-danger">{formik.errors.host}</span>
                  </div>
                )}
             </div>

            <div className='form-group'>
              <label htmlFor='post' style={{marginBottom: '10px', marginTop: '10px'}}>
                <h4 className='text-light-blue'>{intl.formatMessage({id: 'PORT'})}</h4>
              </label>
              <input
                {...formik.getFieldProps('port')}
                className={clsx(
                  'form-control form-control-lg ',
                  {'is-invalid': formik.touched.port && formik.errors.port},
                  {
                    'is-valid': formik.touched.port && !formik.errors.port,
                  }
                )}
                id='port'
                name='port'
                type='text'
                value={port}
                onChange={(e) => {
                  onChangePort(e)
                }}
              />
               {formik.touched.port && formik.errors.port && (
                  <div className='fv-plugins-message-container'>
                    <span className="text-danger">{formik.errors.host}</span>
                  </div>
                )}
              </div>

            <div className='form-group'>
              <label htmlFor='username' style={{marginBottom: '10px', marginTop: '10px'}}>
                <h4 className='text-light-blue'>{intl.formatMessage({id: 'USERNAME'})}</h4>
              </label>
              <input
                {...formik.getFieldProps('username')}
                className={clsx(
                  'form-control form-control-lg ',
                  {'is-invalid': formik.touched.username && formik.errors.username},
                  {
                    'is-valid': formik.touched.username && !formik.errors.username,
                  }
                )}
                id='username'
                name='username'
                type='text'
                value={username}
                onChange={(e) => {
                  onChangeUsername(e)
                }}
              />
               {formik.touched.username && formik.errors.username && (
                  <div className='fv-plugins-message-container'>
                    <span className="text-danger">{formik.errors.username}</span>
                  </div>
                )}
              </div>

            <div className='form-group'>
              <label htmlFor='password' style={{marginBottom: '10px', marginTop: '10px'}}>
                <h4 className='text-light-blue'>{intl.formatMessage({id: 'PASSWORD'})}</h4>
              </label>
              <input
                {...formik.getFieldProps('password')}
                className={clsx(
                  'form-control form-control-lg ',
                  {'is-invalid': formik.touched.password && formik.errors.password},
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
                id='password'
                name='password'
                type='text'
                value={password}
                onChange={(e) => {
                  onChangePass(e)
                }}
              />
              {formik.touched.password && formik.errors.password && (
                <div className='fv-plugins-message-container'>
                  <span className="text-danger">{formik.errors.password}</span>
                </div>
              )}
            </div>
            <button
              type='submit'
              className='btn dark-green-background text-light-blue'
              style={{marginTop: '20px', marginBottom: '20px'}}
            >
              {!loadingUpdate && (
                <span className='indicator-label'>{intl.formatMessage({id: 'UPDATE'})}</span>
              )}
              {loadingUpdate && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'WAIT'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </form>
        </div>

        <div className='card col-md-6' style={{width: '50rem' , padding : '20px'}}>
          <form
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            noValidate
            id='kt_login_edit_form'
            onSubmit={formik_mail.handleSubmit}
          >
            <div className='form-group'>
              <label htmlFor='email' style={{marginBottom: '10px', marginTop: '10px'}}>
                <h4 className='text-light-blue'>{intl.formatMessage({id: 'EMAIL'})}</h4>
              </label>
              <input
                {...formik.getFieldProps('email')}
                className={clsx(
                  'form-control form-control-lg ',
                  {'is-invalid': formik.touched.password && formik.errors.password},
                  {
                    'is-valid': formik.touched.password && !formik.errors.password,
                  }
                )}
                id='email'
                name='email'
                type='text'
                value={formik_mail.values.email}
                onChange={formik_mail.handleChange}
              />
            </div>
            <button
              type='submit'
              className='btn dark-green-background text-light-blue'
              style={{width: '125px', marginTop: '10px'}}
            >
              {!loadingMail && (
                <span className='indicator-label'>{intl.formatMessage({id: 'SEND.MAIL'})}</span>
              )}
              {loadingMail && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  {intl.formatMessage({id: 'WAIT'})}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </form>
        </div>
      </div>
    </>
  )
}

const MailConfigrationsWrapper: FC = () => {
  const intl = useIntl();
  const {currentUser} = useAuth();

  return (
    <>
    {currentUser?.role !== 'superAdmin' ? (
      <> 
          <ErrorsPage />
      </>
    ) : (
      <> 
       <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.MAIL_CONFIGRATIONS' })}</PageTitle>
       <MailConfigrationList />
      </>
    )}
    </>
  )
}

export {MailConfigrationsWrapper}
