import { lazy, FC, Suspense, useEffect, useState } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { BotsWrapper } from '../pages/Bots/BotsWrapper'
import { GroupsWrapper } from '../pages/Groups/GroupsWrapper'
import { UsersWrapper } from '../pages/Users/UsersWrapper'
import { RichMenusWrapper } from '../pages/RichMenus/RichMenusWrapper'
import { MessageTemplatesWrapper } from '../pages/MessageTemplates/MessageTemplatesWrapper'
import { SurveysWrapper } from '../pages/Surveys/SurveysWrapper'
import { MailConfigrationsWrapper } from '../pages/Settings/MailConfigrationsWrapper'
import { ReservationsWrapper } from '../pages/Reservations/ReservationsWrapper'
import { AdminsWrapper } from '../pages/Admins/AdminWrapper'
import { ChatsWrapper } from '../pages/Chats/ChatsWrapper'
import { InteractionWrapper } from '../pages/Users/InteractionWrapper'
import { ReferralWrapper } from '../pages/Users/ReferralWrapper'
import { CreateRichMenusWrapper } from '../pages/RichMenus/CreateRichMenuWrapper'
import { GroupViewWrapper } from '../pages/Groups/GroupViewWrapper'
import { ResetPasswordPage } from '../pages/Users/ResetPasswordPage'
import { CreateMessageTemplateWrapper } from '../pages/MessageTemplates/CreateMessageTemplateWrapper'
import { useAuth } from '../modules/auth'
import { ContactListWrapper } from '../pages/Admins/ContactListWrapper'
import { ScenariosWrapper } from '../pages/Scenarios/ScenariosWrapper'
import { CreateScenarioWrapper } from '../pages/Scenarios/CreateScenarioWrapper'
import { checkAccess, getRedirectRoute } from '../utills/helper_functions'
import { toast } from 'react-toastify'
import { FeatureListWrapper } from '../pages/Features/FeatureListWrapper'
import { InvoiceGeneratesWrapper } from '../pages/InvoiceGenerate/InvoiceGenerateWrapper'
import { CreateInvoiceGenerateWrapper } from '../pages/InvoiceGenerate/CreateInvoiceGenerateWrapper'


const PrivateRoutes = () => {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const [adminRedirectRoute, setAdminRedirectRoute] = useState<any>("");
  const { currentUser, logout } = useAuth();


  useEffect(() => {
    if (currentUser?.role !== 'superAdmin') {
      let route: any = "";
      route = getRedirectRoute(currentUser);
      if (route == "/error/404") {
        toast("Please contact to superadmin to get some features.", { type: "success" })
        setTimeout(() => {
          logout();
        }, 2000);
      }
    }
  }, [])

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        {currentUser?.role === 'superAdmin' ? (
          <>
            <Route path='auth/*' element={<Navigate to='/admins' />} />
          </>
        ) : (
          <>
            <Route path='auth/*' element={<Navigate to={getRedirectRoute(currentUser)} />} />
          </>
        )}
        {/* <Route path='auth/*' element={<Navigate to='/dashboard' />} /> */}
        {/* Pages */}

        {checkAccess(currentUser, "", true) && (
          <>
            {/* <Route path='dashboard' element={<DashboardWrapper />} /> */}
            <Route path='admins' element={<AdminsWrapper />} />
          </>
        )}
        {/* {checkAccess(currentUser, "bots", true) && ( */}
          <Route path='bots' element={<BotsWrapper />} />
        {/* )} */}
        {/* {(checkAccess(currentUser, "bots", true) || checkAccess(currentUser, "users", true) || checkAccess(currentUser, "groups", true)) && ( */}
          <Route path='chat/:page/:id' element={<ChatsWrapper />} />
        {/* )} */}
        {/* {checkAccess(currentUser, "users", true) && ( */}
          <>
            <Route path='user/interactions/:userid/:botid' element={<InteractionWrapper />} />
            <Route path='user/referrals/:userid/:botid' element={<ReferralWrapper />} />
          </>
        {/* )} */}
        {currentUser?.role !== 'superAdmin' && (
          <>
            <Route path='user-group/view/:botid/:userid' element={<GroupViewWrapper />} />
            <Route path='groups' element={<GroupsWrapper />} />
          </>
        )}
        {/* {checkAccess(currentUser, "users", true) && ( */}
          <Route path='users' element={<UsersWrapper />} />
        {/* )} */}
        {/* {checkAccess(currentUser, "richmenus", true) && ( */}
          <>
            <Route path='richmenus' element={<RichMenusWrapper />} />
            <Route path='create-richmenu' element={<CreateRichMenusWrapper />} />
          </>
        {/* )} */}
        {/* {checkAccess(currentUser, "", true) && ( */}
          <Route path='view/contacts' element={<ContactListWrapper />} />
        {/* )} */}
        {/* {checkAccess(currentUser, "message_templates", true) && ( */}
          <>
            <Route path='message-templates' element={<MessageTemplatesWrapper />} />
            <Route path='create-message-templates' element={<CreateMessageTemplateWrapper />} />
            <Route path='edit-message-templates/:id' element={<CreateMessageTemplateWrapper />} />
          </>
        {/* )} */}
        {checkAccess(currentUser, "", true) && (
          <Route path='settings/mail-configurations' element={<MailConfigrationsWrapper />} />
        )}
        {checkAccess(currentUser, "scenario", false) && (
          <>
            <Route path='scenarios' element={<ScenariosWrapper />} />
            <Route path='create-scenario' element={<CreateScenarioWrapper />} />
            <Route path='edit-scenario/:id' element={<CreateScenarioWrapper />} />
          </>
        )}
        {/* {checkAccess(currentUser, "invoice_generate", false) && ( */}
          <>
            <Route path='generated-invoice' element={<InvoiceGeneratesWrapper />} />
            <Route path='generate-invoice' element={<CreateInvoiceGenerateWrapper />} />
            <Route path='edit-generated-invoices/:id' element={<CreateInvoiceGenerateWrapper />} />
          </>
        {/* )} */}
        {currentUser?.role !== 'superAdmin' && (
          <Route path='reset-password' element={<ResetPasswordPage />} />
        )}
        {checkAccess(currentUser, "", true) && (
          <Route path='features' element={<FeatureListWrapper />} />
        )}
        <Route path='reservations' element={<ReservationsWrapper />} />
        <Route path='surveys' element={<SurveysWrapper />} />


        {/* <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} /> */}
        {/* Lazy Modules */}
        {/* <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        /> */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
