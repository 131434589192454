/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { KTCard, KTCardBody, KTSVG } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { toast } from 'react-toastify';
import { Tooltip, OverlayTrigger, Modal, Button } from 'react-bootstrap'
import { create_scenario, get_bots, get_followers, get_user_groups, get_template_list, get_scenarios, update_scenario } from '../../utills/api_helper';
import Select from 'react-select'

import moment from 'moment';
import { useParams } from 'react-router-dom'



const lang = localStorage.getItem('i18nConfig')?.slice(17, 19);

const CreateScenario: FC = () => {
  let params = useParams();
  const intl = useIntl()
  const [bots, setBots] = useState([]);
  const [users, setUsers] = useState<any>([]);
  const [groups, setGroups] = useState<any>([]);
  const [templates, setTemplates] = useState<any>([]);
  const [bot, setBot] = useState<string | undefined>("")
  const [name, setName] = useState<string | undefined>("")
  const [deliveryDate, setDeliveryDate] = useState<string | undefined>("")
  const [selectedUsers, setSelectedUsers] = useState<any>(null)
  const [selectedGroup, setSelectedGroup] = useState<any>("")
  const [sendto, setSendto] = useState<string | undefined>("users")
  const [messageType, setMessageType] = useState<string | undefined>("message")
  const [selectedTemplate, setSelectedTemplate] = useState<any>("")
  const [message, setMessage] = useState<any>("")
  const [startScenario, setStartScenario] = useState<boolean>(true)

  const [createScenarioLoading, setCreateScenarioLoading] = useState<any>("")
  const [scenario, setScenario] = useState<any>()
  const [isEdit, setIsEdit] = useState<any>(false)


  useEffect(() => {
    moment.locale(lang)
    let filter = { bot_id: null, search: "" }
    get_bots(filter).then((res) => {
      if (res.data.status == "success") {
        setBots(res.data.data)
      }
    });
    let templatefilter = { type: "template" }
    get_template_list(templatefilter).then((res) => {
      if (res.data.status == "success") {
        setTemplates(res.data.data)
      }
    });
    if (params.id) {
      get_scenarios_list(null, "", params.id);
      setIsEdit(true);
    }


  }, [])

  function get_scenarios_list(bot_id: any = null, search: string = "", senario_id = "") {
    let filter = { bot_id: bot_id, name: search, _id: senario_id }
    get_scenarios(filter).then((res) => {
      if (res.data.status == "success") {
        let data = res.data.data[0];
        setScenario(data);
        // setBot(data.bot_id._id);
        botChange(data.bot_id._id);
        onChangeName(data.scenario_name);
        onChangeDeliveryDate(data.delivery_date);
        setSendto(data.send_to_type);
        if (data.send_to_type == "users") {
          let all_selected_users: any = [];
          data.users.map((item, index) => {
            all_selected_users.push({ label: item.displayName, value: item._id })
          })
          onChangeUsers(all_selected_users);
        } else if (data.send_to_type == "group") {
          onChangeGroup(data.group._id);
        }
        setMessageType(data.msg_type);
        if (data.msg_type == "template") {
          onChangeTemplate(data.template_id._id)
        } else if (data.msg_type == "message") {
          onChangeMessage(data.message);
        }
        setStartScenario(data.is_active);
      }
    });
  }
  function onChangeName(value) {
    setName(value);
    formikCreateScenario.setFieldValue("name", value);
  }

  function botChange(value) {
    setBot(value);
    formikCreateScenario.setFieldValue("bot", value);
    let follwersFilter = { bot_id: value, search: "" }
    get_followers(follwersFilter).then((res) => {
      if (res.data.status == "success") {
        let localusers: any = [];
        res.data.data.map((item, index) => {
          localusers.push({ value: item._id, label: item.displayName });
        });
        setUsers(localusers)
      }
    });
    get_user_groups(follwersFilter).then((res) => {
      if (res.data.status == "success") {
        setGroups(res.data.data)
      }
    });
  }
  function deliveryDateChange(value) {
    setDeliveryDate(value);
  }

  function sendtoCahnge(value) {
    setSendto(value)
  }
  function messageTypeChange(value) {
    setMessageType(value)
  }

  function onChangeDeliveryDate(value) {
    deliveryDateChange(value);
    formikCreateScenario.setFieldValue("deliveryDate", value);
  }

  function onChangeUsers(values) {
    setSelectedUsers(values);
    formikCreateScenario.setFieldValue("selectedUsers", values);
  }

  function onChangeGroup(value) {
    setSelectedGroup(value);
    formikCreateScenario.setFieldValue("selectedGroup", value);
  }

  function onChangeTemplate(value) {
    setSelectedTemplate(value);
    formikCreateScenario.setFieldValue("selectedTemplate", value);
  }

  function onChangeMessage(value) {
    setMessage(value); formikCreateScenario.setFieldValue("message", value);
  }

  function validationWithcondition() {
    let commonValidation: any = {
      bot: Yup.string()
        .required(intl.formatMessage({ id: 'REQUIRED' })),
      name: Yup.string()
        .required(intl.formatMessage({ id: 'REQUIRED' }))
        .max(30, intl.formatMessage({ id: 'MAXLENGTH' }).replace("[maxLength]", "" + 30)),
      deliveryDate: Yup.string()
        .required(intl.formatMessage({ id: 'REQUIRED' })),
      sendto: Yup.string()
        .required(intl.formatMessage({ id: 'REQUIRED' })),
      messageType: Yup.string()
        .required(intl.formatMessage({ id: 'REQUIRED' }))
    };
    if (sendto == "users") {
      commonValidation.selectedUsers = Yup.array().min(1, intl.formatMessage({ id: 'REQUIRED' }))
    }
    else if (sendto == "group") {
      commonValidation.selectedGroup = Yup.string().required(intl.formatMessage({ id: 'REQUIRED' }));
    }
    if (messageType == "message") {
      commonValidation.message = Yup.string().required(intl.formatMessage({ id: 'REQUIRED' }))
        .max(300, intl.formatMessage({ id: 'MAXLENGTH' }).replace("[maxLength]", "" + 300));
    }
    else if (messageType == "template") {
      commonValidation.selectedTemplate = Yup.string().required(intl.formatMessage({ id: 'REQUIRED' }));
    }
    return Yup.object().shape(commonValidation)
  }

  const initialValues = {
    bot: "",
    name: "",
    deliveryDate: "",
    sendto: sendto,
    selectedUsers: [],
    selectedGroup: "",
    messageType: messageType,
    message: "",
    selectedTemplate: "",
    start: true
  }

  const formikCreateScenario = useFormik({
    initialValues,
    validationSchema: validationWithcondition,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      setCreateScenarioLoading(true);
      let formData: any = {
        scenario_name: name,
        bot_id: bot,
        delivery_date: deliveryDate,
        is_active: startScenario,
        send_to_type: sendto,
        msg_type: messageType
      };
      let all_users_ids: any = [];
      if (sendto == "users") {
        selectedUsers.map((item, index) => {
          all_users_ids.push(item.value);
        })
        formData.users = all_users_ids;
      }
      else if (sendto == "group") {
        formData.group = selectedGroup;
      }
      if (messageType == "message") {
        formData.message = message;
      } else if (messageType == "template") {
        formData.template_id = selectedTemplate;
      }

      try {
        let res: any;
        if (isEdit) {
          formData._id = params.id;
          res = await update_scenario(formData);
        } else {
          res = await create_scenario(formData);
        }
        setCreateScenarioLoading(false)
        setSubmitting(false)
        if (res.data.status == "success") {
          toast(res.data.msg,
            { type: "success" })
          if (!isEdit) {
            resetForm();
            ressetFormValues();
          }
        } else {
          toast(res.data.msg,
            { type: "error" })
        }
      }
      catch (error: any) {
        console.error(error)
        toast(intl.formatMessage({ id: 'Oops! something went wrong' }), { type: "error" })
        setSubmitting(false)
        setCreateScenarioLoading(false)
      }
    }
  })

  function ressetFormValues() {
    setBot("");
    setName("");
    setDeliveryDate("");
    setSendto("users");
    setSelectedUsers([]);
    setSelectedGroup("");
    setMessageType("message");
    setMessage("");
    setSelectedTemplate("");
    setStartScenario(true);
  }


  return (
    <>
      <KTCard>
        <KTCardBody className='py-4'>
          <div className='border-0' >
            <form
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
              noValidate
              id='kt_login_signup_form'
              onSubmit={formikCreateScenario.handleSubmit}
            >
              <div className='row'>
                <div className='col-lg-4'>
                  <div className="mb-5">
                    <label className="form-label">{intl.formatMessage({ id: 'SELECT.BOT' })}</label>
                    <select
                      {...formikCreateScenario.getFieldProps('bot')}
                      className='form-select border-light-blue text-light-blue'
                      onChange={(e) => {

                        botChange(e.target.value)
                      }
                      }
                      name="bot"
                      value={bot}
                      style={{ fontWeight: 200 }}
                    >
                      <option value=''>{intl.formatMessage({ id: 'SELECT.BOT' })}</option>
                      {
                        bots.map((bot: any, index) => {
                          return (
                            <option key={index.toString()} value={bot._id}>{bot.displayName}</option>
                          )
                        })
                      }
                    </select>
                    {formikCreateScenario.touched.bot && formikCreateScenario.errors.bot && (
                      <div className='fv-plugins-message-container'>
                        <span className="text-danger">{formikCreateScenario.errors.bot}</span>
                      </div>
                    )}
                  </div>
                  <div className="mb-5">
                    <label className="form-label">{intl.formatMessage({ id: 'Name' })}</label>
                    <input
                      {...formikCreateScenario.getFieldProps('name')}
                      type="text"
                      className='form-control'
                      name='name'
                      value={name}
                      onChange={(e) => onChangeName(e.target.value)}
                      maxLength={30}
                      placeholder={intl.formatMessage({ id: 'Name of scenario' })} />
                    {formikCreateScenario.touched.name && formikCreateScenario.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <span className="text-danger">{formikCreateScenario.errors.name}</span>
                      </div>
                    )}
                  </div>
                  <div className="mb-5">
                    <label className="form-label">{intl.formatMessage({ id: 'Select Delivery Date' })}</label>
                    <select
                      {...formikCreateScenario.getFieldProps('deliveryDate')}
                      className='form-select border-light-blue text-light-blue'
                      onChange={(e) => onChangeDeliveryDate(e.target.value)}
                      name="deliveryDate"
                      value={deliveryDate}
                      style={{ fontWeight: 200 }}
                    >
                      <option value=''>{intl.formatMessage({ id: 'Select Delivery Date' })}</option>
                      {
                        Array.from(Array(31).keys()).map((item, index) => {
                          return (
                            <option key={index.toString()} value={item + 1}>{item + 1}</option>
                          )
                        })
                      }
                    </select>
                    {formikCreateScenario.touched.deliveryDate && formikCreateScenario.errors.deliveryDate && (
                      <div className='fv-plugins-message-container'>
                        <span className="text-danger">{formikCreateScenario.errors.deliveryDate}</span>
                      </div>
                    )}
                    <span className='text-muted'>{intl.formatMessage({ id: 'Users will receive messages every month on this date.' })}</span><br />
                    <span className='text-muted'>{intl.formatMessage({ id: 'Note: Based upon Months 29, 30, 31 dates users may not receive Messages' })}</span>
                  </div>
                  <div className="mb-5">
                    <label className="form-label">{intl.formatMessage({ id: 'Send to' })}</label>
                    <div className="mb-5 d-flex">
                      <div className="form-check form-check-custom  me-10">
                        <input className="form-check-input border-light-blue" type="radio" name='sendto' value="" checked={sendto == "users" ? true : false} onChange={() => sendtoCahnge("users")} />
                        <label className="form-check-label" >
                          {intl.formatMessage({ id: 'Select Users' })}
                        </label>
                      </div>
                      <div className="form-check form-check-custom  me-10">
                        <input className="form-check-input border-light-blue" type="radio" name='sendto' value="" checked={sendto == "group" ? true : false} onChange={() => sendtoCahnge("group")} />
                        <label className="form-check-label" >
                          {intl.formatMessage({ id: 'Select Group' })}
                        </label>
                      </div>
                    </div>
                    {
                      sendto == "users" ?
                        <>
                          <Select
                            {...formikCreateScenario.getFieldProps('selectedUsers')}
                            isMulti
                            options={users}
                            className="border-light-blue text-light-blue"
                            name="selectedUsers"
                            value={selectedUsers}
                            onChange={(values) => onChangeUsers(values)}
                            styles={{ control: (styles, state) => ({ ...styles, borderStyle: 'none !important', backgroundColor: "transparent" }) }}
                            placeholder={<div>{intl.formatMessage({ id: 'Select Users' })}</div>}
                          />
                          {formikCreateScenario.touched.selectedUsers && formikCreateScenario.errors.selectedUsers && (
                            <div className='fv-plugins-message-container'>
                              <span className="text-danger">{formikCreateScenario.errors.selectedUsers}</span>
                            </div>
                          )}
                        </>
                        :
                        <>
                          <select
                            {...formikCreateScenario.getFieldProps('selectedGroup')}
                            className='form-select border-light-blue text-light-blue'
                            onChange={(e) => onChangeGroup(e.target.value)}
                            value={selectedGroup}
                            name="selectedGroup"
                            style={{ fontWeight: 200 }}
                          >
                            <option value=''>{intl.formatMessage({ id: 'Select Group' })}</option>
                            {
                              groups.map((item, index) => {
                                return (
                                  <option key={index.toString()} value={item._id}>{item.name}</option>
                                )
                              })
                            }
                          </select>
                          {formikCreateScenario.touched.selectedGroup && formikCreateScenario.errors.selectedGroup && (
                            <div className='fv-plugins-message-container'>
                              <span className="text-danger">{formikCreateScenario.errors.selectedGroup}</span>
                            </div>
                          )}
                        </>

                    }

                  </div>
                  <div className="mb-5">
                    <label className="form-label">{intl.formatMessage({ id: 'Message' })}</label>
                    <div className="mb-5 d-flex">
                      <div className="form-check form-check-custom  me-10">
                        <input className="form-check-input border-light-blue" type="radio" name='messageType' checked={messageType == "message" ? true : false} value="" onChange={() => messageTypeChange("message")} />
                        <label className="form-check-label" >
                          {intl.formatMessage({ id: 'Type Message' })}
                        </label>
                      </div>
                      <div className="form-check form-check-custom  me-10">
                        <input className="form-check-input border-light-blue" type="radio" name='messageType' checked={messageType == "template" ? true : false} value="" onChange={() => messageTypeChange("template")} />
                        <label className="form-check-label" >
                          {intl.formatMessage({ id: 'Select Template' })}
                        </label>
                      </div>
                    </div>
                    {
                      messageType == "message" ?
                        <>
                          <textarea
                            {...formikCreateScenario.getFieldProps('selectedTemplate')}
                            className='from-control border-light-blue text-light-blue w-100'
                            rows={3}
                            placeholder={intl.formatMessage({ id: "Type your message..." })}
                            value={message}
                            onChange={(e) => onChangeMessage(e.target.value)}
                            name="message"
                          >
                          </textarea>
                          {formikCreateScenario.touched.message && formikCreateScenario.errors.message && (
                            <div className='fv-plugins-message-container'>
                              <span className="text-danger">{formikCreateScenario.errors.message}</span>
                            </div>
                          )}
                        </>
                        :
                        <>
                          <select
                            {...formikCreateScenario.getFieldProps('selectedTemplate')}
                            className='form-select border-light-blue text-light-blue'
                            onChange={(e) => { onChangeTemplate(e.target.value) }}
                            value={selectedTemplate}
                            name="selectedTemplate"
                            style={{ fontWeight: 200 }}
                          >
                            <option value=''>{intl.formatMessage({ id: 'Select Template' })}</option>
                            {
                              templates.map((item, index) => {
                                return (
                                  <option key={index.toString()} value={item._id}>{item.template.template_name}</option>
                                )
                              })
                            }
                          </select>
                          {formikCreateScenario.touched.selectedTemplate && formikCreateScenario.errors.selectedTemplate && (
                            <div className='fv-plugins-message-container'>
                              <span className="text-danger">{formikCreateScenario.errors.selectedTemplate}</span>
                            </div>
                          )}
                        </>

                    }

                  </div>
                  <div className="mb-5">
                    <label className="form-label">{intl.formatMessage({ id: 'Start' })}</label>
                    <div className="form-check form-switch form-check-custom form-check-solid">
                      <input className="form-check-input border-light-blue" type="checkbox" value="" onChange={(e) => { setStartScenario(e.target.checked) }} checked={startScenario} />
                      <label className="form-check-label">
                      {intl.formatMessage({ id: 'Start Scenario now onwards' })} {startScenario ? intl.formatMessage({ id: 'ON' })  : intl.formatMessage({ id: 'OFF' }) }
                      </label>
                    </div>
                  </div>
                  <div className="mb-5">
                    <button type="submit"
                      className="btn green-background text-light-blue"
                      disabled={formikCreateScenario.isSubmitting || !formikCreateScenario.isValid}>
                      {!createScenarioLoading && <span>{isEdit ? intl.formatMessage({ id: "Update Scenario" }) : intl.formatMessage({ id: "Create Scenario" })}</span>}
                      {createScenarioLoading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          {intl.formatMessage({ id: "WAIT" })}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </KTCardBody>
      </KTCard>
    </>
  )
}

const CreateScenarioWrapper: FC = () => {
  const intl = useIntl()
  let params = useParams();
  return (
    <>
      <PageTitle breadcrumbs={[]}>{params?.id ? intl.formatMessage({ id: 'UPDATE SCENARIO' }) : intl.formatMessage({ id: 'CREATE SCENARIO' })}</PageTitle>
      <CreateScenario />
    </>
  )
}

export { CreateScenarioWrapper }
