import { toAbsoluteUrl } from '../../_metronic/helpers'

export const languages = [
    {
      lang: 'en',
      name: 'English',
      flag: toAbsoluteUrl('/media/flags/united-states.svg'),
    },
    {
      lang: 'ja',
      name: '日本',
      flag: toAbsoluteUrl('/media/flags/japan.svg'),
    },
    // {
    //   lang: 'zh',
    //   name: 'Mandarin',
    //   flag: toAbsoluteUrl('/media/flags/china.svg'),
    // },
    // {
    //   lang: 'es',
    //   name: 'Spanish',
    //   flag: toAbsoluteUrl('/media/flags/spain.svg'),
    // },
    // {
    //   lang: 'de',
    //   name: 'German',
    //   flag: toAbsoluteUrl('/media/flags/germany.svg'),
    // },
    // {
    //   lang: 'fr',
    //   name: 'French',
    //   flag: toAbsoluteUrl('/media/flags/france.svg'),
    // },
  ]