/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { ChatInner } from './ChatInner';
import { get_bots, get_single_follower, get_group_details } from '../../utills/api_helper'
import {
  useParams
} from "react-router-dom";


const ChatList: FC = () => {
  let params = useParams();
  const intl = useIntl();
  const [details, setDetails] = useState<any>()
  const [groupDetail, setGroupDetail] = useState<any>()
  const [status, setStatus] = useState<Boolean>()
  const [displayName, setDisplayName] = useState("")


  useEffect(() => {
    get_botlist();

  }, [])
  function get_botlist(admin_id: any = null, search: string = "") {

    let filter = {};
    if (params.page == "user") {
      filter = { _id: params.id }
      get_single_follower(filter).then((res) => {
        if (res.data.status == "success") {
          setDetails(res.data.data);
          setDisplayName(res.data.data.displayName);
          setStatus(res.data.data.follower_mode ? true : false);
        }
      });
    }
    else if (params.page == "bot") {
      filter = { _id: params.id }
      get_bots(filter).then((res) => {
        if (res.data.status == "success") {
          setDetails(res.data.data[0]);
          setDisplayName(res.data.data[0].displayName)
          setStatus(res.data.data[0].status)
        }
      });
    }
    else if (params.page == "group") {
      filter = { _id: params.id }
      get_group_details(filter).then((res) => {
        if (res.data.status == "success") {
          setDetails(res.data.data[0]);
          setDisplayName(res.data.data[0].name)
          setStatus(res.data.data[0].is_active)
        }
      });
    }
  }
  return (
    <>
      {/* begin::Row */}
      <div className='flex-lg-row-fluid ms-lg-7 ms-xl-10'>
        <div className='card' id='kt_chat_messenger'>
          <div className='card-header' id='kt_chat_messenger_header'>
            <div className='card-title'>
              <div className='symbol-group symbol-hover'></div>
              <div className='d-flex justify-content-center flex-column me-3'>
                <a
                  href='#'
                  className='fs-4 fw-bolder text-light-blue me-1 mb-2 lh-1'
                >
                  {displayName}
                </a>
                    <div className='mb-0 lh-1'>
                             <span className={(status ? 'badge-success ' : 'badge-danger ') + 'badge badge-circle w-10px h-10px me-1'}></span>
                             <span className='fs-7 fw-bold text-gray-400'>{status ? intl.formatMessage({ id: 'ACTIVE' }) : intl.formatMessage({ id: 'INACTIVE' })}</span>
                    </div>
              </div>
            </div>
          </div>
          {
            details ?
              <ChatInner details={details} />
              :
              null
          }
        </div>
      </div>
    </>
  )
}

const ChatsWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.Chat' })}</PageTitle>
      <ChatList />
    </>
  )
}

export { ChatsWrapper }
