/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { KTCard, KTCardBody, KTSVG } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import ReactPaginate from 'react-paginate'
import moment from 'moment'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import Select from 'react-select'
import * as Yup from 'yup'
import clsx from 'clsx'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import {
  get_bots,
  get_user_groups,
  create_groups,
  remove_group,
  get_followers,
} from '../../utills/api_helper'
import { useAuth } from '../../modules/auth'
import { ErrorsPage } from '../../modules/errors/ErrorsPage'


const lang = localStorage.getItem('i18nConfig')?.slice(17, 19);


function Items({ currentItems, deleteGroupDisplay }) {
  const intl = useIntl();
  return (
    <>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead className='border-bottom-very-light-blue'>
            <tr className='text-start text-light-blue fw-bolder fs-7 text-uppercase gs-0'>
              <td>#</td>
              <td className='text-nowrap'>{intl.formatMessage({ id: 'NAME' })}</td>
              <td className='text-nowrap'>{intl.formatMessage({ id: 'MEMBERS' })}</td>
              <td className='text-nowrap'>{intl.formatMessage({ id: 'CREATED.ON' })}</td>
              <td className='text-nowrap'>{intl.formatMessage({ id: 'ACTION' })}</td>
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold text-light-blue'>
            {currentItems.length ? (
              currentItems &&
              currentItems.map((item, index) => (
                <tr key={index.toString()}>
                  <td>{index + 1}</td>
                  <td className='text-nowrap'>{item?.name}</td>
                  <td className='text-nowrap'>{item?.join_details?.length}</td>
                  <td className='text-nowrap'>{moment(item?.created_at).format('LL')}</td>
                  <td className='text-nowrap'>
                    <OverlayTrigger
                      delay={{ hide: 50, show: 50 }}
                      overlay={(props) => (
                        <Tooltip {...props}>{intl.formatMessage({ id: 'VIEW' })}</Tooltip>
                      )}
                      placement='top'
                    >
                      <Link
                        to={'/user-group/view/' + item.bot_id._id + '/' + item._id}
                        target='_blank'
                        type='button'
                        className='btn btn-icon btn-sm btn-bg-light green-background m-1'
                      >
                        <i className='bi bi-eye-fill text-light-blue'></i>
                      </Link>
                    </OverlayTrigger>
                    <OverlayTrigger
                      delay={{ hide: 50, show: 50 }}
                      overlay={(props) => (
                        <Tooltip {...props}>{intl.formatMessage({ id: 'PUSH.MSG' })}</Tooltip>
                      )}
                      placement='top'
                    >
                      <Link
                        to={'/chat/group/' + item._id}
                        type='button'
                        className='btn btn-icon btn-sm btn-bg-light green-background m-1'
                      >
                        {' '}
                        <i className='bi bi-chat-square-text-fill text-light-blue'></i>
                      </Link>
                    </OverlayTrigger>
                    <OverlayTrigger
                      delay={{ hide: 50, show: 50 }}
                      overlay={(props) => (
                        <Tooltip {...props}>{intl.formatMessage({ id: 'DEL.GROUP' })}</Tooltip>
                      )}
                      placement='top'
                    >
                      <button
                        type='button'
                        className='btn btn-icon btn-sm btn-bg-light btn-color-danger green-background m-1  '
                        onClick={() => deleteGroupDisplay(item)}
                      >
                        <i className='fa fa-trash'></i>
                      </button>
                    </OverlayTrigger>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {intl.formatMessage({ id: 'NO.RECORDS' })}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  )
}

function PaginatedItems({ items }) {
  const intl = useIntl()
  const { currentUser } = useAuth();
  const [bot, setBot] = useState<string | undefined>()
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [forcePage, setForcePage] = useState<number>(0)
  const [remountComponent, setRemountComponent] = useState(0)
  const [displayGroupModal, setDisplayGroupModal] = useState(false)
  const [displayDeleteModal, setDisplayDeleteModal] = useState(false)
  const [userGroupList, setUserGroupList] = useState([])
  const [userList, setUserList] = useState([])
  const [toDeleteGroup, setToDeleteGroup] = useState<any>({})
  const [selectedMembers, setSelectedMembers] = useState<any>([])
  const [totalSelected, setTotalSelected] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [refreshState, setRefreshState] = useState(0)
  const [groupName, setGroupName] = useState('')
  const [itemOffset, setItemOffset] = useState(0)
  const [options, setOptions] = useState([{ value: '', label: '' }])

  useEffect(() => {
    get_user_groups({ bot_id: bot }).then((res) => {
      setUserGroupList(res?.data?.data)
    })

    get_followers({ bot_id: bot }).then((res) => {
      setUserList(res.data.data)
      if (res.data.data) {
        let option: any = []
        res.data.data.map((admin, index) => {
          option.push({ value: admin._id, label: admin.displayName })
        })
        setOptions(option)
      }
    })
  }, [bot, refreshState])

  const endOffset = itemOffset + itemsPerPage
  const showingItemsText = intl.formatMessage({ id: "Showing results from [firstRow] to [lastRow]" }).replace("[firstRow]", "" + (itemOffset + 1)).replace("[lastRow]", "" + (endOffset <= userGroupList.length ? endOffset : userGroupList.length));

  // const showingItemsText = `Showing items from ${itemOffset + 1} to ${
  //   endOffset <= userGroupList.length ? endOffset : userGroupList.length
  // }`
  const currentItems = userGroupList.slice(itemOffset, endOffset)
  const pageCount = Math.ceil(userGroupList.length / itemsPerPage)

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % userGroupList.length
    setItemOffset(newOffset)
  }

  const handleGroupClose = () => {
    setDisplayGroupModal(false)
  }

  const handleDeleteGroupClose = () => {
    setDisplayDeleteModal(false)
  }

  const deleteGroup = async () => {
    const res = await remove_group({_id: toDeleteGroup._id._id , lang : lang})
    if (res.data.status == 'success') {
      setDisplayDeleteModal(false)
      setRefreshState(Math.random() * 100)
      toast(res.data.msg, { type: 'success' })
    } else {
      setDisplayDeleteModal(false)
      setRefreshState(Math.random() * 100)
      toast(res.data.msg, { type: 'error' })
    }
  }

  const deleteGroupDisplay = async (id) => {
    setToDeleteGroup({ _id: id, lang: lang })
    setDisplayDeleteModal(true)
  }

  async function onChangeGroupName(e) {
    setGroupName(e.target.value)
    formik_grpName.setFieldValue('groupName', e.target.value)
  }

  function onChangeSelectedMembers(opts, context) {
    let values: any = []
    opts.map((admin, index) => {
      values.push(admin.value)
    })
    setTotalSelected(values)
    setSelectedMembers(opts ? opts.map((opt) => opt) : [])

  }

  const createGroupSchema = Yup.object().shape({
    groupName: Yup.string()
      .required(intl.formatMessage({ id: 'REQUIRED' }))
      .max(30, intl.formatMessage({ id: 'CHARACTES.LIMIT' }))
  })

  const formik_grpName = useFormik({
    initialValues: {
      groupName: '',
    },
    validationSchema: createGroupSchema,
    onSubmit: async (values, { resetForm, setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const res: any = await create_groups({
          bot_id: bot,
          name: groupName,
          members: totalSelected,
          lang: lang,
        })
        if (res.data.status == 'success') {
          setSubmitting(false)
          setLoading(false)
          setRefreshState(Math.random() * 100)
          toast(res.data.msg, { type: 'success' })
          setGroupName("")
          setSelectedMembers([]);
          resetForm()
          const close_popUp = document.getElementById('close_group_modal') as HTMLInputElement
          close_popUp.click()
        } else {
          resetForm()
          setGroupName("")
          setSelectedMembers([]);
          toast(res.data.msg, { type: 'error' })
          const close_popUp = document.getElementById('close_group_modal') as HTMLInputElement
          close_popUp.click()
        }
      } catch (error: any) {
        resetForm()
        setGroupName("")
        setSelectedMembers([]);
        console.error(error)
        toast('Group creation failed', { type: 'error' })
        setSubmitting(false)
        setLoading(false)
        const close_popUp = document.getElementById('close_group_modal') as HTMLInputElement
        close_popUp.click()
      }
    },
  })

  return (
    <>
      <KTCard>
        <KTCardBody className='py-4'>
          <div className='border-0'>
            <div className='row'>
              <div className='col-lg-1'>
                <div className='col-lg-12 py-2'>
                  {/* setRemountComponent is used to reset selection of active pagination to 1 */}
                  <select
                    className='form-select border-light-blue text-light-blue'
                    onChange={(e) => {
                      setItemOffset(0)
                      setItemsPerPage(parseInt(e.target.value))
                      setForcePage(0)
                      setRemountComponent(Math.random())
                    }}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                  </select>
                </div>
              </div>
              {/* begin::Card toolbar */}
              <div className='col-lg-9'>
                <div className='row' >
                  <div className='col-lg-3 py-2'>
                    <select
                      className='form-select border-light-blue text-light-blue'
                      data-placeholder='Select Admin'
                      onChange={(e) => setBot(e.target.value)}
                      value={bot}
                      style={{ fontWeight: 200 }}
                    >
                      <option value=''>{intl.formatMessage({ id: 'SELECT.BOT' })}</option>
                      {items.map((bots: any, index) => {
                        return (
                          <option key={index.toString()} value={bots._id}>
                            {bots.displayName}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                  <div className='col-lg-3 py-2'>
                    <button
                      type='button'
                      className='btn dark-green-background text-light-blue ms-3'
                      onClick={() => {
                        setBot('')
                      }}
                    >
                      {intl.formatMessage({ id: 'RESET' })}
                    </button>
                  </div>
                </div>
              </div>
              <div className='col-lg-2 py-2'>
                <button
                  type='button'
                  className='btn dark-green-background text-light-blue ms-3 float-end'
                  onClick={() => setDisplayGroupModal(true)}
                >
                  {intl.formatMessage({ id: 'ADD.GROUP' })}
                  <i className="fas fa-plus text-light-blue ms-2"></i>
                </button>
              </div>
            </div>
          </div>
        </KTCardBody>
      </KTCard>
      <KTCard className='mt-3'>
        <KTCardBody className='py-4'>
          <Items currentItems={currentItems} deleteGroupDisplay={deleteGroupDisplay} />
          <div className='row' key={remountComponent}>
            <div className='col-lg-6 d-flex align-items-center text-light-blue'>{showingItemsText}</div>
            <ReactPaginate
              nextLabel={intl.formatMessage({ id: "next >" })}
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel={intl.formatMessage({ id: "< previous" })}
              pageClassName='page-item'
              pageLinkClassName='page-link text-light-blue'
              previousClassName='page-item text-light-blue'
              previousLinkClassName='page-link text-light-blue'
              nextClassName='page-item'
              nextLinkClassName='page-link text-light-blue'
              breakLabel='...'
              breakClassName='page-item text-light-blue'
              breakLinkClassName='page-link text-light-blue'
              containerClassName='col-lg-6 pagination justify-content-end'
              activeClassName='green-background rounded'
              renderOnZeroPageCount={() => null}
              forcePage={forcePage}
            />
          </div>
        </KTCardBody>
      </KTCard>

      {/* DELETE GROUP  */}
      <Modal
        show={displayDeleteModal}
        onHide={handleDeleteGroupClose}
        dialogClassName='modal-90w'
        aria-labelledby='example-custom-modal-styling-title'
      >
        <Modal.Header>
          <Modal.Title id='example-custom-modal-styling-title'>
            <b className='text-light-blue'>
              {intl.formatMessage({ id: 'CONFIRM.DEL' })} {groupName}
            </b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6 className='text-light-blue'>{intl.formatMessage({ id: 'Are you sure you want to delete "[groupName]" group?' })
            .replace("[groupName]", "" + toDeleteGroup._id?.name)}</h6>
        </Modal.Body>
        <Modal.Footer>
          <button
          type='button'
            className='btn btn-sm '
            id='close_group_modal'
            onClick={handleDeleteGroupClose}
          >
            {intl.formatMessage({ id: 'CLOSE' })}
          </button>
          <button
            className='btn dark-green-background btn-sm'
            id='close_group_modal'
            onClick={() => deleteGroup()}
          >
            {intl.formatMessage({ id: 'DEL.GROUP' })}
          </button>
        </Modal.Footer>
      </Modal>

      {/* ADD GROUP MODAL */}
      <Modal
        size='lg'
        show={displayGroupModal}
        onHide={handleGroupClose}
        dialogClassName='modal-90w'
        aria-labelledby='example-custom-modal-styling-title'
      >
        <form
          noValidate
          onSubmit={formik_grpName.handleSubmit}>
          <Modal.Header>
            <Modal.Title id='example-custom-modal-styling-title' className='text-light-blue'>
              <b>{intl.formatMessage({ id: 'USER.DETAILS' })}</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='show-grid'>
            <div className='row'>
              <div className='col-md-12 mb-3'>
                <label className='form-label text-light-blue mb-3'>{intl.formatMessage({ id: 'SELECT.BOT' })}</label>
                <select
                  id='botId'
                  name='botId'
                  className='form-select border-light-blue text-light-blue mb-5'
                  onChange={(e) => { setBot(e.target.value); setSelectedMembers([]) }}
                  value={bot}
                  style={{ fontWeight: 200 }}
                >
                  <option value=''>{intl.formatMessage({ id: 'SELECT.BOT' })}</option>
                  {items.map((bots: any, index) => {
                    return (
                      <option key={index.toString()} value={bots._id}>
                        {bots.displayName}
                      </option>
                    )
                  })}
                </select>
                <label className='form-label text-light-blue mb-3'>
                  {intl.formatMessage({ id: 'SELECT.MEMBERS' })}
                </label>
                <Select
                  isMulti
                  value={selectedMembers}
                  placeholder={<div>{intl.formatMessage({ id: 'SELECT.MEMBERS' })}</div>}
                  onChange={onChangeSelectedMembers}
                  options={options}
                  className="react-select-border-light-blue text-light-blue"
                  styles={{control: (styles, state) => ({ ...styles, borderStyle: 'none !important', backgroundColor: "transparent" })}}
                />
                <label className='form-label text-light-blue mt-5'>{intl.formatMessage({ id: 'GROUP.NAME' })}</label>
                <input
                  {...formik_grpName.getFieldProps('groupName')}
                  className={clsx(
                    'form-control form-control-lg border-light-blue text-light-blue',
                    {
                      'is-invalid':
                        formik_grpName.touched.groupName && formik_grpName.errors.groupName,
                    },
                    {
                      'is-valid':
                        formik_grpName.touched.groupName && !formik_grpName.errors.groupName,
                    }
                  )}
                  name='groupName'
                  type='text'
                  maxLength={30}
                  onChange={(e) => onChangeGroupName(e)}
                  value={groupName}
                />
                {formik_grpName.touched.groupName && formik_grpName.errors.groupName && (
                  <div className='fv-plugins-message-container'>
                    <span className="text-danger">{formik_grpName.errors.groupName}</span>
                  </div>
                )}
              </div>

            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className='btn text-light-blue' id='close_group_modal' onClick={handleGroupClose}>
              {intl.formatMessage({ id: 'CLOSE' })}
            </button>
            <button type='submit' className='btn dark-green-background text-light-blue'>
              {intl.formatMessage({ id: 'CREATE' })}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}

const GroupList: FC = () => {
  const [botList, setBotList] = useState([])
  const { currentUser } = useAuth();

  useEffect(() => {
    moment.locale(lang)
    let filter: any = {}
    if (currentUser?.role === 'superAdmin') {
      filter = {}
    } else {
      filter = { admin_id: currentUser?._id }
    }
    get_bots(filter).then((res) => {
      setBotList(res?.data?.data)
    })

  }, [])

  return (
    <PaginatedItems items={botList} />
  )
}

const GroupsWrapper: FC = () => {
  const intl = useIntl();
  const { currentUser } = useAuth()

  return (
    <>
    {currentUser?.role === 'superAdmin' ? (
      <>
        <ErrorsPage />
      </>
    ) : (
      <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.GROUPS' })}</PageTitle>
        <GroupList />
      </>
    )}
  </>
  )
}

export { GroupsWrapper }
