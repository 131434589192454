/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'
import { toAbsoluteUrl } from '../../../helpers'
import { useLang, setLanguage } from '../../../i18n/Metronici18n'
import { useIntl } from 'react-intl'
import { languages } from './../../../../app/utills/constants'


// const languages = [
//   {
//     lang: 'en',
//     name: 'English',
//     flag: toAbsoluteUrl('/media/flags/united-states.svg'),
//   },
//   {
//     lang: 'ja',
//     name: '日本',
//     flag: toAbsoluteUrl('/media/flags/japan.svg'),
//   },
//   // {
//   //   lang: 'zh',
//   //   name: 'Mandarin',
//   //   flag: toAbsoluteUrl('/media/flags/china.svg'),
//   // },
//   // {
//   //   lang: 'es',
//   //   name: 'Spanish',
//   //   flag: toAbsoluteUrl('/media/flags/spain.svg'),
//   // },
//   // {
//   //   lang: 'de',
//   //   name: 'German',
//   //   flag: toAbsoluteUrl('/media/flags/germany.svg'),
//   // },
//   // {
//   //   lang: 'fr',
//   //   name: 'French',
//   //   flag: toAbsoluteUrl('/media/flags/france.svg'),
//   // },
// ]

const Languages: FC = () => {
  const intl = useIntl();
  const lang = useLang();
  const currentLanguage = languages.find((x) => x.lang === lang)
  return (


    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      {languages.map((l) => (
        <div
          className='menu-item px-3'
          key={l.lang}
          onClick={() => {
            setLanguage(l.lang)
          }}
        >
          <a
            href='#'
            className={clsx('menu-link d-flex px-5 text-light-blue', { active: l.lang === currentLanguage?.lang })}
          >
            <span className='symbol symbol-20px me-4'>
              <img className='rounded-1' src={l.flag} alt={l.lang} />
            </span>
            {l.name}
          </a>
        </div>
      ))}
    </div>
  )
}

export { Languages }
