import axios from 'axios'
import { AuthModel, UserModel } from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/api/users/getUserByToken`
export const LOGIN_URL = `${API_URL}/api/users/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/api/users/forgotPassword`
export const RESET_PASSWORD_URL = `${API_URL}/api/users/setNewPassword`


// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string , lang : string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
    lang
  })
}

export function resetPassword(key: string ,password : string, lang : string) {
  return axios.post<{ result: boolean }>(RESET_PASSWORD_URL, {
    key,
    password,
    lang
  })
}

export async function getUserByToken(token: string) {
  const user = await axios.post(GET_USER_BY_ACCESSTOKEN_URL,{})
  return {
    data: user.data.data
  };
}
