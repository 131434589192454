/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { KTCard, KTCardBody, KTSVG } from '../../../_metronic/helpers'
import { get_admins, create_admin, edit_admin, resend_verification, change_admin_status, get_features } from '../../utills/api_helper'
import ReactPaginate from 'react-paginate';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { toast } from 'react-toastify';
import Select from 'react-select';
import moment from 'moment';
import 'moment/locale/ja';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useAuth } from '../../../app/modules/auth'
import { ErrorsPage } from '../../modules/errors/ErrorsPage'
import { debounce } from 'lodash'

const lang: any = localStorage.getItem('i18nConfig')?.slice(17, 19);

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'transparent' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      color: '#FFF',
    };
  },
};



function Items({ currentItems, itemOffset, refreshAdminList }) {

  const initialValues = {
    email: '',
    name: '',
  }
  const [currentUser, setCurrentUser] = useState<{ admin_id: string, email: string, name: string }>();
  const [id, setId] = useState("");
  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [loading, setLoading] = useState(false);
  const [features, setFeatures] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState<string[]>([]);
  const [editModal, setEditModal] = useState(false);
  const intl = useIntl()


  useEffect(() => {
    let filter = {};
    get_features(filter).then((res) => {
      if (res.data.status == "success") {
        setFeatures(res.data.data);
      }
    });
  }, [])

  async function editUser(user) {
    setEditModal(true);
    // console.log("editUser user ", user)
    const { email, name, _id, features } = user;
    setId(_id)
    setCurrentUser({ admin_id: user._id, email: user.email, name: user.name });
    setemail(email);
    setname(name);
    let localfeatures: any = [];
    features.map((item, index) => {
      localfeatures.push(item._id);
    });
    setSelectedFeatures(localfeatures);
    formik_edit.setFieldValue("email", user.email);
    formik_edit.setFieldValue("name", user.name);

  }

  async function onChangeName(event) {
    setname(event.target.value)
    formik_edit.setFieldValue("name", event.target.value)
  }

  async function onChangeEmail(event) {
    setemail(event.target.value)
    formik_edit.setFieldValue("email", event.target.value)
  }

  async function resendVerification(user) {
    await resend_verification({ admin_id: user._id, lang: lang }).then((res) => {
      toast(res.data.msg, { type: "success" })
    }).catch(err => {
      console.log(err)
      toast(err.data.msg, { type: "error" })
    });
  }

  async function changeAdminStats(adminId, status) {
    await change_admin_status({ admin_id: adminId, is_active: status, lang: lang }).then((res) => {
      toast(res.data.msg, { type: "success" })
      //To Update userList status using useEffect
      refreshAdminList(Math.random() * 150)
    }).catch(err => {
      console.log(err)
      toast(err.data.msg, { type: "error" })
    })
  }

  function onChangeSelectFeature(feature) {
    // console.log("onChangeSelectFeature selectedFeatures ", selectedFeatures);
    // console.log(" onChangeSelectFeature feature ", feature);
    let localsetSelectedFeatures: any = [...selectedFeatures];
    if (localsetSelectedFeatures.includes(feature)) {
      localsetSelectedFeatures = localsetSelectedFeatures.filter(e => e !== feature);
    } else {
      localsetSelectedFeatures.push(feature);
    }
    setSelectedFeatures(localsetSelectedFeatures);
  }


  const createUserSchema = Yup.object().shape({
    email: Yup.string()
      .required(intl.formatMessage({ id: 'EMAIL.REQUIRED' }))
      .email(intl.formatMessage({ id: 'EMAIL.VALIDATION' })),
    name: Yup.string()
      .required(intl.formatMessage({ id: 'NAME.REQUIRED' }))
      .min(3, intl.formatMessage({ id: 'AUTH.VALIDATION.MIN_LENGTH' }).replace("{name}}", intl.formatMessage({ id: 'NAME' }) + "").replace("{{min}", "3"))
      .max(30, intl.formatMessage({ id: 'CHARACTES.LIMIT' })),
  })

  const formik_edit = useFormik({
    initialValues,
    validationSchema: createUserSchema,
    onSubmit: async (values, { resetForm, setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const res: any = await edit_admin({ admin_id: id, name: values.name, email: values.email, features: selectedFeatures, lang: lang })
        if (res.data.status == "success") {
          setSubmitting(false)
          setLoading(false)
          toast(res.data.msg, { type: "success" })
          resetForm()
          setEditModal(false);
          refreshAdminList(Math.random() * 150)
        } else {
          setSubmitting(false)
          setLoading(false)
          toast(res.data.msg, { type: "error" })
        }
      } catch (error: any) {
        console.error(error)
        toast(intl.formatMessage({ id: 'USER.CREATE.FAIL' }), { type: "error" })
        setSubmitting(false)
        setLoading(false)
      }
    }
  })


  return (
    <>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead className='border-bottom-very-light-blue'>
            <tr className='text-start text-light-blue fw-bolder fs-7 text-uppercase gs-0 align-items-center justify-content-center'>
              <td>#</td>
              <td className='text-nowrap'>{intl.formatMessage({ id: 'NAME' })}</td>
              <td className='text-nowrap'>{intl.formatMessage({ id: 'EMAIL' })}</td>
              <td className='text-nowrap'>{intl.formatMessage({ id: 'STATUS' })}</td>
              <td className='text-nowrap'>{intl.formatMessage({ id: 'CREATED.ON' })}</td>
              <td >{intl.formatMessage({ id: 'ACTION' })}</td>
            </tr>
          </thead>
          <tbody className='text-light-blue fw-bold'>
            {currentItems.length ?
              currentItems &&
              currentItems.map((item, index) => (
                <tr key={index.toString()}>
                  <td>{itemOffset + index + 1}</td>
                  <td className='text-nowrap'>{item.name}</td>
                  <td className='text-nowrap'>{item.email}</td>
                  <td className='text-nowrap'>{item.is_active === true ? intl.formatMessage({ id: 'ACTIVE' }) : intl.formatMessage({ id: 'INACTIVE' })}</td>
                  <td className='text-nowrap'>{moment(item.created_at).format('LL')}</td>
                  <td className='text-nowrap'>
                    <OverlayTrigger
                      delay={{ hide: 50, show: 50 }}
                      overlay={(props) => (
                        <Tooltip {...props}>
                          {intl.formatMessage({ id: 'EDIT.USER' })}
                        </Tooltip>
                      )}
                      placement="top">
                      <button className='btn btn-icon btn-fill btn-sm btn-bg-light btn-active-color-primary m-1 green-background' onClick={() => editUser(item)}><i className="bi bi-pencil-square text-light-blue"></i></button>
                    </OverlayTrigger>
                    <OverlayTrigger
                      delay={{ hide: 50, show: 50 }}
                      overlay={(props) => (
                        <Tooltip {...props}>
                          {intl.formatMessage({ id: 'VERIFICATION' })}
                        </Tooltip>
                      )}
                      placement="top">

                      <button type='button' className='btn btn-icon btn-sm btn-bg-light btn-active-color-warning m-1 green-background' onClick={() => resendVerification(item)}><i className="bi bi-shield-check text-light-blue"></i></button>
                    </OverlayTrigger>
                    {item.is_active === true ? (
                      <OverlayTrigger
                        delay={{ hide: 50, show: 50 }}
                        overlay={(props) => (
                          <Tooltip {...props}>
                            {intl.formatMessage({ id: 'DEACTIVATE' })}
                          </Tooltip>
                        )}
                        placement="top">

                        <button type='button' className='btn btn-icon btn-sm btn-bg-light btn-color-danger m-1 green-background' onClick={() => changeAdminStats(item._id, false)} style={{ width: 'auto', padding: '5px' }}>{intl.formatMessage({ id: 'DEACTIVATE' })}</button>
                      </OverlayTrigger>
                    ) : (
                      <OverlayTrigger
                        delay={{ hide: 50, show: 50 }}
                        overlay={(props) => (
                          <Tooltip {...props}>
                            {intl.formatMessage({ id: 'ACTIVATE' })}
                          </Tooltip>
                        )}
                        placement="top">
                        <button type='button' className='btn btn-icon btn-sm btn-bg-light btn-color-success m-1 green-background' onClick={() => changeAdminStats(item._id, true)} style={{ width: 'auto', padding: '5px' }}>{intl.formatMessage({ id: 'ACTIVATE' })}</button>
                      </OverlayTrigger>
                    )}
                  </td>
                </tr>
              ))
              : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      {intl.formatMessage({ id: 'NO.RECORDS' })}
                    </div>
                  </td>
                </tr>
              )}
          </tbody>
        </table>
      </div>

      <Modal
        show={editModal}
        size="lg">

        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_edit_form'
          onSubmit={formik_edit.handleSubmit}
        >
          <Modal.Header >
            <Modal.Title className='text-light-blue'>{intl.formatMessage({ id: 'EDIT.USER' })}</Modal.Title>
          </Modal.Header>
          <Modal.Body className='text-light-blue'>
            <div>
              <div className='row'>
                <div className='col-md-12'>
                  <label className="form-label text-light-blue">{intl.formatMessage({ id: 'EMAIL' })}</label>
                  <input
                    {...formik_edit.getFieldProps('email')}
                    className={clsx(
                      'form-control form-control-lg ',
                      { 'is-invalid': formik_edit.touched.email && formik_edit.errors.email },
                      {
                        'is-valid': formik_edit.touched.email && !formik_edit.errors.email,
                      }
                    )}
                    type="email"
                    name="email"
                    value={email ? email : ""}
                    onChange={(e) => {
                      onChangeEmail(e);
                    }}
                    readOnly
                  />
                  {formik_edit.touched.email && formik_edit.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <span className="text-danger">{formik_edit.errors.email}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mt-2'>
              <div className='col-md-12'>
                <label className="form-label text-light-blue">{intl.formatMessage({ id: 'NAME' })}</label>
                <input
                  {...formik_edit.getFieldProps('name')}
                  className={clsx(
                    'form-control form-control-lg ',
                    { 'is-invalid': formik_edit.touched.name && formik_edit.errors.name },
                    {
                      'is-valid': formik_edit.touched.name && !formik_edit.errors.name,
                    }
                  )}
                  name='name'
                  maxLength={30}
                  value={name ? name : ""}
                  onChange={(e) => {
                    onChangeName(e);
                  }}
                ></input>
                {formik_edit.touched.name && formik_edit.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <span className="text-danger">{formik_edit.errors.name}</span>
                  </div>
                )}
              </div>
            </div>
            <div className='row mt-4'>
              <div className='col-md-12'>
                <label className="form-label text-light-blue">{intl.formatMessage({ id: 'Features' })}</label>
                {
                  features.map((item: any, index) => {
                    return (
                      <div key={index.toString()} className="form-check form-check-custom form-check-solid mt-2">
                        <input className="form-check-input" type="checkbox" checked={selectedFeatures.includes(item._id)} onChange={() => onChangeSelectFeature(item._id)} />
                        <label className="form-check-label">
                          {item.name}
                        </label>
                      </div>
                    )
                  })
                }

              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <button
              type='button'
              className="btn text-light-blue"
              onClick={() => setEditModal(false)}
            >
              {intl.formatMessage({ id: 'CLOSE' })}
            </button>
            <button type="submit" className="btn dark-green-background">
              {!loading && <span className='indicator-label text-light-blue'>{intl.formatMessage({ id: 'EDIT.USER' })}</span>}
              {loading && (
                <span className='indicator-progress text-light-blue' style={{ display: 'block' }}>
                  {intl.formatMessage({ id: 'WAIT' })}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}


function PaginatedItems() {

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [forcePage, setForcePage] = useState<number>(0)
  const [remountComponent, setRemountComponent] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [options, setOptions] = useState([{ value: "", label: "" }]);
  const [userListUpdated, setUserListUpdated] = useState<number>(0);
  const [selectedMember, setSelectedMember] = useState<any>();
  const [admin, setAdmin] = useState<any>();
  const [features, setFeatures] = useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [search, setSearch] = useState<string>("");
  const [addModal, setAddModal] = useState(false);
  const [addAdminEmail, setAddAdminEmail] = useState("");
  const [addAdminName, setAddAdminName] = useState("");
  const intl = useIntl();

  useEffect(() => {
    moment.locale(lang);

    // if (selectedMember !== "" || selectedMember !== null || selectedMember !== undefined) {
    //   filter = { email: selectedMember }
    // } else {
    //   filter = {}
    // }
    get_admin_list(null, search)


  }, [userListUpdated])

  function get_admin_list(admin_id = null, search = "") {
    let filter = { admin_id: admin_id, admin_name: search, list_type: "admin" }

    get_admins(filter).then((res) => {
      setUserList(res?.data?.data);
      let option: any = [];
      res.data.data.map((admin, index) => {
        option.push({ value: admin._id, label: admin.email })
      })
      setOptions(option);
    });
  }

  useEffect(() => {
    let filter = {};
    get_features(filter).then((res) => {
      if (res.data.status == "success") {
        setFeatures(res.data.data);
      }
    });
  }, [])

  function searchChange(text) {
    setSearch(text);
    if (text === "") {
      get_admin_list(null, "");
    }
  }

  const searchQuery = useCallback(debounce(() => {
    get_admin_list(null, search)
  }, 500), [search]);

  useEffect(() => {
    searchQuery();
    // Cancel the debounce on useEffect cleanup.
    return searchQuery.cancel;
  }, [search, searchQuery]);

  function onChangeSelectFeature(feature) {
    let localsetSelectedFeatures: any = [...selectedFeatures];
    if (localsetSelectedFeatures.includes(feature)) {
      localsetSelectedFeatures = localsetSelectedFeatures.filter(e => e !== feature);
    } else {
      localsetSelectedFeatures.push(feature);
    }
    setSelectedFeatures(localsetSelectedFeatures);
    // console.log("onChangeSelectFeature selectedFeatures ", localsetSelectedFeatures);
  }

  function refreshAdminList(val) {
    setUserListUpdated(val)
  }


  function onChangeSelectedMember(admin) {
    setSelectedMember(admin.label);
    setAdmin(admin);
    refreshAdminList(Math.random() * 100);
  }


  const initialValues = {
    email: '',
    name: '',
  }

  const createUserSchema = Yup.object().shape({
    email: Yup.string()
      .required(intl.formatMessage({ id: 'EMAIL.REQUIRED' }))
      .email(intl.formatMessage({ id: 'EMAIL.VALIDATION' })),
    name: Yup.string()
      .required(intl.formatMessage({ id: 'NAME.REQUIRED' }))
      .min(3, intl.formatMessage({ id: 'AUTH.VALIDATION.MIN_LENGTH' }).replace("{name}}", intl.formatMessage({ id: 'NAME' }) + "").replace("{{min}", "3"))
      .max(30, intl.formatMessage({ id: 'CHARACTES.LIMIT' })),
  })

  const formik = useFormik({
    initialValues,
    validationSchema: createUserSchema,
    onSubmit: async (values, { resetForm, setStatus, setSubmitting }) => {
      setLoading(true)
      // console.log("onSubmit selectedFeatures ", selectedFeatures);
      try {
        const res: any = await create_admin({ name: values.name, email: values.email, features: selectedFeatures, lang: lang })
        if (res.data.status == "success") {
          setSubmitting(false)
          setLoading(false)
          toast(res.data.msg, { type: "success" })
          resetForm()
          setAddModal(false)
          // const close_popUp = document.getElementById('create_user_button') as HTMLInputElement
          // close_popUp.click()
          let newAdmins: any = userList;
          newAdmins.unshift(res.data.data)
          setUserList(newAdmins)
          resetAddForm()
        } else {
          setSubmitting(false)
          toast(res.data.msg, { type: "error" })
          setLoading(false)
          // const close_popUp = document.getElementById('create_user_button') as HTMLInputElement
          // close_popUp.click()
        }
      } catch (error: any) {
        console.error(error)
        toast("User creation failed", { type: "error" })
        // resetForm()
        setSubmitting(false)
        setLoading(false)
        // const close_popUp = document.getElementById('create_user_button') as HTMLInputElement
        // close_popUp.click()
      }
    }
  })

  function resetAddForm(){
    console.log("resetAddForm called");
    setAddAdminEmail("");
    setAddAdminName("");
    formik.setFieldValue("email", "");
    formik.setFieldValue("name", "");
    setSelectedFeatures([]);
    formik.resetForm();
  }

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage; //10
  const showingItemsText = intl.formatMessage({ id: "Showing results from [firstRow] to [lastRow]" }).replace("[firstRow]", "" + (itemOffset + 1)).replace("[lastRow]", "" + (endOffset <= userList.length ? endOffset : userList.length));

  // const showingItemsText = `Showing items from ${itemOffset + 1} to ${endOffset <= userList.length ? endOffset : userList.length}`;
  // console.log(`Loading items from ${itemOffset} to ${endOffset} item.length ${items.length}`);
  const currentItems = userList.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(userList.length / itemsPerPage);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % userList.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      <KTCard>
        <KTCardBody className='py-4'>
          <div className='border-0' >
            <div className='row'>
              <div className='col-lg-1'>
                <div className='col-lg-12 py-2'>
                  {/* setRemountComponent is used to reset selection of active pagination to 1 */}
                  <select className='form-select border-light-blue text-light-blue' onChange={(e) => { setItemOffset(0); setItemsPerPage(parseInt(e.target.value)); setForcePage(0); setRemountComponent(Math.random()); }}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                  </select>
                </div>
              </div>
              <div className='col-lg-4 py-2 '>
                <input type="text" value={search} onChange={(e) => searchChange(e.target.value)} className='form-control border-light-blue text-light-blue placeholder-light-blue' placeholder={intl.formatMessage({ id: "Serach by name" })} style={{ fontWeight: 200 }} />
                {/* <Select
                  value={admin}
                  onChange={onChangeSelectedMember}
                  placeholder={<div>{intl.formatMessage({ id: 'SELECT.ADMIN' })}</div>}
                  options={options}
                  className="react-select-border-light-blue text-light-blue"
                  styles={{ control: (styles, state) => ({ ...styles, borderStyle: 'none !important', backgroundColor: "transparent" }) }}
                /> */}
              </div>
              <div className='col-lg-4 py-2'>
                <button
                  type='button'
                  className='btn border-light-blue text-light-blue fw-bold me-2 px-6'
                  onClick={() => {
                    searchChange('');
                    // setSelectedMember('')
                    refreshAdminList(Math.random() * 100);
                  }}
                >
                  {intl.formatMessage({ id: 'RESET' })}
                </button>
              </div>
              <div className='col-lg-3 py-2'>
                {/* begin::Add user */}
                <button type='button' className='btn dark-green-background text-light-blue ms-4 float-end' onClick={() => { setAddModal(true);}} >
                  {intl.formatMessage({ id: 'ADD.ADMIN' })}
                  <i className="fas fa-plus text-light-blue ms-2"></i>
                </button>
                {/* end::Add user */}

              </div>
            </div>
            {/* end::Card toolbar */}
          </div>
        </KTCardBody>
      </KTCard>
      <KTCard className='mt-3'>
        <KTCardBody className='py-4'>
          <Items currentItems={currentItems} itemOffset={itemOffset} refreshAdminList={refreshAdminList} />
          <div className='row' key={remountComponent}>

            <div className='col-lg-6 d-flex text-light-blue align-items-center'>{showingItemsText}</div>
            <ReactPaginate
              nextLabel={intl.formatMessage({ id: "next >" })}
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel={intl.formatMessage({ id: "< previous" })}
              pageClassName="page-item"
              pageLinkClassName="page-link text-light-blue"
              previousClassName="page-item"
              previousLinkClassName="page-link text-light-blue"
              nextClassName="page-item"
              nextLinkClassName="page-link text-light-blue"
              breakLabel="..."
              breakClassName="page-item text-light-blue"
              breakLinkClassName="page-link text-light-blue"
              containerClassName="col-lg-6 pagination justify-content-end"
              activeClassName="green-background rounded"
              renderOnZeroPageCount={() => null}
              forcePage={forcePage}
            />
          </div>
        </KTCardBody>
      </KTCard>
      {/* Add User Modal */}
      <Modal
        show={addModal}
        onHide={() => resetAddForm()}
        size="lg">

        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >

          <Modal.Header >
            <Modal.Title className='text-light-blue'>{intl.formatMessage({ id: 'ADD.USER' })}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className='row'>
                <div className='col-md-12'>
                  <label className="form-label text-light-blue">{intl.formatMessage({ id: 'EMAIL' })}</label>
                  <input
                    {...formik.getFieldProps('email')}
                    className="form-control form-control-lg"
                    type="email"
                    name="email" 
                    onChange={(e) => {setAddAdminEmail(e.target.value); formik.setFieldValue("email", e.target.value)}}
                    value={addAdminEmail}/>
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <span className="text-danger">{formik.errors.email}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className='row mt-4'>
              <div className='col-md-12'>
                <label className="form-label text-light-blue">{intl.formatMessage({ id: 'NAME' })}</label>
                <input
                  {...formik.getFieldProps('name')}
                  className="form-control form-control-lg"
                  name='name'
                  maxLength={30}
                  value={addAdminName}
                  onChange={(e) => {
                    setAddAdminName(e.target.value); 
                    formik.setFieldValue("name", e.target.value)
                  }}
                ></input>
                {formik.touched.name && formik.errors.name && (
                  <div className='fv-plugins-message-container'>
                    <span className="text-danger">{formik.errors.name}</span>
                  </div>
                )}
              </div>
            </div>
            <div className='row mt-4'>
              <div className='col-md-12'>
                <label className="form-label text-light-blue">{intl.formatMessage({ id: 'Features' })}</label>
                {
                  features.map((item: any, index) => {
                    return (
                      <div key={index.toString()} className="form-check form-check-custom form-check-solid mt-2">
                        <input className="form-check-input" type="checkbox" value="" onChange={() => onChangeSelectFeature(item._id)} />
                        <label className="form-check-label">
                          {item.name}
                        </label>
                      </div>
                    )
                  })
                }

              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn text-light-blue"
              onClick={() => {setAddModal(false); resetAddForm()}}
            >
              {intl.formatMessage({ id: 'CLOSE' })}
            </button>
            <button type="submit" className="btn dark-green-background">
              {!loading && <span className='indicator-label text-light-blue'>{intl.formatMessage({ id: 'CREATE.USER' })}</span>}
              {loading && (
                <span className='indicator-progress text-light-blue' style={{ display: 'block' }}>
                  {intl.formatMessage({ id: 'WAIT' })}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}


const AdminList: FC = () => {
  return (
    <PaginatedItems />
  )
}

const AdminsWrapper: FC = () => {
  const intl = useIntl()
  const { currentUser } = useAuth()

  return (
    <>
      {currentUser?.role !== 'superAdmin' ? (
        <>
          <ErrorsPage />
        </>
      ) : (
        <>
          <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.ADMINS' })}</PageTitle>
          <AdminList />
        </>
      )}
    </>
  )
}

export { AdminsWrapper }
