/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { KTCard, KTCardBody, KTSVG } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import Select from 'react-select';
import { get_template_list, get_admins, delete_template } from '../../utills/api_helper'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useParams } from "react-router-dom";
import { useAuth } from '../../modules/auth';
import moment from 'moment';


const lang = localStorage.getItem('i18nConfig')?.slice(17, 19);


const MessageTemplateList: FC = () => {

  return (
    <PaginatedItems />
  )
}

function PaginatedItems() {
  const intl = useIntl()
  let params = useParams();
  const { currentUser } = useAuth();
  const [details, setDetails] = useState<any>([]);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [forcePage, setForcePage] = useState<number>(0)
  const [remountComponent, setRemountComponent] = useState(0);
  const [selectedMember, setSelectedMember] = useState<any>();
  const [admin, setAdmin] = useState("");
  const [options, setOptions] = useState([{ value: "", label: "" }]);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    moment.locale(lang)
    getTemplatesByAdmin();

    if (currentUser?.role === 'superAdmin') {
      get_admins({}).then((res) => {
        let option: any = [];
        res.data.data.map((admin, index) => {
          option.push({ value: admin._id, label: admin.email })
        })
        setOptions(option);
      });
    }
  }, [])

  function getTemplatesByAdmin(admin = "", type = "template") {
    let filter: any = {}

    if (currentUser?.role === 'superAdmin') {
      filter = { admin_id: admin, type: type }
    } else {
      filter = { admin_id: currentUser?._id, type: type }
    }
    get_template_list(filter).then((res) => {
      setDetails(res?.data?.data);
    });
  }

  const endOffset = itemOffset + itemsPerPage;
  const showingItemsText = intl.formatMessage({ id: "Showing results from [firstRow] to [lastRow]" }).replace("[firstRow]", "" + (itemOffset + 1)).replace("[lastRow]", "" + (endOffset <= details.length ? endOffset : details.length));

  // const showingItemsText = `Showing items from ${itemOffset + 1} to ${endOffset <= details?.length ? endOffset : details?.length}`;
  const currentItems = details?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(details?.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % details?.length;
    setItemOffset(newOffset);
  };


  function onChangeSelectedMember(admin) {
    setSelectedMember(admin);
    setAdmin(admin.value);
    getTemplatesByAdmin(admin.value)
  }

  function deleteTemplate(id) {
    console.log("deleteTemplate called");
    console.log("deleteTemplate called id", id);
    delete_template({ id: id, lang: lang }).then(res => {
      // console.log("res ", res);
      if (res.data.status == "success") {
        toast(res.data.msg, { type: 'success' });
        getTemplatesByAdmin();
      } else {
        toast(res.data.msg, { type: "error" })
      }
    }).catch(err => {
      toast(err.data.msg, { type: 'error' });
    })
  }


  return (
    <>
      <KTCard>
        <KTCardBody className='py-4'>
          <div className='border-0' >
            <div className='row'>
              <div className='col-lg-1'>
                <div className='col-lg-12 py-2'>
                  {/* setRemountComponent is used to reset selection of active pagination to 1 */}
                  <select className='form-select border-light-blue text-light-blue' onChange={(e) => { setItemOffset(0); setItemsPerPage(parseInt(e.target.value)); setForcePage(0); setRemountComponent(Math.random()); }}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                  </select>
                </div>
              </div>
              {/* begin::Card toolbar */}
              {currentUser?.role === 'superAdmin' && (
                <>
                  <div className='col-lg-4 py-2 '>
                    <Select
                      value={selectedMember}
                      onChange={onChangeSelectedMember}
                      placeholder={<div>{intl.formatMessage({ id: 'SELECT.ADMIN' })}</div>}
                      options={options}
                      className="react-select-border-light-blue text-light-blue"
                      styles={{ control: (styles, state) => ({ ...styles, borderStyle: 'none !important', backgroundColor: "transparent" }) }}
                    />
                  </div>
                  <div className='col-lg-5 py-2'>
                    <button
                      type='button'
                      className='btn border-light-blue text-light-blue fw-bold me-2 px-6'
                      onClick={() => {
                        setSelectedMember('');
                        getTemplatesByAdmin();
                      }}
                    >
                      {intl.formatMessage({ id: 'RESET' })}
                    </button>
                  </div>
                </>
              )}

              {currentUser?.role !== 'superAdmin' && (
                <>
                  <div className='col-lg-9 py-2 '>
                  </div>
                </>
              )}


              <div className='col-lg-2 py-2'>
                <Link to={'/create-message-templates'} target="_self" style={{ float: 'right' }} type="button" className='btn dark-green-background text-light-blue float-end'>

                  {intl.formatMessage({ id: 'CREATE.TEMPLATE' })}
                  <i className="fas fa-plus text-light-blue ms-2"></i>
                </Link>
              </div>
            </div>
          </div>
        </KTCardBody>
      </KTCard>

      {/* TEMPLATE LIST DISPLAY MODAL */}
      <KTCard className='mt-3'>
        <KTCardBody className='py-4'>
          <div className='table-responsive'>
            <table
              id='kt_table_users'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer text-light-blue'
            >
              <thead className='border-bottom-very-light-blue'>
                <tr className='text-start fw-bolder fs-7 text-uppercase gs-0'>
                  <td>#</td>
                  <td className='text-nowrap'>{intl.formatMessage({ id: 'NAME' })}</td>
                  {/* <td className='text-nowrap'>{intl.formatMessage({ id: 'TEXT' })}</td> */}
                  <td className='text-nowrap'>{intl.formatMessage({ id: 'TEMPLATE.TYPE' })}</td>
                  <td className='text-nowrap'>{intl.formatMessage({ id: 'CREATED.ON' })}</td>
                  <td className='text-nowrap'>{intl.formatMessage({ id: 'ACTIONS' })}</td>
                </tr>
              </thead>
              <tbody className='fw-bold'>
                {currentItems?.length ?
                  currentItems.map((item, index) => (
                    <tr key={index}>
                      <td>{itemOffset + index + 1}</td>
                      <td className='text-nowrap'>{item?.template?.template_name}</td>
                      {/* {(item?.template?.type === 'buttons' || item?.template?.type === 'confirm') && (
                        <td className='text-nowrap'>{item?.template?.text}</td>
                      )}
                      {item?.template?.type === 'image_carousel' && (
                        <td className='text-nowrap'>-----</td>
                      )}
                      {item?.template?.type === 'referral_template' && (
                        <td className='text-nowrap'>{item?.template?.text}</td>
                      )}
                      {item?.template?.type === 'carousel' && (
                        <td className='text-nowrap'>{item?.template?.columns[0].text}</td>
                      )}
                      {item?.template?.type === 'reservation_template' && (
                        <td className='text-nowrap'>------</td>
                      )} */}
                      <td className='text-nowrap'>{item?.template?.type}</td>
                      <td className='text-nowrap'>{moment(item.created_at).format('LL')}</td>
                      <td className='text-nowrap'>
                        <OverlayTrigger
                          delay={{ hide: 50, show: 50 }}
                          overlay={(props) => (
                            <Tooltip {...props}>
                              {intl.formatMessage({ id: 'DEL.TEMPLATE' })}
                            </Tooltip>
                          )}
                          placement="top">
                          <button type='button' className='btn btn-icon btn-sm green-background btn-color-danger m-1' onClick={() => deleteTemplate(item._id)} ><i className='fa fa-trash'></i></button>
                        </OverlayTrigger>
                        {
                          item?.template?.type == "referral_template" ?
                            <OverlayTrigger
                              delay={{ hide: 50, show: 50 }}
                              overlay={(props) => (
                                <Tooltip {...props}>
                                  {intl.formatMessage({ id: 'Edit' })} {intl.formatMessage({ id: 'Message Template' })}
                                </Tooltip>
                              )}
                              placement="top">
                              <Link to={"/edit-message-templates/" + item._id} className='btn btn-icon btn-fill btn-sm btn-bg-light btn-active-color-primary m-1 green-background' ><i className="bi bi-pencil-square text-light-blue"></i></Link>
                            </OverlayTrigger>
                            :
                            null
                        }
                      </td>
                    </tr>
                  ))
                  : (
                    <tr>
                      <td colSpan={7}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          {intl.formatMessage({ id: 'NO.RECORDS' })}
                        </div>
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
          </div>
          <div className='row' key={remountComponent}>

            <div className='col-lg-6 d-flex align-items-center'>{showingItemsText}</div>
            <ReactPaginate
              nextLabel={intl.formatMessage({ id: "next >" })}
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel={intl.formatMessage({ id: "< previous" })}
              pageClassName="page-item"
              pageLinkClassName="page-link text-light-blue"
              previousClassName="page-item"
              previousLinkClassName="page-link text-light-blue"
              nextClassName="page-item"
              nextLinkClassName="page-link text-light-blue"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link text-light-blue"
              containerClassName="col-lg-6 pagination justify-content-end"
              activeClassName="green-background rounded"
              renderOnZeroPageCount={() => null}
              forcePage={forcePage}
            />
          </div>
        </KTCardBody>
      </KTCard>
    </>
  );
}

const MessageTemplatesWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.MESSAGE_TEMPLATES' })}</PageTitle>
      <MessageTemplateList />
    </>
  )
}

export { MessageTemplatesWrapper }


