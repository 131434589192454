/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { KTCard, KTCardBody, KTSVG } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { toast } from 'react-toastify';
import { Tooltip, OverlayTrigger, Modal, Button } from 'react-bootstrap'
import { create_invoice_generate, get_bots, get_followers, get_user_groups, get_template_list, get_invoice_generate, update_invoice_generate } from '../../utills/api_helper';
import Select from 'react-select'

import moment from 'moment';
import { useParams } from 'react-router-dom'



const lang = localStorage.getItem('i18nConfig')?.slice(17, 19);

const CreateInvoiceGenerate: FC = () => {
  let params = useParams();
  const intl = useIntl()
  const [bots, setBots] = useState([]);
  const [users, setUsers] = useState<any>([]);
  const [bot, setBot] = useState<string | undefined>("")
  const [name, setName] = useState<string | undefined>("")
  const [amount, setAmount] = useState<any | undefined>("")
  const [selectedUsers, setSelectedUsers] = useState<any>(null)
  const [message, setMessage] = useState<any>("")
  const [paymentLink, setPaymentLink] = useState<any>("")
  const [paymentButtonName, setPaymentButtonName] = useState<any>("")
  const [paymentDueDate, setPaymentDueDate] = useState<any>("")
  const [image, setImage] = useState<any>()
  const [inputFile, setInputFile] = useState<HTMLInputElement | null>(null);
  // const [startInvoiceGenerate, setStartInvoiceGenerate] = useState<boolean>(true)

  const [createInvoiceGenerateLoading, setCreateInvoiceGenerateLoading] = useState<any>("")
  const [invoiceGenerate, setInvoiceGenerate] = useState<any>()
  const [isEdit, setIsEdit] = useState<any>(false)


  useEffect(() => {
    setInputFile(document.getElementById("input-file") as HTMLInputElement);
    moment.locale(lang)
    let filter = { bot_id: null, search: "" }
    get_bots(filter).then((res) => {
      if (res.data.status == "success") {
        setBots(res.data.data)
      }
    });
    if (params.id) {
      get_invoiceGenerates_list(null, "", params.id);
      setIsEdit(true);
    }


  }, [])

  function get_invoiceGenerates_list(bot_id: any = null, search: string = "", invoice_id = "") {
    let filter = { bot_id: bot_id, name: search, _id: invoice_id }
    get_invoice_generate(filter).then((res) => {
      if (res.data.status == "success") {
        let data = res.data.data[0];
        setInvoiceGenerate(data);
        // setBot(data.bot_id._id);
        botChange(data.bot_id._id);
        onChangeName(data.name);
        onChangeAmount(data.amount);
        onChangeMessage(data.message);
        onChangePaymentLink(data.payment_link);
        onChangePaymentButtonName(data.payment_button_name);
        onChangePaymentDueDate(data.payment_due_date);
        let all_selected_users: any = [];
        data.users.map((item, index) => {
          all_selected_users.push({ label: item.displayName, value: item._id })
        })
        onChangeUsers(all_selected_users);
        // setStartInvoiceGenerate(data.is_active);
      }
    });
  }
  function onChangeName(value) {
    setName(value);
    formikCreateInvoiceGenerate.setFieldValue("name", value);
  }
  function onChangeAmount(value) {
    setAmount(value);
    formikCreateInvoiceGenerate.setFieldValue("amount", value);
  }
  function onChangePaymentLink(value) {
    setPaymentLink(value);
    formikCreateInvoiceGenerate.setFieldValue("paymentLink", value);
  }
  function onChangePaymentButtonName(value) {
    setPaymentButtonName(value);
    formikCreateInvoiceGenerate.setFieldValue("paymentButtonName", value);
  }
  function onChangePaymentDueDate(value) {
    setPaymentDueDate(value);
    formikCreateInvoiceGenerate.setFieldValue("paymentDueDate", value);
  }

  function botChange(value) {
    setBot(value);
    formikCreateInvoiceGenerate.setFieldValue("bot", value);
    let follwersFilter = { bot_id: value, search: "" }
    get_followers(follwersFilter).then((res) => {
      if (res.data.status == "success") {
        setSelectedUsers([]);
        formikCreateInvoiceGenerate.setFieldValue("selectedUsers", []);
        let localusers: any = [];
        res.data.data.map((item, index) => {
          localusers.push({ value: item._id, label: item.displayName });
        });
        setUsers(localusers)
      }
    });
  }

  function onChangeUsers(values) {
    setSelectedUsers(values);
    formikCreateInvoiceGenerate.setFieldValue("selectedUsers", values);
  }

  function onChangeMessage(value) {
    setMessage(value); formikCreateInvoiceGenerate.setFieldValue("message", value);
  }

  function validationWithcondition() {
    let commonValidation: any = {
      bot: Yup.string()
        .required(intl.formatMessage({ id: 'REQUIRED' })),
      selectedUsers: Yup.array().min(1, intl.formatMessage({ id: 'REQUIRED' })),
      name: Yup.string()
        .required(intl.formatMessage({ id: 'REQUIRED' }))
        .max(30, intl.formatMessage({ id: 'MAXLENGTH' }).replace("[maxLength]", "" + 30)),
      amount: Yup.number()
        .min(1, intl.formatMessage({ id: 'Please enter value between [min] to [max]'}).replace("[min]", "" + 1).replace("[max]", "" + 100000))
        .max(100000, intl.formatMessage({ id: 'Please enter value between [min] to [max]'}).replace("[min]", "" + 1).replace("[max]", "" + 100000))
        .required(intl.formatMessage({ id: 'REQUIRED' })),
      message: Yup.string().required(intl.formatMessage({ id: 'REQUIRED' }))
        .max(60, intl.formatMessage({ id: 'MAXLENGTH' }).replace("[maxLength]", "" + 60)),
      paymentButtonName: Yup.string().required(intl.formatMessage({ id: 'REQUIRED' }))
        .max(12, intl.formatMessage({ id: 'MAXLENGTH' }).replace("[maxLength]", "" + 12)),
      paymentDueDate: Yup.string().required(intl.formatMessage({ id: 'REQUIRED' })),
      paymentLink: Yup.string().required(intl.formatMessage({ id: 'REQUIRED' }))
        .max(1000, intl.formatMessage({ id: 'MAXLENGTH' }).replace("[maxLength]", "" + 1000))
        .matches(
          /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
          intl.formatMessage({ id: "Please enter valid uri" }))

    };

    return Yup.object().shape(commonValidation)
  }

  const initialValues = {
    bot: "",
    name: "",
    amount: "",
    selectedUsers: [],
    message: "",
    paymentLink: "",
    paymentButtonName: "",
    paymentDueDate: "",
    // start: true
  }

  const formikCreateInvoiceGenerate = useFormik({
    initialValues,
    validationSchema: validationWithcondition,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      setCreateInvoiceGenerateLoading(true);
      let formData = new FormData();
      formData.append("name", name + "");
      formData.append("bot_id", bot + "");
      formData.append("amount", amount + "");
      formData.append("message", message + "");
      formData.append("payment_link", paymentLink + "");
      formData.append("payment_button_name", paymentButtonName + "");
      formData.append("payment_due_date", paymentDueDate + "");
      if (image) {
        formData.append("invoice_image", image, image.name);
      }
      let all_users_ids: any = [];
      selectedUsers.map((item, index) => {
        all_users_ids.push(item.value);
      })
      // formData.users = all_users_ids;
      formData.append("users", JSON.stringify(all_users_ids));


      try {
        let res: any;
        if (isEdit) {
          formData.append("_id", params.id + "");
          // formData._id = params.id;
          res = await update_invoice_generate(formData, true);
        } else {
          res = await create_invoice_generate(formData, true);
        }
        setCreateInvoiceGenerateLoading(false)
        setSubmitting(false)
        if (res.data.status == "success") {
          toast(res.data.msg,
            { type: "success" })
          if (!isEdit) {
            resetForm();
            ressetFormValues();
          }
        } else {
          toast(res.data.msg,
            { type: "error" })
        }
      }
      catch (error: any) {
        console.error(error)
        toast(intl.formatMessage({ id: 'Oops! something went wrong' }), { type: "error" })
        setSubmitting(false)
        setCreateInvoiceGenerateLoading(false)
      }
    }
  })

  function ressetFormValues() {
    setBot("");
    setName("");
    setAmount("");
    setSelectedUsers([]);
    setMessage("");
    setPaymentLink("");
    setPaymentButtonName("");
    setPaymentDueDate("");
    setImage(undefined);
    let input_file: any = document.getElementById("input-file");
    if (input_file) {
      input_file.value = "";
    }
  }

  const handleUpload = () => {
    inputFile?.click();
  };

  const file_choosen = (input) => {
    let filePath = input.target.value;
    let files = input.target.files[0];
    if (!filePath) {
      return;
    }

    // let fileExt = input.value.split('.')[1].toLowerCase();
    let fileExt = filePath.substring(filePath.lastIndexOf('.') + 1, filePath.length).toLowerCase();

    let msg_type = "";
    let imageType = "";
    if (fileExt == "png" || fileExt == "jpeg" || fileExt == "jpg") {
      setImage(files)
    } else {
      input.target.value = "";
      toast(intl.formatMessage({ id: 'Only images(PNG/JPG/JPEG) are supported' }),
        { type: "error" });
    }
    // switch (fileExt) {
    //   case "png":
    //     imageType = "image/png";
    //     msg_type = "image";
    //     setImage(files)
    //     break;
    //   case "jpg":
    //   case "jpeg":
    //     imageType = "image/jpeg";
    //     msg_type = "image";
    //     setImage(files)
    //     break;
    //   // case "mp4":
    //   //   imageType = "video/mp4"
    //   //   msg_type = "video";
    //   //   break;
    //   /* case "m4a":
    //     imageType = "audio/m4a";
    //     msg_type = "audio";
    //     break; */
    //   default:
    //     input.value = "";
    //     msg_type = "";
    //     imageType = "";
    //     toast(intl.formatMessage({ id: 'Only images(PNG/JPG/JPEG) are supported' }),
    //       { type: "error" });

    //     return;
    // }
    // input.target.value = "";
  }

  return (
    <>
      <KTCard>
        <KTCardBody className='py-4'>
          <div className='border-0' >
            <form
              className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
              noValidate
              id='kt_login_signup_form'
              onSubmit={formikCreateInvoiceGenerate.handleSubmit}
            >
              <div className='row'>
                <div className='col-lg-4'>
                  <div className="mb-5">
                    <label className="form-label">{intl.formatMessage({ id: 'SELECT.BOT' })}</label>
                    <select
                      {...formikCreateInvoiceGenerate.getFieldProps('bot')}
                      className='form-select border-light-blue text-light-blue'
                      onChange={(e) => {

                        botChange(e.target.value)
                      }
                      }
                      name="bot"
                      value={bot}
                      style={{ fontWeight: 200 }}
                    >
                      <option value=''>{intl.formatMessage({ id: 'SELECT.BOT' })}</option>
                      {
                        bots.map((bot: any, index) => {
                          return (
                            <option key={index.toString()} value={bot._id}>{bot.displayName}</option>
                          )
                        })
                      }
                    </select>
                    {formikCreateInvoiceGenerate.touched.bot && formikCreateInvoiceGenerate.errors.bot && (
                      <div className='fv-plugins-message-container'>
                        <span className="text-danger">{formikCreateInvoiceGenerate.errors.bot}</span>
                      </div>
                    )}
                  </div>
                  <div className="mb-5">
                    <label className="form-label">{intl.formatMessage({ id: 'Select Users' })}</label>
                    <Select
                      {...formikCreateInvoiceGenerate.getFieldProps('selectedUsers')}
                      isMulti
                      options={users}
                      className="border-light-blue text-light-blue"
                      name="selectedUsers"
                      value={selectedUsers}
                      onChange={(values) => onChangeUsers(values)}
                      styles={{ control: (styles, state) => ({ ...styles, borderStyle: 'none !important', backgroundColor: "transparent" }) }}
                      placeholder={<div>{intl.formatMessage({ id: 'Select Users' })}</div>}
                    />
                    {formikCreateInvoiceGenerate.touched.selectedUsers && formikCreateInvoiceGenerate.errors.selectedUsers && (
                      <div className='fv-plugins-message-container'>
                        <span className="text-danger">{formikCreateInvoiceGenerate.errors.selectedUsers}</span>
                      </div>
                    )}
                  </div>

                  <div className="mb-5">
                    <label className="form-label">{intl.formatMessage({ id: 'Name' })}</label>
                    <input
                      {...formikCreateInvoiceGenerate.getFieldProps('name')}
                      type="text"
                      className='form-control'
                      name='name'
                      value={name}
                      onChange={(e) => onChangeName(e.target.value)}
                      maxLength={30}
                      placeholder={intl.formatMessage({ id: 'Name of invoice' })} />
                    {formikCreateInvoiceGenerate.touched.name && formikCreateInvoiceGenerate.errors.name && (
                      <div className='fv-plugins-message-container'>
                        <span className="text-danger">{formikCreateInvoiceGenerate.errors.name}</span>
                      </div>
                    )}
                  </div>
                  <div className="mb-5">
                    <label className="form-label">{intl.formatMessage({ id: 'Amount' })}</label>
                    <input
                      {...formikCreateInvoiceGenerate.getFieldProps('amount')}
                      type="number"
                      className='form-control'
                      name='amount'
                      value={amount}
                      onChange={(e) => onChangeAmount(e.target.value)}
                      placeholder={intl.formatMessage({ id: 'Amount' })} />
                    {formikCreateInvoiceGenerate.touched.amount && formikCreateInvoiceGenerate.errors.amount && (
                      <div className='fv-plugins-message-container'>
                        <span className="text-danger">{formikCreateInvoiceGenerate.errors.amount}</span>
                      </div>
                    )}
                  </div>
                  <div className="mb-5">
                    <label className="form-label">{intl.formatMessage({ id: 'Message' })}</label>
                    <textarea
                      {...formikCreateInvoiceGenerate.getFieldProps('selectedTemplate')}
                      className='from-control border-light-blue text-light-blue w-100'
                      rows={3}
                      placeholder={intl.formatMessage({ id: "Type your message..." })}
                      value={message}
                      onChange={(e) => onChangeMessage(e.target.value)}
                      name="message"
                    >
                    </textarea>
                    {formikCreateInvoiceGenerate.touched.message && formikCreateInvoiceGenerate.errors.message && (
                      <div className='fv-plugins-message-container'>
                        <span className="text-danger">{formikCreateInvoiceGenerate.errors.message}</span>
                      </div>
                    )}
                  </div>


                  <div className="mb-5">
                    <label className="form-label">{intl.formatMessage({ id: 'Payment Link' })}</label>
                    <input
                      {...formikCreateInvoiceGenerate.getFieldProps('paymentLink')}
                      type="url"
                      className='form-control'
                      name='paymentLink'
                      value={paymentLink}
                      onChange={(e) => onChangePaymentLink(e.target.value)}
                      maxLength={30}
                      placeholder={intl.formatMessage({ id: 'Payment Link' })} />
                    {formikCreateInvoiceGenerate.touched.paymentLink && formikCreateInvoiceGenerate.errors.paymentLink && (
                      <div className='fv-plugins-message-container'>
                        <span className="text-danger">{formikCreateInvoiceGenerate.errors.paymentLink}</span>
                      </div>
                    )}
                  </div>
                  <div className="mb-5">
                    <label className="form-label">{intl.formatMessage({ id: 'Payment Button Name' })}</label>
                    <input
                      {...formikCreateInvoiceGenerate.getFieldProps('paymentButtonName')}
                      type="text"
                      className='form-control'
                      name='paymentButtonName'
                      value={paymentButtonName}
                      onChange={(e) => onChangePaymentButtonName(e.target.value)}
                      maxLength={12}
                      placeholder={intl.formatMessage({ id: 'Payment Button Name' })} />
                    {formikCreateInvoiceGenerate.touched.paymentButtonName && formikCreateInvoiceGenerate.errors.paymentButtonName && (
                      <div className='fv-plugins-message-container'>
                        <span className="text-danger">{formikCreateInvoiceGenerate.errors.paymentButtonName}</span>
                      </div>
                    )}
                  </div>
                  <div className="mb-5">
                    <label className="form-label">{intl.formatMessage({ id: 'Payment Due Date' })}</label>
                    <input
                      {...formikCreateInvoiceGenerate.getFieldProps('paymentDueDate')}
                      type="date"
                      min={moment().format("YYYY-MM-DD")}
                      className='form-control'
                      name='paymentDueDate'
                      value={paymentDueDate}
                      onChange={(e) => onChangePaymentDueDate(e.target.value)}
                      placeholder={intl.formatMessage({ id: 'Payment Button Name' })}
                      onKeyDown={(e) => {
                        e.preventDefault();
                        return false;

                      }}
                    />
                    {formikCreateInvoiceGenerate.touched.paymentDueDate && formikCreateInvoiceGenerate.errors.paymentDueDate && (
                      <div className='fv-plugins-message-container'>
                        <span className="text-danger">{formikCreateInvoiceGenerate.errors.paymentDueDate}</span>
                      </div>
                    )}
                  </div>
                  <div className="mb-5">
                    <label className="form-label">{intl.formatMessage({ id: 'Image' })}</label>
                    <div className='row'>
                      <div className='col-lg-6'>
                        {
                          image ?
                            <img src={URL.createObjectURL(image)} width="100%" />
                            :
                            <img src={process.env.REACT_APP_WEB_URL + "/images/default_invoice.jpg"} width="100%" />
                        }
                      </div>
                      <div className='col-lg-2 d-flex align-items-center my-5'>
                      {intl.formatMessage({ id: 'OR' })}
                      </div>

                      <div className='col-lg-4 d-flex align-items-center'>
                      <div className="d-grid gap-2">
                        <div className='d-flex flex-stack'>
                          <div className='d-flex align-items-center justify-content-center me-2'>
                            <input id="input-file" className="d-none" type="file" onChange={file_choosen} />
                            <button
                              className='btn btn-sm green-background text-light-blue text-left'
                              type='button'
                              data-bs-toggle='tooltip'
                              onClick={handleUpload}
                            >
                              <i className='bi bi-upload fs-3 text-light-blue'></i>
                              <br />{intl.formatMessage({ id: 'Upload Image' })}
                            </button>

                          </div>
                        </div>
                        </div>
                      </div>
                      <span className='text-muted mt-1'>{intl.formatMessage({ id: 'Default image will be send if you do not choose image.' })}</span><br />
                    </div>
                  </div>




                  {/* <div className="mb-5">
                    <label className="form-label">{intl.formatMessage({ id: 'Start' })}</label>
                    <div className="form-check form-switch form-check-custom form-check-solid">
                      <input className="form-check-input border-light-blue" type="checkbox" value="" onChange={(e) => { setStartInvoiceGenerate(e.target.checked) }} checked={startInvoiceGenerate} />
                      <label className="form-check-label">
                        {intl.formatMessage({ id: 'Start InvoiceGenerate now onwards' })} {startInvoiceGenerate ? intl.formatMessage({ id: 'ON' }) : intl.formatMessage({ id: 'OFF' })}
                      </label>
                    </div>
                  </div> */}
                  <div className="mb-5">
                    <button type="submit"
                      className="btn green-background text-light-blue"
                      disabled={formikCreateInvoiceGenerate.isSubmitting || !formikCreateInvoiceGenerate.isValid}>
                      {!createInvoiceGenerateLoading && <span>{isEdit ? intl.formatMessage({ id: "UPDATE GENERATED INVOICE" }) : intl.formatMessage({ id: "CREATE INVOICE" })}</span>}
                      {createInvoiceGenerateLoading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          {intl.formatMessage({ id: "WAIT" })}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </KTCardBody>
      </KTCard>
    </>
  )
}


const CreateInvoiceGenerateWrapper: FC = () => {
  const intl = useIntl()
  let params = useParams();
  return (
    <>
      <PageTitle breadcrumbs={[]}>{params?.id ? intl.formatMessage({ id: 'UPDATE GENERATED INVOICE' }) : intl.formatMessage({ id: 'CREATE INVOICE' })}</PageTitle>
      <CreateInvoiceGenerate />
    </>
  )
}


export { CreateInvoiceGenerateWrapper }
