import React, {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link , useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import { useIntl } from 'react-intl'
import { resetPassword } from '../core/_requests'
import { toast } from 'react-toastify'
import { useNavigate } from "react-router-dom";



const initialValues = {
    new_password: '',
    confirm_password: ''
}

const lang : any = localStorage.getItem('i18nConfig')?.slice(17, 19);


export function ResetPassword() {
  const navigate = useNavigate();
  const intl = useIntl();
  let { token } : any = useParams();
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

const resetPasswordSchema = Yup.object().shape({
    new_password: Yup
      .string()
      .min(8, intl.formatMessage({ id: 'MIN_LENGTH.PWD' }))
      .matches(/[0-9]/, intl.formatMessage({ id: 'NUM_REQ_PWD' }))
      .matches(/[a-z]/, intl.formatMessage({ id: 'LOWER_CASE_REQ.PWD' }))
      .matches(/[A-Z]/, intl.formatMessage({ id: 'UPPER_CASE_REQ.PWD' }))
      .matches(/[^\w]/, intl.formatMessage({ id: 'SPECIAL_CHAR_REQ.PWD' }))
      .required(intl.formatMessage({ id: 'NEW_PWD.REQUIRED' })),
    confirm_password: Yup
      .string()
      .oneOf([Yup.ref('new_password'), null], intl.formatMessage({ id: 'PWD.MATCH' })) //pass is field name
      .required(intl.formatMessage({ id: 'CONFIRM_PWD.REQUIRED' })),
})

  const formik = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit: (values, {setStatus,resetForm , setSubmitting}) => {
      setLoading(true)
      // setHasErrors(undefined)
      // setTimeout(() => {
        resetPassword(token,values.new_password ,lang)
          .then((result: any) => {
            toast(result?.data?.msg, { type: "success" })
            // setHasErrors(false)
            setLoading(false)
            resetForm()
            navigate("/auth");
          })
          .catch((err) => {
            toast(err?.response?.data?.msg, { type: "error" })
            // console.log(err)
            // setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            // setStatus('login details incorrect')
          })
      // }, 1000)
    },
  })

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        {/* <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Forgot Password ?</h1>
          <div className='text-gray-400 fw-bold fs-4'>Enter your email to reset your password.</div>
        </div> */}

        {/* begin::Title */}
        {/* {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
                {intl.formatMessage({id : 'KEY.EXPIRED'})}
            </div>
          </div>
        )}

        {hasErrors === false && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>{intl.formatMessage({id : 'PWD_RESET_EMAIL_SUCCESS'})}</div>
          </div>
        )} */}
        {/* end::Title */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>{intl.formatMessage({id : 'NEW.PWD'})}</label>
          <input
            type='password'
            placeholder={intl.formatMessage({id : 'NEW.PWD'})}
            autoComplete='off'
            {...formik.getFieldProps('new_password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.new_password && formik.errors.new_password},
              {
                'is-valid': formik.touched.new_password && !formik.errors.new_password,
              }
            )}
          />
          {formik.touched.new_password && formik.errors.new_password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className="text-danger">{formik.errors.new_password}</span>
              </div>
            </div>
          )}
        </div>
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>{intl.formatMessage({id : 'CONFIRM.PWD'})}</label>
          <input
            type='password'
            placeholder={intl.formatMessage({id : 'CONFIRM.PWD'})}
            autoComplete='off'
            {...formik.getFieldProps('confirm_password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.confirm_password && formik.errors.confirm_password},
              {
                'is-valid': formik.touched.confirm_password && !formik.errors.confirm_password,
              }
            )}
          />
          {formik.touched.confirm_password && formik.errors.confirm_password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span className="text-danger">{formik.errors.confirm_password}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg dark-green-background text-light-blue fw-bolder me-4'
          >
            <span className='indicator-label'>{intl.formatMessage({id : 'SUBMIT'})}</span>
            {loading && (
                <span className='indicator-progress'>
                    {intl.formatMessage({id : 'WAIT'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-lg fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {intl.formatMessage({id : 'CANCEL'})}
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </>
  )
}
