/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from '../core/Auth'
import { toast } from 'react-toastify';
import { useIntl } from 'react-intl'
import { login } from '../../../utills/api_helper'

const lang = localStorage.getItem('i18nConfig')?.slice(17, 19);

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const intl = useIntl()
  const [email, setEmail] = useState<any>("")
  const [password, setPassword] = useState<any>("")
  const [loading, setLoading] = useState(false)
  const [rememberMe, setRememberMe] = useState(false)
  const [termsCheck, setTermsCheck] = useState(false)
  const [policyCheck, setPolicyCheck] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()

  useEffect(() => {
    setEmail(localStorage.getItem("loginEmail") ? localStorage.getItem("loginEmail") : "");
    setPassword(localStorage.getItem("loginPassword") ? localStorage.getItem("loginPassword") : "");
    setRememberMe(localStorage.getItem("loginPassword") ? true : false);
  }, [])

  const loginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'EMAIL.VALIDATION' }))
      .required(intl.formatMessage({ id: 'EMAIL.REQUIRED' })),
    password: Yup.string()
      .required(intl.formatMessage({ id: 'PWD.REQUIRED' })),
    termsCheck: Yup.bool()
      .oneOf([true], intl.formatMessage({ id: 'REQUIRED' })),
    // policyCheck: Yup.boolean()
    //   .oneOf([true], intl.formatMessage({ id: 'REQUIRED' }))
  })

  const initialValues = {
    email: localStorage.getItem("loginEmail") ? localStorage.getItem("loginEmail") : "",
    password: localStorage.getItem("loginPassword") ? localStorage.getItem("loginPassword") : "",
    termsCheck: false,
    policyCheck: false
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      let data = {
        email: values.email,
        password: values.password
      }
      try {
        const res: any = await login(data)
        // console.log("login data ",data)
        if (res.data.status == "success") {
          saveAuth({ token: res.data.data.token })
          let data = res.data.data;
          let unique_features: any = []
          data?.features.map((item, index) => {
            unique_features.push(item.uniq_name)
          })
          data.unique_features = unique_features;
          setCurrentUser(data);
          if (rememberMe) {
            localStorage.setItem("loginEmail", email);
            localStorage.setItem("loginPassword", password);
          } else {
            localStorage.removeItem("loginEmail");
            localStorage.removeItem("loginPassword");
          }
        } else {
          // console.log("inside else data", data)
          saveAuth(undefined)
          toast(res.data.msg, { type: "error" })
          setStatus('The login detail is incorrect')
          setSubmitting(false)
          setLoading(false)
        }
      } catch (error: any) {
        console.error(error)
        // console.error(error.response)
        saveAuth(undefined)
        toast(intl.formatMessage({ id: 'LOGIN.FAIL' }),
          { type: "error" })
        setStatus('The login detail is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  function onClickRememberMe(value) {
    setRememberMe(value);
  }
  function onChangeTermsCheck(value) {
    setTermsCheck(value);
    formik.setFieldValue("termsCheck", value);
  }
  function onChangePolicyCheck(value) {
    setPolicyCheck(value);
    formik.setFieldValue("policyCheck", value)
  }

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>{intl.formatMessage({ id: "Sign In to [Project_name]" }).replace("[Project_name]", "" + process.env.REACT_APP_THEME_NAME)}</h1>
        {/* <div className='text-gray-400 fw-bold fs-4'>
          New Here?{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            Create an Account
          </Link>
        </div> */}
      </div>
      {/* begin::Heading */}

      {/* {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
            continue.
          </div>
        </div>
      )} */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark text-light-blue'>{intl.formatMessage({ id: "LOGIN.Email" })}</label>
        <input
          placeholder={intl.formatMessage({ id: "LOGIN.Email" })}
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          value={email}
          onChange={(e) => { setEmail(e.target.value); formik.setFieldValue("email", e.target.value); }}
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span className="text-danger">{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0 text-light-blue'>{intl.formatMessage({ id: "LOGIN.PASSWORD" })}</label>
            {/* end::Label */}
            {/* begin::Link */}
            <Link
              to='/auth/forgot-password'
              className='link-primary fs-7 fw-bolder'
              style={{ marginLeft: '11px' }}
            >
              {intl.formatMessage({ id: "FORGOT.PWD" })}
            </Link>
            {/* end::Link */}
          </div>
        </div>
        <input
          {...formik.getFieldProps('password')}
          placeholder={intl.formatMessage({ id: "LOGIN.PASSWORD" })}
          type='password'
          name='password'
          value={password}
          onChange={(e) => { setPassword(e.target.value); formik.setFieldValue("password", e.target.value) }}
          autoComplete='off'
          className={clsx(
            'form-control form-control-lg',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span className="text-danger">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>

            <div className="form-check form-check-custom">
              <input className="form-check-input border-light-blue" type="checkbox" checked={rememberMe} onChange={(e) => onClickRememberMe(e.target.checked)} />
              <label className="form-check-label" >
                {intl.formatMessage({ id: 'Remember me' })}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>

          <div className='fv-row mb-10'>
            <div className='d-flex justify-content-between mt-n5'>
              <div className='d-flex flex-stack mb-2'>

                <div className="form-check form-check-custom">
                  <input
                    {...formik.getFieldProps('termsCheck')}
                    className="form-check-input border-light-blue"
                    type="checkbox"
                    name="termsCheck"
                    checked={termsCheck}
                    onChange={(e) => onChangeTermsCheck(e.target.checked)}
                  />
                  <label className="form-check-label" >
                    <a href='https://line-dash.com/home/terms' target="_blank">{intl.formatMessage({ id: 'Terms and Condition' })}</a>,
                    &nbsp;<a href='https://line-dash.com/home/privacy' target="_blank">{intl.formatMessage({ id: 'Privacy policy' })}</a>
                  </label>
                </div>
              </div>
            </div>
            {formik.touched.termsCheck && formik.errors.termsCheck && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className="text-danger">{formik.errors.termsCheck}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <div className='col-md-6'>
          <div className='fv-row mb-10'>
            <div className='d-flex justify-content-between mt-n5'>
              <div className='d-flex flex-stack mb-2'>

                <div className="form-check form-check-custom  me-10">
                  <input
                    {...formik.getFieldProps('policyCheck')}
                    className="form-check-input border-light-blue"
                    type="checkbox"
                    name="policyCheck"
                    checked={policyCheck}
                    onChange={(e) => onChangePolicyCheck(e.target.checked)} />
                  <label className="form-check-label" >
                    <a href='https://line-dash.com/home/privacy' target="_blank">{intl.formatMessage({ id: 'Privacy policy' })}</a>
                  </label>
                </div>
              </div>
            </div>
            {formik.touched.policyCheck && formik.errors.policyCheck && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span className="text-danger">{formik.errors.policyCheck}</span>
                </div>
              </div>
            )}
          </div>
        </div> */}
      </div>
      

      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg dark-green-background text-light-blue w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>{intl.formatMessage({ id: "CONTINUE" })}</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              {intl.formatMessage({ id: "WAIT" })}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        {/* begin::Separator */}
        {/* <div className='text-center text-muted text-uppercase fw-bolder mb-5'>or</div> */}
        {/* end::Separator */}

        {/* begin::Google link */}
        {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
            className='h-20px me-3'
          />
          Continue with Google
        </a> */}
        {/* end::Google link */}

        {/* begin::Google link */}
        {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
            className='h-20px me-3'
          />
          Continue with Facebook
        </a> */}
        {/* end::Google link */}

        {/* begin::Google link */}
        {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
            className='h-20px me-3'
          />
          Continue with Apple
        </a> */}
        {/* end::Google link */}
      </div>
      {/* end::Action */}
    </form>
  )
}
