/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC , useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import moment from 'moment'
import {  referral_list  , get_followers } from '../../utills/api_helper'
import { useParams } from "react-router-dom";
import { Tree, TreeNode } from 'react-organizational-chart';

const lang = localStorage.getItem('i18nConfig')?.slice(17, 19);



const ReferralWrapperList: FC = () => {

  const intl = useIntl()
  let params = useParams();
  const [ details , setDetails ] = useState<any>([]);
  const [ userReferralList , setUserReferralList ] = useState<any>([]);
  
    useEffect(() => {
           moment.locale(lang)
           get_followers({bot_id:params.botid , _id:params.userid}).then((res) => {
             setDetails(res?.data?.data);
           });

           referral_list({bot_id:params.botid, user_id : params.userid }).then((res) => {
              setUserReferralList(res.data.data);
            })
    }, [])

  return (
    <>
        <div className='card col-md-12 text-light-blue' style={{marginBottom :'30px'}}>
          <div className='row' style={{marginLeft:'25px' , paddingBottom:'25px' , paddingTop:'20px'}}>
            {/* begin::Card toolbar */}
            <div className='col-lg-12'>
              <div className='row'>
                <div className='col-lg-3 py-2' style={{fontSize:'15px' , marginTop:'10px'}}>
                      <b>{intl.formatMessage({id: 'BOT.NAME'})}</b> : {details[0]?.bot_id?.displayName}
                </div>
                <div className='col-lg-3 py-2' style={{fontSize:'15px' , marginTop:'10px'}}>
                     <b>{intl.formatMessage({id: 'USER'})}</b> : {details[0]?.displayName}
                </div>
                <div className='col-lg-3 py-2' style={{fontSize:'15px' , marginTop:'10px'}}>
                     <b>{intl.formatMessage({id: 'REF.COUNT'})}</b> : {details[0]?.referral_count}
                </div>
                <div className='col-lg-3 py-2' style={{fontSize:'15px' , marginTop:'10px'}}>
                     <b>{intl.formatMessage({id: 'CREATED'})}</b> : {moment(details[0]?.created_at).format('LL')}
                </div>
              </div>
            </div>
          </div>
        </div>

  
        {/* USERLIST FOR REFERRAL DISPLAY MODAL */}
        <h3><b className='text-light-blue'>{intl.formatMessage({id: 'REFS.TREE'})}</b></h3>
          <div className='card col-md-12 text-light-blue' >
          <div className='row' style={{marginLeft:'25px' , paddingBottom:'25px' , paddingTop:'25px'}}>
               <Tree 
               label={<div><h3 className='text-light-blue'>{details[0]?.displayName}</h3></div>}
               >
                 {userReferralList.length ?
                   userReferralList && 
                   userReferralList.map((item,idx) => (
                     <div key={idx} className="d-flex text-center w-100 align-content-center justify-content-center">
                     <TreeNode label={<div><h3 className='text-light-blue'>{item?.displayName ? item?.displayName : "" }</h3></div>}>
                     </TreeNode>
                     </div>
                   )) : (
                     <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                       <h3 className='text-light-blue'>{intl.formatMessage({id: 'REF.NORECORDS'})}</h3>
                     </div>
                   )}
               </Tree>
              </div>
        </div>
      </>
  )
}


const ReferralWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.REFERRALS' })}</PageTitle>
      <ReferralWrapperList />
    </>
  )
}

export { ReferralWrapper }