import axios from 'axios'
import { toast } from 'react-toastify'
import { createIntl, createIntlCache } from 'react-intl';
import English from './../../_metronic/i18n/messages/en.json';
import Japanees from './../../_metronic/i18n/messages/ja.json';
let msgs = {
    en: English,
    ja: Japanees
};
const lang = localStorage.getItem('i18nConfig')?.slice(17, 19) + "";
const cache = createIntlCache();
const intl = createIntl({ locale: lang == "" ? lang : "en", messages: msgs[lang == "" ? lang : "en"], }, cache);

const API_URL = process.env.REACT_APP_API_URL

//LOGIN
export const LOGIN_URL = `${API_URL}/api/users/login`

//BOTS
export const GET_ADMINS = `${API_URL}/api/users/list`
export const GET_BOTS = `${API_URL}/api/bots/list`
export const CREATE_BOT = `${API_URL}/api/bots/create`
export const BOT_LIST = `${API_URL}/api/bots/list`
export const BROADCAST_MSG = `${API_URL}/api/chats/broadcastMsg`
export const GET_CHATS = `${API_URL}/api/chats/getChat`
export const REFERRAL_LIST = `${API_URL}/api/users/referral`


//ADMINS
export const CREATE_ADMIN = `${API_URL}/api/users/create`
export const EDIT_ADMIN = `${API_URL}/api/users/updateProfile`
export const CHANGE_ADMIN_STATS = `${API_URL}/api/users/changeAdminStatus`
export const SEND_VERIFICATION = `${API_URL}/api/users/resendVerification`
export const RESET_PASSWORD = `${API_URL}/api/users/updatePassword`

//MAIL CONFIG
export const SEND_TEST_MAIL = `${API_URL}/api/configurations/testmail`
export const CREATE_TEST_MAIL = `${API_URL}/api/configurations/create`
export const GET_TEST_MAIL = `${API_URL}/api/configurations/list`
export const EDIT_TEST_MAIL = `${API_URL}/api/configurations`

//USER GROUPS
export const SINGLE_FOLLOWER = `${API_URL}/api/users/singleFollower`
export const PUSH_MSG = `${API_URL}/api/chats/pushMsg`
export const GROUP_MSG = `${API_URL}/api/chats/multicastMsg`
export const USER_GROUPS = `${API_URL}/api/adminGroups/list`
export const CREATE_GROUP = `${API_URL}/api/adminGroups/create`
export const UPDATE_GROUP_NAME = `${API_URL}/api/adminGroups/update`
export const REMOVE_GROUP_USER = `${API_URL}/api/adminGroups/pop`
export const REMOVE_GROUP = `${API_URL}/api/adminGroups/deleteGroup`
export const GET_SINGLE_GROUP = `${API_URL}/api/adminGroups/listGroup`
export const ADD_GROUP_USER = `${API_URL}/api/adminGroups/push`

//USERS
export const FOLLOWERS_LIST = `${API_URL}/api/users/followers`
export const UPDATE_USER_TAG = `${API_URL}/api/users/updateUser`
export const CONTACT_LIST = `${API_URL}/api/users/view/contacts`

//RICHMENU INTERACTIONS
export const RICHMENU_INTERACTIONS = `${API_URL}/api/richmenuInteractions/list`;
export const INIT_RICHMENU = `${API_URL}/api/richmenu/init`;
export const CREATE_RICHMENU = '';//`${API_URL}/api/richmenuInteractions/list`;
export const RICHMENU_LIST = `${API_URL}/api/richmenu/list`;
export const SET_DEFALUT_RICH_MENU = `${API_URL}/api/richmenu/setDefault`;
export const UNLINK_RICH_MENU = `${API_URL}/api/richmenu/cancel`;
export const ADD_RICH_MENU_AREA = `${API_URL}/api/richmenu/addArea`;
export const DELETE_AREA = `${API_URL}/api/richmenu/removeArea`;
export const CREATE_RICH_MENU = `${API_URL}/api/richmenu/create`;
export const RICHMENU_AREAS = `${API_URL}/api/richmenu/actionsList`;
export const DELETE_RICHMENU = `${API_URL}/api/richmenu/delete`;
export const EXPORT_RICH_MENU = `${API_URL}/api/richmenu/excelDownload`;

//MAIL TEMPLATES
export const TEMPLATES_LIST = `${API_URL}/api/template/list`;
export const TEMPLATES_LIST_BY_ID = `${API_URL}/api/template/listById`;
export const CREATE_TEMPLATE = `${API_URL}/api/template/`;
export const UPDATE_TEMPLATE = `${API_URL}/api/template/update/`;
export const DELETE_TEMPLATE = `${API_URL}/api/template/delete`;


//SURVEYS
export const SURVEY_LIST = `${API_URL}/api/survey_reports/list`;

//SCENARIOS
export const SCENARIOS = `${API_URL}/api/scenarios/list`;
export const CREATE_SCENARIO = `${API_URL}/api/scenarios/create`;
export const UPDATE_SCENARIO = `${API_URL}/api/scenarios/update`;
export const DELETE_SCENARIO = `${API_URL}/api/scenarios/remove`;
export const INVOICE_GENERATE = `${API_URL}/api/invoices/list`;
export const CREATE_INVOICE_GENERATE = `${API_URL}/api/invoices`;
export const UPDATE_INVOICE_GENERATE = "";//`${API_URL}/api/scenarios/update`;
export const DELETE_INVOICE_GENERATE = "";//`${API_URL}/api/scenarios/remove`;

//Feateures
export const FEATURES = `${API_URL}/api/features/list`;

var is_session_expired = false;

// ---------------- AXIOS FUNCTIONS -----------------------

//POST FUNCTION
async function post(url, data, headers: any = null, is_file = false) {
    const lang = localStorage.getItem('i18nConfig')?.slice(17, 19);
    if (is_file) {
        data.append("lang", lang);
    } else {
        data.lang = lang;
    }
    let res;
    try {
        res = await axios.post(url, data, headers ? { headers } : {});
        // console.log("res ", res);
    } catch (err: any) {
        if (err?.response.status == 401) {
            if (!is_session_expired) {
                is_session_expired = true;
                toast(intl.formatMessage({ id: 'Session Expired' }),
                    { type: "error" });
                setTimeout(() => {
                    document.location.reload();
                }, 2000);
            }
        }
    }
    return res;
}

//GET FUNCTION
async function get(url, data) {
    const lang = localStorage.getItem('i18nConfig')?.slice(17, 19);
    data.lang = lang;
    return await axios.get(url, data)
}

//PUT FUNCTION
async function put(url, data) {
    const lang = localStorage.getItem('i18nConfig')?.slice(17, 19);
    data.lang = lang;
    return await axios.put(url, data)
}

// ---------------- LOGIN FUNCTIONS -----------------------
export async function login(data) {
    return await post(LOGIN_URL, data)
}
//! ---------------- LOGIN FUNCTIONS -----------------------

// ---------------- ADMIN FUNCTIONS -----------------------

//GET ALL ADMINS
export async function get_admins(data) {
    return await post(GET_ADMINS, data)
}

//CREATE ADMIN/USERS
export async function create_admin(data) {
    return await post(CREATE_ADMIN, data)
}

//GET CONTACTS LIST
export async function contact_list(data) {
    return await post(CONTACT_LIST, data)
}

//EDIT ADMIN/USERS
export async function edit_admin(data) {
    return await post(EDIT_ADMIN, data)
}


//EDIT ADMIN/USERS STATS
export async function change_admin_status(data) {
    return await post(CHANGE_ADMIN_STATS, data)
}

//RESEND_VERIFICATION
export async function resend_verification(data) {
    return await post(SEND_VERIFICATION, data);
}

//RESET PASSWORD
export async function reset_password(data) {
    return await post(RESET_PASSWORD, data);
}



// ---------------- BOT FUNCTIONS -----------------------

//CREATING A BOT
export async function createBot(data) {
    return await post(CREATE_BOT, data)
}

//GET ALL BOTS LIST  / FILTER BOTS
export async function get_bots(filter) {
    return await post(BOT_LIST, filter)
}
// to push chat msg from bot chat
export async function broadcast_msg(data, is_file) {
    let headers = {};
    if (is_file) {
        headers = { 'accept': 'application/json', 'Content-Type': 'multipart/form-data' }
    }
    return await post(BROADCAST_MSG, data, headers, is_file)

}

// to push chat msg from bot chat
export async function get_chats(data) {
    return await post(GET_CHATS, data)
}

//Get referrals list of users
export async function referral_list(data) {
    return await post(REFERRAL_LIST, data)
}



// ---------------- MAIL SETTING FUNCTIONS -----------------------

//TEST MAIL SETTING
export async function send_mail(email) {
    return await post(SEND_TEST_MAIL, email)
}

//CREATE MAIL CONFIG
export async function create_mail_config(data) {
    return await post(CREATE_TEST_MAIL, data)
}

//GET ALL MAIL CONFIG
export async function get_mail_config() {
    return await post(GET_TEST_MAIL, {})
}

//EDIT MAIL CONFIG
export async function edit_mail_config(id) {
    return await put(EDIT_TEST_MAIL + "/" + id._id, id);
}

// ---------------- GROUP LIST FUNCTIONS -----------------------

//GET ALL GROUPS
export async function get_user_groups(botid) {
    return await post(USER_GROUPS, botid);
}

//CREATE NEW GROUP
export async function create_groups(data) {
    return await post(CREATE_GROUP, data);
}

//UPDATE EXISTING GROUP NAME
export async function update_group_name(data) {
    return await post(UPDATE_GROUP_NAME, data);
}

//REMOVE MEMBER FROM A GROUP
export async function remove_group_user(data) {
    return await post(REMOVE_GROUP_USER, data);
}

//REMOVE GROUP
export async function remove_group(data) {
    return await post(REMOVE_GROUP, data);
}

//ADD NEW MEMBERS TO EXISTING GROUP
export async function add_group_user(data) {
    return await post(ADD_GROUP_USER, data);
}

//GET SINGLE GROUP DETAILS
export async function get_group_details(data) {
    return await post(GET_SINGLE_GROUP, data);
}

// ---------------- BOT FOLLOWERS LIST FUNCTIONS -----------------------

//GET FOLLOWERS FOR A BOT
export async function get_followers(botid) {
    return await post(FOLLOWERS_LIST, botid);
}

//GET SINGLE FOLLOWER FROM A BOT
export async function get_single_follower(id) {
    return await post(SINGLE_FOLLOWER, id);
}

//UPDATE USER TAG NAME
export async function update_user_tag(data) {
    return await post(UPDATE_USER_TAG, data)
}

//PUSH MESSAGE FOR A BOT
export async function push_msg(data, is_file) {
    let headers = {};
    if (is_file) {
        headers = { 'accept': 'application/json', 'Content-Type': 'multipart/form-data' }
    }
    return await post(PUSH_MSG, data, headers, is_file)
}

//GROUP MESSAGE
export async function group_msg(data, is_file) {
    let headers = {};
    if (is_file) {
        headers = { 'accept': 'application/json', 'Content-Type': 'multipart/form-data' }
    }
    return await post(GROUP_MSG, data, headers, is_file)
}


// ---------------- RICHMENU INTERACTIONS -----------------------

export async function richmenu_interactions(data) {
    return await post(RICHMENU_INTERACTIONS, data)
}
export async function init_richmenu(data) {
    return await post(INIT_RICHMENU, data)
}
export async function createRichMenu(data) {
    return await post(CREATE_RICHMENU, data)
}
export async function get_rich_menus(data) {
    return await post(RICHMENU_LIST, data)
}
export async function set_defalut_rich_menu(data) {
    return await post(SET_DEFALUT_RICH_MENU, data)
}
export async function unlink_rich_menu(data) {
    return await post(UNLINK_RICH_MENU, data)
}
export async function export_rich_menu(data) {
    return await post(EXPORT_RICH_MENU, data)
}
export async function addRichmenuArea(data, is_file) {
    let headers = {};
    if (is_file) {
        headers = { 'accept': 'application/json', 'Content-Type': 'multipart/form-data' }
    }
    return await post(ADD_RICH_MENU_AREA, data, headers, is_file)
}
export async function delete_area(data) {
    return await post(DELETE_AREA, data)
}
export async function create_rich_menu(data, is_file = false) {
    let headers = {};
    if (is_file) {
        headers = { 'accept': 'application/json', 'Content-Type': 'multipart/form-data' }
    }
    return await post(CREATE_RICH_MENU, data, headers, is_file)
}
export async function get_richmenu_areas(data) {
    return await post(RICHMENU_AREAS, data)
}
export async function delete_rich_menu(data) {
    return await post(DELETE_RICHMENU, data)
}

// ---------------- MAIL TEMPLATE FUNCTIONS -----------------------

//TEMPLATE LIST
export async function get_template_list(data) {
    return await post(TEMPLATES_LIST, data)
}


//DELETE TEMPLATE
export async function delete_template(data) {
    return await post(DELETE_TEMPLATE, data)
}

//TEMPLATE LIST BY ID
export async function get_template_list_by_id(data) {
    return await post(TEMPLATES_LIST_BY_ID, data)
}

//CREATE TEMPLATE
export async function create_template(data, is_file = false) {
    let headers = {};
    if (is_file) {
        headers = { 'accept': 'application/json', 'Content-Type': 'multipart/form-data' }
    }
    return await post(CREATE_TEMPLATE, data, headers, is_file)

}
//UPDATE TEMPLATE
export async function update_template(data, is_file = false) {
    let headers = {};
    if (is_file) {
        headers = { 'accept': 'application/json', 'Content-Type': 'multipart/form-data' }
    }
    return await post(UPDATE_TEMPLATE, data, headers, is_file)

}


// ---------------- SURVEY FUNCTIONS -----------------------

//TEMPLATE LIST
export async function get_survey_list(data) {
    return await post(SURVEY_LIST, data)
}
// ---------------- SCENARIOS FUNCTIONS -----------------------

//SCENARIOS LIST
export async function get_scenarios(data) {
    return await post(SCENARIOS, data)
}
//CREATE SCENARIOS
export async function create_scenario(data, is_file = false) {
    let headers = {};
    if (is_file) {
        headers = { 'accept': 'application/json', 'Content-Type': 'multipart/form-data' }
    }
    return await post(CREATE_SCENARIO, data, headers, is_file)
}
export async function update_scenario(data, is_file = false) {
    let headers = {};
    if (is_file) {
        headers = { 'accept': 'application/json', 'Content-Type': 'multipart/form-data' }
    }
    return await post(UPDATE_SCENARIO, data, headers, is_file)
}
export async function delete_scenario(data, is_file = false) {
    let headers = {};
    if (is_file) {
        headers = { 'accept': 'application/json', 'Content-Type': 'multipart/form-data' }
    }
    return await post(DELETE_SCENARIO, data, headers, is_file)
}

//FEATURES LIST
export async function get_features(data) {
    return await post(FEATURES, data)
}

// ---------------- INVOICE GENERATE FUNCTIONS -----------------------

//INVOICE_GENERATES LIST
export async function get_invoice_generate(data) {
    return await post(INVOICE_GENERATE, data)
}
//CREATE INVOICE_GENERATES
export async function create_invoice_generate(data, is_file = false) {
    let headers = {};
    if (is_file) {
        headers = { 'accept': 'application/json', 'Content-Type': 'multipart/form-data' }
    }
    return await post(CREATE_INVOICE_GENERATE, data, headers, is_file)
}
export async function update_invoice_generate(data, is_file = false) {
    let headers = {};
    if (is_file) {
        headers = { 'accept': 'application/json', 'Content-Type': 'multipart/form-data' }
    }
    return await post(UPDATE_INVOICE_GENERATE, data, headers, is_file)
}
export async function delete_invoice_generate(data, is_file = false) {
    let headers = {};
    if (is_file) {
        headers = { 'accept': 'application/json', 'Content-Type': 'multipart/form-data' }
    }
    return await post(DELETE_INVOICE_GENERATE, data, headers, is_file)
}