/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { KTCard, KTCardBody, KTSVG } from '../../../_metronic/helpers'
import { PageTitle } from '../../../_metronic/layout/core'
import ReactPaginate from 'react-paginate';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { toast } from 'react-toastify';
import axios from 'axios'
import { Tooltip, OverlayTrigger, Modal, Button } from 'react-bootstrap'
import { get_bots, createRichMenu, get_rich_menus, set_defalut_rich_menu, get_richmenu_areas, delete_rich_menu, unlink_rich_menu, export_rich_menu, EXPORT_RICH_MENU } from '../../utills/api_helper'
import { Link } from "react-router-dom";
import { env } from 'process';
import moment from 'moment';
import { debounce } from "lodash"
import { CSVLink, CSVDownload } from "react-csv";
// const csvData = [
//   ["RICHMENU NAME", "CHATBAR TEXT", "BOT NAME", "HEIGHT", "WIDTH", "RICHMENU ID", "CREATED_AT"],
//   ["Ahmed", "Tomi", "ah@smthing.co.com"],
//   ["Raed", "Labes", "rl@smthing.co.com"],
//   ["Yezzi", "Min l3b", "ymin@cocococo.com"]
// ];


const lang = localStorage.getItem('i18nConfig')?.slice(17, 19);




function Items({ currentItems, itemOffset, setDefaultRichMenuCB, deleteRichmenuCB, unlinkRichMenuCB }) {
  const intl = useIntl()

  const [selectedRichMenu, setSelectedRichMenu] = useState();
  const [viewModal, setViewModalShow] = useState(false);
  const [viewRichMenu, setViewRichMenu] = useState<any>();
  const [viewRichMenuAreas, setViewRichMenuAreas] = useState<any>([]);



  function getRichMenuAreas(richMenuId) {
    let filter = { richmenu_id: richMenuId }
    get_richmenu_areas(filter).then((res) => {
      // console.log("get_rich_menus res ", res)
      if (res.data.status == "success") {
        setViewRichMenuAreas(res.data.data);
      }
    });
  }
  function deleteRichmenu(richMenu) {
    deleteRichmenuCB(richMenu);

  }

  const handleCloseView = () => {
    setViewModalShow(false);
  };
  const handleShowView = (richMenu) => {
    setViewRichMenu(richMenu);
    getRichMenuAreas(richMenu._id);
    setViewModalShow(true);
  }


  function setDefaultRichMenu(richMenu) {
    setDefaultRichMenuCB(richMenu)
  }
  function unlinkRichMenu(richMenu) {
    unlinkRichMenuCB(richMenu)
  }

  return (
    <>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead className='border-bottom-very-light-blue'>
            <tr className='text-start text-light-blue fw-bolder fs-7 text-uppercase gs-0'>
              <td>#</td>
              <td className='text-nowrap'>{intl.formatMessage({ id: 'Image' })}</td>
              <td className='text-nowrap'>{intl.formatMessage({ id: 'Name' })}</td>
              <td className='text-nowrap'>{intl.formatMessage({ id: 'Bot' })}</td>
              <td className='text-nowrap'>{intl.formatMessage({ id: 'Broadcast' })}</td>
              <td className='text-nowrap'>{intl.formatMessage({ id: 'Created On' })}</td>
              <td className='text-nowrap' style={{ textAlign: "right" }}>{intl.formatMessage({ id: 'ACTION' })}</td>
            </tr>
          </thead>
          <tbody className='text-light-blue fw-bold'>
            {currentItems.length ?
              currentItems &&
              currentItems.map((item, index) => (
                <tr key={item._id}>
                  <td>{itemOffset + index + 1}</td>
                  <td className='text-nowrap'>
                    {
                      item?.image ?
                        <img src={process.env.REACT_APP_WEB_URL + "/" + item?.image} width="80" />
                        :
                        intl.formatMessage({ id: 'No Image' })
                    }
                  </td>
                  <td className='text-nowrap'>{item?.name}</td>
                  <td className='text-nowrap'>{item?.bot_id?.displayName}</td>
                  <td className='text-nowrap'>{item?.broadcast_to ? intl.formatMessage({ id: 'Yes' }) : intl.formatMessage({ id: 'No' })}</td>

                  <td className='text-nowrap'>{moment(item?.created_at).format('LL')}</td>
                  <td className='text-nowrap' style={{ textAlign: "right" }}>
                    {
                      item?.menu_status == "created" ?
                        <OverlayTrigger
                          delay={{ hide: 50, show: 50 }}
                          overlay={(props) => (
                            <Tooltip {...props}>
                              {intl.formatMessage({ id: 'VIEW' })}
                            </Tooltip>
                          )}
                          placement="top">
                          <button
                            className="btn btn-icon btn-active-color-primary btn-sm me-1 green-background"
                            onClick={() => handleShowView(item)}>
                            <i className={"fas fa-eye text-light-blue"}></i>
                          </button>
                        </OverlayTrigger>
                        :
                        null
                    }
                    {
                      item?.menu_status == "draft" ?
                        <OverlayTrigger
                          delay={{ hide: 50, show: 50 }}
                          overlay={(props) => (
                            <Tooltip {...props}>
                              {intl.formatMessage({ id: 'Draft' })}
                            </Tooltip>
                          )}
                          placement="top">
                          <a className="btn btn-icon  btn-active-color-primary btn-sm me-1 green-background" onClick={() => handleShowView(item)}><i className={clsx('bi fs-3 text-light-blue', "bi-pen-fill ")}></i></a>
                        </OverlayTrigger>
                        :
                        null
                    }
                    {
                      item?.menu_status == "created" ?
                        <>
                          {
                            item?.broadcast_to ?
                              <OverlayTrigger
                                delay={{ hide: 50, show: 50 }}
                                overlay={(props) => (
                                  <Tooltip {...props}>
                                    {intl.formatMessage({ id: 'Unlink' })}
                                  </Tooltip>
                                )}
                                placement="top">
                                <a className="btn btn-icon  btn-active-color-primary btn-sm me-1 green-background" onClick={() => unlinkRichMenu(item)} ><i className="fas fa-unlink text-light-blue"></i></a>
                              </OverlayTrigger>
                              :
                              <OverlayTrigger
                                delay={{ hide: 50, show: 50 }}
                                overlay={(props) => (
                                  <Tooltip {...props}>
                                    {intl.formatMessage({ id: 'Link' })}
                                  </Tooltip>
                                )}
                                placement="top">
                                <a onClick={() => setDefaultRichMenu(item)} className="btn btn-icon  btn-active-color-primary btn-sm me-1 green-background" ><i className={"fas fa-link text-light-blue"}></i></a>
                              </OverlayTrigger>
                          }

                        </>
                        :
                        null
                    }
                    <OverlayTrigger
                      delay={{ hide: 50, show: 50 }}
                      overlay={(props) => (
                        <Tooltip {...props}>
                          {intl.formatMessage({ id: 'Delete' })}
                        </Tooltip>
                      )}
                      placement="top">
                      <button
                        className="btn btn-icon  btn-active-color-primary btn-sm me-1 green-background" onClick={() => deleteRichmenu(item)}><i className="fas fa-trash text-light-blue"></i>
                      </button>
                    </OverlayTrigger>
                    {
                      item?.menu_status == "created" ?
                        <OverlayTrigger
                          delay={{ hide: 50, show: 50 }}
                          overlay={(props) => (
                            <Tooltip {...props}>
                              {intl.formatMessage({ id: 'Export Data' })}
                            </Tooltip>
                          )}
                          placement="top">
                          <CSVLink className="btn btn-icon  btn-active-color-primary btn-sm me-1 green-background" data={[
                            ["RICHMENU NAME", "CHATBAR TEXT", "BOT NAME", "HEIGHT", "WIDTH", "RICHMENU ID", "CREATED_AT"],
                            [item?.name, item?.chatBarText, item?.bot_id?.displayName, item?.height, item?.width, item?.richMenuId, moment(item?.created_at).format('LLL')]
                          ]}><i className="fas fa-file-export text-light-blue"></i></CSVLink>
                          {/* <a href={EXPORT_RICH_MENU + "/" + item._id} target="_blank" className="btn btn-icon  btn-active-color-primary btn-sm me-1 green-background" ><i className="fas fa-file-export text-light-blue"></i></a> */}
                        </OverlayTrigger>
                        :
                        null
                    }
                  </td>

                </tr>
              ))
              : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      {intl.formatMessage({ id: 'NO.RECORDS' })}
                    </div>
                  </td>
                </tr>
              )}
          </tbody>
        </table>
        {/* View rich_menu Modal start*/}
        <Modal
          show={viewModal}
          onHide={handleCloseView}
          size="lg">

          <Modal.Header className='p-5'>
            <Modal.Title className=' w-100'>
              <div className='row w-100'>

                <div className='col-6 d-flex align-items-center'>
                  {intl.formatMessage({ id: 'Rich menu Details' })}
                </div>
                <div className='col-6 text-end p-0'>
                  <OverlayTrigger
                    delay={{ hide: 50, show: 50 }}
                    overlay={(props) => (
                      <Tooltip {...props}>
                        {intl.formatMessage({ id: 'CLOSE' })}
                      </Tooltip>
                    )}
                    placement="top">
                    <button className="btn btn-icon  btn-active-color-primary btn-sm me-1 green-background" onClick={() => handleCloseView()}><i className="fas fa-times text-light-blue"></i></button>
                  </OverlayTrigger>
                </div>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row'>
              <div className='col-lg-6 d-flex align-items-center justify-content-center'>
                {
                  viewRichMenu?.image ?
                    <img src={process.env.REACT_APP_WEB_URL + "/" + viewRichMenu?.image} width="100%" />
                    :
                    intl.formatMessage({ id: 'No Image' })
                }
              </div>
              <div className='col-lg-6'>
                <table className='table border table-row-dashed mb-0'>
                  <tbody >
                    <tr><td className='px-2'><b>{intl.formatMessage({ id: 'Rich Menu name:' })}</b> </td><td className='px-2'>{viewRichMenu?.name}</td></tr>
                    <tr><td className='px-2'><b>{intl.formatMessage({ id: 'Chat Bar Text:' })}</b> </td><td className='px-2'>{viewRichMenu?.chatBarText}</td></tr>
                    <tr><td className='px-2'><b>{intl.formatMessage({ id: 'Bot name:' })}</b> </td><td className='px-2'>{viewRichMenu?.bot_id.displayName}</td></tr>
                    <tr><td className='px-2'><b>{intl.formatMessage({ id: 'Bot Status:' })}</b> </td><td className='px-2'>{viewRichMenu?.bot_id.status.toString()}</td></tr>
                    <tr><td className='px-2'><b>{intl.formatMessage({ id: 'Status:' })}</b> </td><td className='px-2'>{viewRichMenu?.status.toString()}</td></tr>
                    <tr><td className='px-2'><b>{intl.formatMessage({ id: 'Broadcast:' })}</b> </td><td className='px-2'>{viewRichMenu?.broadcast_to.toString()}</td></tr>
                  </tbody>
                </table>
              </div>

            </div>
            <hr />
            <div className='row'>
              {
                viewRichMenuAreas.length ?
                  <>
                    <div className='row'>
                      {
                        viewRichMenuAreas.map((item, index) => {
                          return (

                            <div className='col-lg-6' key={index}>
                              <KTCard className='mb-2 fixed-area-card-height'>
                                <KTCardBody className='px-3 py-4'>
                                  <h4>
                                    <div className='row'>
                                      <div className='col-6 align-middle'>{intl.formatMessage({ id: 'Area' })} {index + 1}</div>

                                    </div>
                                  </h4>
                                  <table className='table border table-row-dashed'>
                                    <tbody>
                                      <tr>
                                        <td className='p-1 fw-bold'>X</td>
                                        <td className='p-1'>{item.bounds.x}</td>
                                      </tr>
                                      <tr>
                                        <td className='p-1 fw-bold'> Y</td>
                                        <td className='p-1'>{item.bounds.y}</td>
                                      </tr>
                                      <tr>
                                        <td className='p-1 fw-bold'>{intl.formatMessage({ id: 'Width' })}</td>
                                        <td className='p-1'>{item.bounds.width}</td>
                                      </tr>
                                      <tr>
                                        <td className='p-1 fw-bold'> {intl.formatMessage({ id: 'Height' })}</td>
                                        <td className='p-1'>{item.bounds.height}</td>
                                      </tr>
                                      <tr>
                                        <td className='p-1 fw-bold'> {intl.formatMessage({ id: 'Select Type' })}</td>
                                        <td className='p-1'>{item.action?.type}</td>
                                      </tr>
                                      <>
                                        <tr>
                                          <td className='p-1 fw-bold'> {intl.formatMessage({ id: 'Label' })}</td>
                                          <td className='p-1'>{item.action.label}</td>
                                        </tr>
                                        {
                                          item.action.type == "message" ?
                                            <tr>
                                              <td className='p-1 fw-bold'> {intl.formatMessage({ id: 'Message' })}</td>
                                              <OverlayTrigger
                                                delay={{ hide: 50, show: 50 }}
                                                overlay={(props) => (
                                                  <Tooltip {...props}>
                                                    {item.action.text}
                                                  </Tooltip>
                                                )}
                                                placement="top">
                                                <td className='p-1'>
                                                  {item.action.text.length > 20 ? item.action.text.substring(0, 20) + "..." : item.action.text}
                                                </td>
                                              </OverlayTrigger>
                                            </tr>
                                            :
                                            null
                                        }
                                      </>
                                      {
                                        item.action.type == "uri" ?

                                          <tr>
                                            <td className='p-1 fw-bold'> {intl.formatMessage({ id: 'Uri' })}</td>
                                            <OverlayTrigger
                                              delay={{ hide: 50, show: 50 }}
                                              overlay={(props) => (
                                                <Tooltip {...props}>
                                                  {item.action.uri}
                                                </Tooltip>
                                              )}
                                              placement="top">

                                              <td className='p-1 text-break'><a href={item.action.uri} target="_blank"> {item.action.uri.length > 20 ? item.action.uri.substring(0, 20) + "..." : item.action.uri}</a></td>
                                            </OverlayTrigger>
                                          </tr>

                                          :
                                          null
                                      }
                                      {
                                        item.action.type == "postback" ?
                                          <>

                                            <tr>
                                              <td className='p-1 fw-bold'> {intl.formatMessage({ id: 'Display Text' })}</td>
                                              <OverlayTrigger
                                                delay={{ hide: 50, show: 50 }}
                                                overlay={(props) => (
                                                  <Tooltip {...props}>
                                                    {item.action.text}
                                                  </Tooltip>
                                                )}
                                                placement="top">

                                                <td className='p-1'>
                                                  {item.action.text.length > 15 ? item.action.text.substring(0, 15) + "..." : item.action.text}
                                                </td>
                                              </OverlayTrigger>
                                            </tr>
                                            <tr>
                                              <td className='p-1 fw-bold'> {intl.formatMessage({ id: 'Auto reply type' })}</td>
                                              <td className='p-1'> {item.message.type}</td>
                                            </tr>
                                            {
                                              item.message.type == "text" ?
                                                <tr>
                                                  <td className='p-1 fw-bold'> {intl.formatMessage({ id: 'Response Message' })}</td>
                                                  <OverlayTrigger
                                                    delay={{ hide: 50, show: 50 }}
                                                    overlay={(props) => (
                                                      <Tooltip {...props}>
                                                        {item.message.text}
                                                      </Tooltip>
                                                    )}
                                                    placement="top">

                                                    <td className='p-1'>
                                                      {item.message.text.length > 20 ? item.message.text.substring(0, 20) + "..." : item.message.text}
                                                    </td>
                                                  </OverlayTrigger>
                                                </tr>
                                                :
                                                null
                                            }
                                            {
                                              item.message.type == "image" ?
                                                <tr>
                                                  <td className='p-1 fw-bold'> {intl.formatMessage({ id: 'Response Message' })}</td>
                                                  <td className='p-1'> <img src={item.message.previewImageUrl} width="40" /></td>
                                                </tr>
                                                :
                                                null
                                            }
                                            {
                                              item.message.type == "video" ?
                                                <tr>
                                                  <td className='p-1 fw-bold'> {intl.formatMessage({ id: 'Response Message' })}</td>
                                                  <td className='p-1'> sdfsfg</td>
                                                </tr>
                                                :
                                                null
                                            }
                                            {
                                              item.message.type == "template" ?
                                                <tr>
                                                  <td className='p-1 fw-bold'> {intl.formatMessage({ id: 'Message Template' })}</td>
                                                  <td className='p-1'> {item.message.template_id}</td>
                                                </tr>
                                                :
                                                null
                                            }
                                            {
                                              item.message.type == "survey" ?
                                                <tr>
                                                  <td className='p-1 fw-bold'> {intl.formatMessage({ id: 'Survey' })}</td>
                                                  <td className='p-1'> {item.message.template_id}</td>
                                                </tr>
                                                :
                                                null
                                            }
                                            {
                                              item.message.type == "referral" ?
                                                <tr>
                                                  <td className='p-1 fw-bold'> {intl.formatMessage({ id: 'Referral Template' })}</td>
                                                  <td className='p-1'> {item.message.template_id}</td>
                                                </tr>
                                                :
                                                null
                                            }
                                          </>
                                          :
                                          null
                                      }
                                    </tbody>
                                  </table>
                                </KTCardBody>
                              </KTCard>
                            </div>
                          )
                        })
                      }
                    </div>
                  </>
                  :
                  null
              }
            </div>
          </Modal.Body>

        </Modal>

        {/* Add rich_menu Modal end*/}

      </div>
    </>
  );
}

function PaginatedItems({ bots }) {



  const intl = useIntl()
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState<string>("");
  const [bot, setBot] = useState<string | undefined>("")
  const [createBotList, setcreateBotList] = useState<string | undefined>()
  const [channelSecret, setchannelSecret] = useState<string | undefined>()
  const [channelAccessToken, setchannelAccessToken] = useState<string | undefined>()
  const [itemsPerPage, setItemsPerPage] = useState<number>(10)
  const [forcePage, setForcePage] = useState<number>(0)
  const [remountComponent, setRemountComponent] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [rich_menus, setRichMenus] = useState([]);
  const [refreshState, setRefreshState] = useState(0);
  const [updateRichmenu, setUpdateRichmenu] = useState<any>();
  const [deleteConfirmModalShow, setDeleteConfirmModalShow] = useState(false);
  const [linkConfirmModalShow, setLinkConfirmModalShow] = useState(false);
  const [unlinkConfirmModalShow, setUnlinkConfirmModalShow] = useState(false);


  // Simulate fetching rich_menus from another resources.
  // (This could be rich_menus from props; or rich_menus loaded in a local state
  // from an API endpoint with useEffect and useState)
  const endOffset = itemOffset + itemsPerPage;
  const showingItemsText = intl.formatMessage({ id: "Showing results from [firstRow] to [lastRow] of [totalLength]" })
    .replace("[firstRow]", "" + (itemOffset + 1))
    .replace("[lastRow]", "" + (endOffset <= rich_menus.length ? endOffset : rich_menus.length))
    .replace("[totalLength]", "" + rich_menus.length);
  const currentItems = rich_menus.slice(itemOffset, endOffset);

  const pageCount = Math.ceil(rich_menus.length / itemsPerPage);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // get_rich_menulist();
  }, [])
  useEffect(() => {
    // get_rich_menulist();
  }, [refreshState])
  useEffect(() => {
    get_rich_menulist(bot, search);
  }, [bot])

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % rich_menus.length;
    setItemOffset(newOffset);
  };

  function get_rich_menulist(bot_id: any = null, search: string = "") {
    let filter = { bot_id: bot_id, name: search }
    get_rich_menus(filter).then((res) => {
      if (res.data.status == "success") {
        setRichMenus(res.data.data);
      }
    });
  }

  function botChange(value) {
    setBot(value);
  }

  function searchChange(text) {
    setSearch(text);
  }

  const searchQuery = useCallback(debounce(() => {
    get_rich_menulist(bot, search);
  }, 500), [search]);


  useEffect(() => {
    searchQuery();
    // Cancel the debounce on useEffect cleanup.
    return searchQuery.cancel;
  }, [search, searchQuery]);

  function filter() {
    get_rich_menulist(bot, search);
  }
  function clearFilter() {
    setBot("");
    setSearch("");
    get_rich_menulist(null, "");
  }

  const initialValues = {
    createBotList: '',
    channelSecret: '',
    channelAccessToken: '',
  }

  const createRichMenuSchema = Yup.object().shape({
    createBotList: Yup.string()
      .required(intl.formatMessage({ id: 'EMAIL.REQUIRED' })),
    channelSecret: Yup.string()
      .required(intl.formatMessage({ id: 'SECRET.KEY' })),
    channelAccessToken: Yup.string()
      .required(intl.formatMessage({ id: 'ACCESS.KEY' })),
  })


  const formik = useFormik({
    initialValues,
    validationSchema: createRichMenuSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true)
      try {
        const res: any = await createRichMenu({ bot_id: values.createBotList, channelSecret: values.channelSecret, channelAccessToken: values.channelAccessToken, lang: lang })
        setLoading(false)
        if (res.data.status == "success") {
          setSubmitting(false)
          toast(res.data.msg,
            { type: "success" })
          resetForm();
          let close_el = document.getElementById('close_create_rich_menu_modal') as HTMLInputElement;
          close_el.click()
          let newRichMenus: any = rich_menus;
          newRichMenus.unshift(res.data.data);
          setRichMenus(newRichMenus)
        } else {
          toast(res.data.msg, { type: "error" })
        }
      } catch (error: any) {
        console.error(error)
        toast(intl.formatMessage({ id: 'BOT.CREATE.FAIL' }), { type: "error" })
        setSubmitting(false)
        setLoading(false)
      }
    }
  })

  function setDefaultRichMenu(richMenu) {
    setUpdateRichmenu(richMenu);
    setLinkConfirmModalShow(true);
  }
  function confirmSetDefaultRichMenu() {
    let data = { richmenu_id: updateRichmenu?._id, _id: updateRichmenu?.bot_id._id };
    set_defalut_rich_menu(data).then((res) => {
      if (res.data.status == "success") {
        //calling the api coz need to unlink all other
        //and some might be linked will be decided from backend
        get_rich_menulist(bot, search);
        setRefreshState(Math.random())
        setLinkConfirmModalShow(false);
        toast(res.data.msg, { type: "success" })
      } else {
        toast(res.data.msg, { type: "error" })
      }
    });
  }
  function unlinkRichMenu(richMenu) {
    setUpdateRichmenu(richMenu);
    setUnlinkConfirmModalShow(true);
  }
  function confirmUnlinkRichMenu() {
    let data = { richmenu_id: updateRichmenu?._id, _id: updateRichmenu?.bot_id._id };
    unlink_rich_menu(data).then((res) => {
      if (res.data.status == "success") {
        //calling the api coz need to unlink all other
        //and some might be linked will be decided from backend
        get_rich_menulist(bot, search);
        setRefreshState(Math.random());
        setUnlinkConfirmModalShow(false);
        toast(res.data.msg, { type: "success" })
      } else {
        toast(res.data.msg, { type: "error" })
      }
    });
  }

  function deleteRichmenu(richMenu) {
    setUpdateRichmenu(richMenu);
    setDeleteConfirmModalShow(true);
  }

  function confirmDeleteRichMenu() {
    let filter = { richmenu_id: updateRichmenu?._id, _id: updateRichmenu?.bot_id._id, lang: lang }
    delete_rich_menu(filter).then((res) => {
      if (res.data.status == "success") {
        let localRichMenus = rich_menus;
        localRichMenus.map((item: any, index) => {
          if (item._id == updateRichmenu?._id) {
            localRichMenus.splice(index, 1);
          }
        });
        setRichMenus(localRichMenus);
        setRefreshState(Math.random() * 100)
        toast(res.data.msg, { type: "success" })
        setDeleteConfirmModalShow(false)
      } else {
        toast(res.data.msg, { type: "error" })
      }
    });
  }


  return (
    <>
      <KTCard>
        <KTCardBody className='py-4'>
          <div className='border-0' >
            <div className='row'>
              <div className='col-lg-1'>
                <div className='col-lg-12 py-2'>
                  {/* setRemountComponent is used to reset selection of active pagination to 1 */}
                  <select className='form-select border-light-blue text-light-blue'
                    onChange={(e) => {
                      setItemOffset(0);
                      setItemsPerPage(parseInt(e.target.value));
                      setForcePage(0);
                      setRemountComponent(Math.random());
                    }}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                  </select>
                </div>
              </div>
              {/* begin::Card toolbar */}
              <div className='col-lg-9'>
                <div className='row'>
                  <div className='col-lg-4 py-2'>

                    <select
                      className='form-select border-light-blue text-light-blue'
                      onChange={(e) => botChange(e.target.value)}
                      value={bot}
                      style={{ fontWeight: 200 }}
                    >
                      <option value=''>{intl.formatMessage({ id: 'SELECT.BOT' })}</option>
                      {
                        bots.map((bot: any, index) => {
                          return (
                            <option key={index.toString()} value={bot._id}>{bot.displayName}</option>
                          )
                        })
                      }
                    </select>
                  </div>
                  <div className='col-lg-4 py-2'>
                    <input type="text" onChange={(e) => searchChange(e.target.value)} value={search} className='form-control border-light-blue text-light-blue placeholder-light-blue' placeholder={intl.formatMessage({ id: "Serach by name" })} style={{ fontWeight: 200 }} />
                  </div>
                  {/* <div className='col-lg-2 py-2'>
                <button
                  type='button'
                  className='btn btn-light-primary me-3'
                  onClick={filter}
                >
                  <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
                  {intl.formatMessage({ id: 'FILTER' })}
                </button>
              </div> */}
                  <div className='col-lg-2 py-2'>
                    <button
                      type='button'
                      className='btn fw-bold me-2 px-6 border-light-blue text-light-blue'
                      data-kt-menu-dismiss='true'
                      data-kt-user-table-filter='reset'
                      onClick={clearFilter}
                    >
                      {intl.formatMessage({ id: 'RESET' })}
                    </button>
                  </div>
                </div>
              </div>
              <div className='col-lg-2 py-2'>
                {/* end::Filter Button */}
                {/* begin::Add user */}
                {/* <button type='button' className='btn btn-primary ms-4 float-end' data-bs-toggle="modal" data-bs-target="#rich_menu_create_modal" >
              <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
              {intl.formatMessage({ id: 'ADD.BOT' })}
            </button> */}
                <Link to={"/create-richmenu"} type='button' className='btn dark-green-background text-light-blue ms-4 float-end' >
                  {intl.formatMessage({ id: 'Richmenu' })}
                  <i className="fas fa-plus text-light-blue ms-2"></i>
                </Link>
                {/* end::Add user */}

              </div>
            </div>
            {/* end::Card toolbar */}
          </div>
        </KTCardBody>
      </KTCard>
      <KTCard className='mt-3'>
        <KTCardBody className='py-4'>
          <Items currentItems={currentItems}
            itemOffset={itemOffset}
            setDefaultRichMenuCB={setDefaultRichMenu}
            unlinkRichMenuCB={unlinkRichMenu}
            deleteRichmenuCB={deleteRichmenu}
          />
          <div className='row' key={remountComponent}>

            <div className='col-lg-6 d-flex align-items-center text-light-blue fw-bold'>{showingItemsText}</div>
            <ReactPaginate
              nextLabel={intl.formatMessage({ id: "next >" })}
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel={intl.formatMessage({ id: "< previous" })}
              pageClassName="page-item"
              pageLinkClassName="page-link text-light-blue"
              previousClassName="page-item"
              previousLinkClassName="page-link text-light-blue"
              nextClassName="page-item"
              nextLinkClassName="page-link text-light-blue"
              breakLabel="..."
              breakClassName="page-item text-light-blue"
              breakLinkClassName="page-link text-light-blue"
              containerClassName="col-lg-6 pagination justify-content-end"
              activeClassName="green-background rounded"
              renderOnZeroPageCount={() => null}
              forcePage={forcePage}
            />
          </div>
        </KTCardBody>
      </KTCard>
      {/* delete confirm modal start */}
      <Modal
        show={deleteConfirmModalShow}
        onHide={() => { setDeleteConfirmModalShow(false) }}
      >

        <Modal.Header className='p-5'>
          <Modal.Title className=' w-100'>
            <div className='row w-100'>

              <div className='col-6 d-flex align-items-center'>
                {intl.formatMessage({ id: 'Delete Rich menu' })}
              </div>
              <div className='col-6 text-end p-0'>
                <OverlayTrigger
                  delay={{ hide: 50, show: 50 }}
                  overlay={(props) => (
                    <Tooltip {...props}>
                      {intl.formatMessage({ id: 'CLOSE' })}
                    </Tooltip>
                  )}
                  placement="top">
                  <button className="btn btn-icon  btn-active-color-primary btn-sm me-1 green-background" onClick={() => setDeleteConfirmModalShow(false)}><i className="fas fa-times text-light-blue"></i></button>
                </OverlayTrigger>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>{intl.formatMessage({ id: 'Are you sure you want to delete "[richMenuName]" rich menu?' })
            .replace("[richMenuName]", "" + updateRichmenu?.name)}</h6>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light"
            data-bs-dismiss="modal"
            onClick={() => setDeleteConfirmModalShow(false)}
          >
            {intl.formatMessage({ id: 'CLOSE' })}
          </button>
          <button className="btn btn-primary" onClick={confirmDeleteRichMenu}>
            <span className='indicator-label'>{intl.formatMessage({ id: 'Delete' })}</span>
          </button>
        </Modal.Footer>
      </Modal>
      {/* delete confirm modal end */}
      {/* link confirm modal start */}
      <Modal
        show={linkConfirmModalShow}
        onHide={() => { setLinkConfirmModalShow(false) }}
      >

        <Modal.Header className='p-5'>
          <Modal.Title className=' w-100'>
            <div className='row w-100'>

              <div className='col-6 d-flex align-items-center'>
                {intl.formatMessage({ id: 'Set Default' })}
              </div>
              <div className='col-6 text-end p-0'>
                <OverlayTrigger
                  delay={{ hide: 50, show: 50 }}
                  overlay={(props) => (
                    <Tooltip {...props}>
                      {intl.formatMessage({ id: 'CLOSE' })}
                    </Tooltip>
                  )}
                  placement="top">
                  <button className="btn btn-icon  btn-active-color-primary btn-sm me-1 green-background" onClick={() => setLinkConfirmModalShow(false)}><i className="fas fa-times text-light-blue"></i></button>
                </OverlayTrigger>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>{intl.formatMessage({ id: 'Are you sure you want to set default "[richMenuName]" rich menu?' })
            .replace("[richMenuName]", "" + updateRichmenu?.name)}</h6>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light"
            data-bs-dismiss="modal"
            onClick={() => setLinkConfirmModalShow(false)}
          >
            {intl.formatMessage({ id: 'CLOSE' })}
          </button>
          <button className="btn btn-primary" onClick={confirmSetDefaultRichMenu}>
            <span className='indicator-label'>{intl.formatMessage({ id: 'Link' })}</span>
          </button>
        </Modal.Footer>
      </Modal>
      {/* link confirm modal end */}
      {/* unlink confirm modal start */}
      <Modal
        show={unlinkConfirmModalShow}
        onHide={() => { setUnlinkConfirmModalShow(false) }}
      >

        <Modal.Header className='p-5'>
          <Modal.Title className=' w-100'>
            <div className='row w-100'>

              <div className='col-6 d-flex align-items-center'>
                {intl.formatMessage({ id: 'Unlink' })}
              </div>
              <div className='col-6 text-end p-0'>
                <OverlayTrigger
                  delay={{ hide: 50, show: 50 }}
                  overlay={(props) => (
                    <Tooltip {...props}>
                      {intl.formatMessage({ id: 'CLOSE' })}
                    </Tooltip>
                  )}
                  placement="top">
                  <button className="btn btn-icon  btn-active-color-primary btn-sm me-1 green-background" onClick={() => setUnlinkConfirmModalShow(false)}><i className="fas fa-times text-light-blue"></i></button>
                </OverlayTrigger>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>{intl.formatMessage({ id: 'Are you sure you want to unlink "[richMenuName]" rich menu?' })
            .replace("[richMenuName]", "" + updateRichmenu?.name)}</h6>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-light"
            data-bs-dismiss="modal"
            onClick={() => setUnlinkConfirmModalShow(false)}
          >
            {intl.formatMessage({ id: 'CLOSE' })}
          </button>
          <button className="btn btn-primary" onClick={confirmUnlinkRichMenu}>
            <span className='indicator-label'>{intl.formatMessage({ id: 'Unlink' })}</span>
          </button>
        </Modal.Footer>
      </Modal>
      {/* unlink confirm modal end */}
    </>
  );
}


const RichMenuList: FC = () => {
  const [bots, setBots] = useState([]);

  useEffect(() => {
    moment.locale(lang)
    let filter = { bot_id: null, search: "" }
    get_bots(filter).then((res) => {
      if (res.data.status == "success") {
        setBots(res.data.data)
      }
    });
  }, [])

  return (
    <>
      <PaginatedItems bots={bots} />
    </>
  )
}

const RichMenusWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.RICHMENUS' })}</PageTitle>
      <RichMenuList />
    </>
  )
}

export { RichMenusWrapper }
