/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import Select from 'react-select';
import { PageTitle } from '../../../_metronic/layout/core'
import { KTCard, KTCardBody, KTSVG } from '../../../_metronic/helpers'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { create_template } from '../../utills/api_helper'
import { toast } from 'react-toastify';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';

const lang = localStorage.getItem('i18nConfig')?.slice(17, 19);



const CarouselTemplate: FC = () => {

  return (
    <>
      <KTCard>
        <KTCardBody className='py-4'>
          <PaginatedItems />
        </KTCardBody>
      </KTCard>
    </>
  )
}

function PaginatedItems() {
  const navigate = useNavigate();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [inputFiles, setInputFiles] = useState<any>([]);
  const [imgAspect, setImgAspect] = useState<any>(null);
  const [imgSize, setImgSize] = useState<any>(null);

  const add_columns_options = [
    { value: "postback", label: intl.formatMessage({ id: 'POSTBACK' }) },
    { value: "message", label: intl.formatMessage({ id: 'MESSAGE' }) },
    { value: "uri", label: intl.formatMessage({ id: 'URI' }) }
  ]


  const img_aspect_ratio_options = [
    { value: "rectangle", label: intl.formatMessage({ id: 'RECTANGLE' }) },
    { value: "square", label: intl.formatMessage({ id: 'SQUARE' }) }
  ]

  const img_size_options = [
    { value: "cover", label: intl.formatMessage({ id: 'COVER' }) },
    { value: "contain", label: intl.formatMessage({ id: 'CONTAIN' }) }
  ]

  const [columns, setColumns] = useState<any | undefined>([{
    imageUrl: null, imgBg: '#FFFFFF', title: '', text: '', actions: [
      { type: add_columns_options[0].value, label: "", data: "" },
      { type: add_columns_options[0].value, label: "", data: "" },
      { type: add_columns_options[0].value, label: "", data: "" },
    ]
  }]);

  const [selectOptions, setSelectOptions] = useState<any>(add_columns_options);

  function checkLabelValidation(textbox) {
    if (textbox.target.value === '') {
      textbox.target.setCustomValidity(intl.formatMessage({ id: 'LABEL.REQ' }));
    } else {
      textbox.target.setCustomValidity('');
    }
    return true;
  }

  function checkDataValidation(textbox) {
    if (textbox.target.value === '') {
      textbox.target.setCustomValidity(intl.formatMessage({ id: 'DATA.REQ' }));
    } else {
      textbox.target.setCustomValidity('');
    }
    return true;
  }


  const initialValues = {
    templateName: '',
    img_aspect_ratio: img_aspect_ratio_options[0].value,
    img_size: img_size_options[0].value,
  }

  const createButtonSchema = Yup.object().shape({
    templateName: Yup.string()
      .required(intl.formatMessage({ id: 'TEMPLATE.REQ' })),
  })

  const formik_carousel_type = useFormik({
    initialValues,
    validationSchema: createButtonSchema,
    onSubmit: async (values, { resetForm, setStatus, setSubmitting }) => {


      let updatedActions = [...columns];
      // console.log("onSubmit updatedActions ", updatedActions);
      columns.map((item1, index1) => {
        item1.actions.map((item, index) => {
          if (item.type == "message") {
            updatedActions[index1].actions[index].text = updatedActions[index1].actions[index].data;
          } else if (item.type == "uri") {
            updatedActions[index1].actions[index].uri = updatedActions[index1].actions[index].data;
          }
        })
      });
      // console.log("onSubmit updatedActions1 ", updatedActions);
      setColumns(updatedActions);

      let template = JSON.stringify({
        template_name: values.templateName,
        type: "carousel",
        columns: updatedActions,
      })
      let form_data = new FormData();

      form_data.append('type', "template" + "");
      form_data.append('altText', "This is carousel template" + "");
      form_data.append('lang', lang + "");
      for (let i = 0; i < inputFiles.length; i++) {
        form_data.append('thumbnailImageUrl' + [i], inputFiles[i]);
      }
      form_data.append('template', template + "");
      setLoading(true)
      try {
        const res: any = await create_template(form_data)
        if (res.data.status == "success") {
          setSubmitting(false)
          setLoading(false)
          toast(res.data.msg, { type: "success" })
          resetForm()
          navigate("/message-templates")
          // setTimeout(() => {
          //   window.location.href = "/message-templates";
          // }, 1500);
        } else {
          setSubmitting(false)
          setLoading(false)
          toast(res.data.msg, { type: "error" })
          // resetForm()
        }
      } catch (error: any) {
        console.error(error)
        toast(intl.formatMessage({ id: 'TEMPLATE.CREATE.FAIL' }), { type: "error" })
        setSubmitting(false)
        setLoading(false)
        // resetForm()
      }
    }
  })

  const onChangeTemplateName = (event) => {
    setTemplateName(event.target.value)
    formik_carousel_type.setFieldValue("templateName", event.target.value)
  }

  function addColumns() {
    if (columns.length < 10) {
      setSelectOptions([...add_columns_options])
      setColumns([...columns, { imageUrl: null, imgBg: '#FFFFFF', title: '', text: '', actions: [{ type: add_columns_options[0].value, label: "", data: "" }, { type: add_columns_options[0].value, label: "", data: "" }, { type: add_columns_options[0].value, label: "", data: "" }] }])
    } else {
      toast(intl.formatMessage({ id: 'MAX.ALLOWED.10' }), { type: "error" })
    }
  }

  function deleteColumns(index) {
    if (columns.length === 1) {
      toast(intl.formatMessage({ id: 'MIN.ALLOWED.1' }), { type: "error" })
    } else {
      const updatedFields = [...columns];
      updatedFields.splice(index, 1);
      const updatedInputFields = [...inputFiles];
      updatedInputFields.splice(index, 1);
      setInputFiles(updatedInputFields)
      setColumns(updatedFields);
      console.log('updated col : ', updatedFields)
    }
  }

  const onChangeImgAspect = (e) => {
    setImgAspect(e.value)
    formik_carousel_type.setFieldValue("img_aspect_ratio", e.value)
  }
  const onChangeImgSize = (e) => {
    setImgSize(e.value)
    formik_carousel_type.setFieldValue("img_size", e.value)
  }


  const handleTypeChange = (index, actionIndex, e) => {
    const updatedFields = [...columns];
    updatedFields[index].actions[actionIndex].type = e.target.value;
    setColumns(updatedFields);
  }


  const handleLabelChange = (index, actionIndex, value) => {
    const updatedFields = [...columns];
    updatedFields[index].actions[actionIndex].label = value;
    setColumns(updatedFields);
  }
  const handleDataChange = (index, actionIndex, value) => {
    const updatedFields = [...columns];
    updatedFields[index].actions[actionIndex].data = value;
    setColumns(updatedFields);
  }

  const handleImgBg = (index, event) => {
    const updatedFields = [...columns];
    updatedFields[index].imgBg = event.target.value;
    setColumns(updatedFields);
  }

  const handleTitleChange = (index, event) => {
    const updatedFields = [...columns];
    updatedFields[index].title = event.target.value;
    setColumns(updatedFields);
  }

  const handleTextChange = (index, event) => {
    const updatedFields = [...columns];
    updatedFields[index].text = event.target.value;
    setColumns(updatedFields);
  }

  const file_choosen = (index, event) => {
    let filePath = event.target.value;
    const file = event.target.files[0];
    let fileExt = filePath.substring(filePath.lastIndexOf('.') + 1, filePath.length).toLowerCase();
    if (!file) {
      return;
    }
    if (fileExt == "png" || fileExt == "jpeg") {
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = new Image();
        image.src = event.target.files[0];
        image.onload = () => {
          if (image.width > 1024) {
            toast(intl.formatMessage({ id: 'IMG.WIDTH.ERR' }), { type: "error" });
            return;
          }
          if (file.size > 10000000) { // 10MB
            toast(intl.formatMessage({ id: 'IMG.SIZE.ERR' }), { type: "error" });
            return;
          }
          formik_carousel_type.handleChange(event);
        };
      };
      reader.readAsDataURL(file);
      // setInputFiles(event.target.files[0]);
      const updatedInputFiles = [...inputFiles];
      updatedInputFiles[index] = event.target.files[0];
      setInputFiles(updatedInputFiles)

      const updatedFields = [...columns];
      updatedFields[index].imageUrl = event.target.value;
      setColumns(updatedFields);
    } else {
      // setInputFiles(null)
      event.target.value = "";
      toast(intl.formatMessage({ id: 'IMG.ALLOWED' }), { type: "error" });
      return;
    }
  };

  function getActionLabel(item) {
    if (item.type == "message") {
      return <label className="form-label">{intl.formatMessage({ id: 'TEXT' })}*</label>
    } else if (item.type == "uri") {
      return <label className="form-label">{intl.formatMessage({ id: 'URI' })}*</label>
    } else {
      return <label className="form-label">{intl.formatMessage({ id: 'TEMPLATE.DATA' })}*</label>
    }
  }


  return (
    <>
      <div className='border-0' >
        <div className='col-lg-7 py-5'>
          <form onSubmit={formik_carousel_type.handleSubmit}>
            <div className='row my-6 '>
              <h4><label className="form-label">{intl.formatMessage({ id: 'TEMPLATE.NAME' })}*</label></h4>
              <input
                {...formik_carousel_type.getFieldProps('templateName')}
                className={clsx(
                  'form-control form-control-lg ',
                  { 'is-invalid': formik_carousel_type.touched.templateName && formik_carousel_type.errors.templateName },
                  {
                    'is-valid': formik_carousel_type.touched.templateName && !formik_carousel_type.errors.templateName,
                  }
                )}
                name='templateName'
                type="text"
                maxLength={30}
                onChange={(e) => onChangeTemplateName(e)}
                value={templateName}
              />
            </div>
            <div className='row my-6'>
              <div className='col-lg-4 py-5'>
                <h4><label className="form-label">{intl.formatMessage({ id: 'IMAGE.ASPECT' })}</label></h4>
                <Select
                  defaultValue={img_aspect_ratio_options[0]}
                  onChange={(e) => onChangeImgAspect(e)}
                  options={img_aspect_ratio_options}
                  className="react-select-border-light-blue text-light-blue"
                  styles={{ control: (styles, state) => ({ ...styles, borderStyle: 'none !important', backgroundColor: "transparent" }) }}
                />
              </div>
            </div>
            <div className='row my-6'>
              <div className='col-lg-4 py-5'>
                <h4><label className="form-label">{intl.formatMessage({ id: 'IMAGE.SIZE' })}</label></h4>
                <Select
                  defaultValue={img_size_options[0]}
                  onChange={(e) => onChangeImgSize(e)}
                  options={img_size_options}
                  className="react-select-border-light-blue text-light-blue"
                  styles={{ control: (styles, state) => ({ ...styles, borderStyle: 'none !important', backgroundColor: "transparent" }) }}
                />
              </div>
            </div>
            <div className='row my-6'>
              <div className='col-lg-3 py-2'>
                <h4><label className="form-label">{intl.formatMessage({ id: 'COLUMNS' })}*</label></h4>
                <button className='btn green-background text-light-blue btn-sm btn-block' onClick={() => addColumns()}>

                  {intl.formatMessage({ id: 'ADD.COLUMNS' })}
                  <i className="fas fa-plus text-light-blue ms-2"></i>
                </button>
              </div>
            </div>
            <div className='row my-6 '>
              {columns.map((item, index) => {
                return (
                  <div key={index} className={"row mt-5 p-3 border-light-blue"}>
                    <div className="row">
                      <div className='col-lg-6'>
                        <h4><label className="form-label">{intl.formatMessage({ id: 'THUMBNAIL.IMAGE' })}</label></h4>
                        <label htmlFor={`input-file+${[index]}`}><i className="bi bi-upload " style={{ fontSize: '25px', marginLeft: '20px', cursor: "pointer" }}></i></label>
                        <input
                          className="form-control form-control-lg "
                          type="file"
                          id={`input-file+${[index]}`}
                          style={{ visibility: 'hidden' }}
                          name="thumbnailImg"
                          value={undefined}
                          onChange={(value) => file_choosen(index, value)}
                        />
                      </div>
                      <div className='col-lg-3 my-5' style={{ marginLeft: '30px' }}>
                        {
                          inputFiles[index] ?
                            <div >
                              <div id="templateImg-display float-right">
                                <img id="templateImg" src={URL.createObjectURL(inputFiles[index])} style={{ width: "100px", height: '100px', borderRadius: '5px' }} />
                              </div>
                            </div>
                            :
                            <>
                              <div style={{ border: '1px solid black', width: '100px', height: '100px', borderRadius: '5px' }}>
                                <div className="text-center text-muted my-5"><h5>{intl.formatMessage({ id: 'TEMPLATE.SELECT' })}</h5></div>
                              </div>
                            </>
                        }
                      </div>
                      <div className='col-lg-2 py-2'>
                        <OverlayTrigger
                          delay={{ hide: 50, show: 50 }}
                          overlay={(props) => (
                            <Tooltip {...props}>
                              {intl.formatMessage({ id: 'DEL.COL' })}
                            </Tooltip>
                          )}
                          placement="top">
                          <button
                            type='button'
                            style={{ display: index === 0 ? 'none' : 'inline-block' }}
                            className='btn btn-icon btn-md btn-bg-light btn-color-danger me-1'
                            onClick={() => deleteColumns(index)}
                          >
                            <i className='fa fa-trash'></i>
                          </button>
                        </OverlayTrigger>
                      </div>
                    </div>
                    <div className='row my-6 '>
                      <h4><label className="form-label">{intl.formatMessage({ id: 'IMAGE.BG' })}</label></h4>
                      <input
                        className='form-control form-control-lg '
                        name='imgBgColor'
                        type="color"
                        onChange={(event) => handleImgBg(index, event)}
                        value={columns[index].imgBg}
                      />
                    </div>
                    <div className='row my-6 '>
                      <h4><label className="form-label">{intl.formatMessage({ id: 'TITLE' })}</label></h4>
                      <input
                        className='form-control form-control-lg '
                        name='title'
                        type="text"
                        maxLength={60}
                        onChange={(event) => handleTitleChange(index, event)}
                        value={columns[index].title}
                      />
                    </div>
                    <div className='row my-6 '>
                      <h4><label className="form-label">{intl.formatMessage({ id: 'TEXT' })}*</label></h4>
                      <textarea
                        className='form-control form-control-lg '
                        name='text'
                        maxLength={inputFiles.length ? 60 : 120}
                        onChange={(event) => handleTextChange(index, event)}
                        value={columns[index].text}
                      />
                    </div>
                    {columns[index].actions.map((item, actionIndex) => {
                      return (
                        <div key={actionIndex} className='row'>
                          <div className='col-lg-4 py-2'>
                            <h4><label className="form-label">{intl.formatMessage({ id: 'TEMPLATE.TYPE' })}*</label></h4>
                            <select
                              // defaultInputValue={selectOptions[0].value}
                              value={columns[index].actions[actionIndex].type}
                              onChange={(e) => handleTypeChange(index, actionIndex, e)}
                              // options={selectOptions}
                              className="form-control border-light-blue text-light-blue"
                            >
                              {/* <option value=""></option> */}
                              {
                                selectOptions.map((item, index1) => {
                                  return <option key={index1} value={item.value}>{item.label}</option>
                                })
                              }
                            </select>
                          </div>
                          <div className='col-lg-4 py-2'>
                            <h4><label className="form-label">{intl.formatMessage({ id: 'TEMPLATE.LABEL' })}*</label></h4>
                            <input
                              {...formik_carousel_type.getFieldProps('action.label')}
                              className="form-control form-control-lg "
                              name='action.label'
                              type="text"
                              maxLength={20}
                              onChange={(event) => handleLabelChange(index, actionIndex, event.target.value)}
                              value={columns[index].actions[actionIndex].label}
                              required
                              onInvalid={(e) => checkLabelValidation(e)}
                              onInput={(e) => checkLabelValidation(e)}
                            />
                          </div>
                          <div className='col-lg-4 py-2'>
                            <h4>
                              {
                                getActionLabel(item)
                              }
                            </h4>
                            <input
                              {...formik_carousel_type.getFieldProps('action.data')}
                              className="form-control form-control-lg "
                              name='action.data'
                              type={columns[index].actions[actionIndex].type == "uri" ? "url" : "text"}
                              maxLength={250}
                              onChange={(event) => handleDataChange(index, actionIndex, event.target.value)}
                              value={columns[index].actions[actionIndex].data}
                              required
                              onInvalid={(e) => checkDataValidation(e)}
                              onInput={(e) => checkDataValidation(e)}
                            />

                          </div>
                        </div>
                      )
                    })}
                  </div>
                )
              })}
            </div>
            <div className='row my-4'>
              <div className='col-lg-3 py-2'>
                {!loading && <button type="submit" className='btn green-background text-light-blue btn-sm btn-block'>{intl.formatMessage({ id: 'SUBMIT' })}</button>}
                {loading && (
                  <button className='btn  green-background text-light-blue btn-sm btn-block' disabled>{intl.formatMessage({ id: 'WAIT' })}</button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}


const CarouselTemplateWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'CAROUSEL' })}</PageTitle>
      <CarouselTemplate />
    </>
  )
}

export { CarouselTemplateWrapper }